import React from 'react';
import { Container, RadioLabel, RadioInput, CustomRadio, LabelText} from './styled';
import { DefaultText } from 'components/CheckoutBuilder/styled';
import Tooltip from '../Tooltip';
// string
interface RadioOption {
  value: string;
  label: any;
}

interface RadioProps {
  options: RadioOption[];
  selected?: string | undefined,  
  title?:string,
  onChange: (value: string) => void,
  direction?: string, 
  disabled?: boolean, 
  smallFont?:boolean
  styleContainerMargin?:any, 
  isPreview?:boolean, 
  infoText?:string
}



const Radio = ({
  options, 
  selected, 
  onChange, 
  title, 
  direction, 
  disabled, 
  smallFont, 
  styleContainerMargin, 
  isPreview, 
  infoText
  }: RadioProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }
  
  return (
    <div>
      {title && <DefaultText>{title}</DefaultText>}
      <Container direction={direction} style={{...styleContainerMargin}}>
        {options?.map((option) => {
          
          return (
          <RadioLabel key = {option.value}>
            <RadioInput 
              type = "radio"
              name = {option.value}
              // name = "radio-group"
              value={option.value}
              checked ={selected === option.value}
              onChange = {handleChange}
              disabled={disabled}
            />
            <CustomRadio smallFont = {smallFont} isPreview = {isPreview}/>
            <LabelText smallFont = {smallFont}>{option.label}</LabelText>
          </RadioLabel>
          )
        })}
        <Tooltip 
          infoText = {infoText}
          style={{marginBottom: 0}}
        />
      </Container>
    </div>
  )
}

export default Radio;