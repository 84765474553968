import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ListItem, Handle } from "./styled";

interface SortableItemProps {
  id: string;
  label: string;
}

export const SortableItem: React.FC<SortableItemProps> = ({ id, label }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : undefined, // Bring dragged item to the front
  };

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      isDragging={isDragging}
      {...attributes}
      {...listeners} // Make the entire row draggable
    >
      <span>{label}</span>
      <Handle>
        <span style={{fontSize: 16, fontWeight: 700}}>⋮⋮</span>
      </Handle>
    </ListItem>
  );
};


// import React from "react";
// import { useSortable } from "@dnd-kit/sortable";
// import { CSS } from "@dnd-kit/utilities";
// import { ListItem, Handle } from "./styled";
// import { MdDragIndicator } from "react-icons/md";


// interface SortableItemProps {
//   id: string;
//   label: string;
// }

// export const SortableItem: React.FC<SortableItemProps> = ({ id, label }) => {
//   const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
//     useSortable({ id });

//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition,
//     zIndex: isDragging ? 1000 : undefined, // Bring dragged item to the front
//   };

//   return (
//     <ListItem
//       ref={setNodeRef}
//       style={style}
//       isDragging={isDragging}
//       {...attributes}
//     >
//       <span>{label}</span>
//       <Handle {...listeners}>
//         <span style={{fontWeight: 700}}>⋮⋮</span>
//       </Handle>
//     </ListItem>
//   );
// };
