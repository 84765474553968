import React from 'react';
import { Card } from '../styled';
import {DiscountInputContainer, IconImage} from './styled';
import { Button, InputBox } from '../styled';

const Coupon = ({isCardDesign}:{isCardDesign?:boolean}) => {
    const discountIcon = 'https://fastrr-boost-ui.pickrr.com/assets/images/svg/discount_icon.svg';        
    return (        
        <DiscountInputContainer>
            <div style={{width: '100%'}}>
                <IconImage src = {discountIcon} alt = "discount-icon" />
                <InputBox placeholder='Enter coupon code' />
            </div>
            <Button mutaed = {true}>Apply</Button>
        </DiscountInputContainer>
    )
}

export default Coupon;