import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect
} from "react";
import { paymentMethodsData } from "components/Checkout/Payment/data";
import { gettingPaymentMethods, createOtherPaymentMethods } from "components/Checkout/Payment/helper";
import { FetchPaymentConfig } from "components/CheckoutBuilder/Elements/components/PaymentMethods/script";
import { DataContext } from "./DataProvider";
import { useQuery } from "react-query";


interface UiConfigContextValue {
  preLoginBanner:any;
  setPreLoginBanner:any;
  postLoginBanner:any 
  setPostLoginBanner:any, 
  methods:any,
  setMethods:any, 
  otherPaymentMethods:any, 
  setOtherPaymentMethods:any, 
  selectedPaymentMethodColor:any, 
  setSelectedPaymentMethodColor:any, 
  fomoExit:any,
  setFomoExit:any, 
  exitReasons: any, 
  setExitResons: any, 
  exitFormCustomReasons:any, 
  setExitFormCustomReasons:any, 
  themeColor:any, 
  setThemeColor:any,
  sellerLogo:any, 
  setSellerLogo:any, 
  isPostLoginBannerSame:any,  
  setIsPostLoginBannerSame:any, 
  editData:any, 
  setEditData:any, 
  headerConfig:any, 
  setHeaderConfig:any, 
  templateConfig:any,
  setTemplateConfig:any, 
  resetConfig:any, 
  paymentConfig:any
  uploadedFile:any, 
  setUploadedFile:any, 
}

export const UiConfigContext = createContext<UiConfigContextValue | undefined>(undefined);

interface CustomUIContextProps {
  children: ReactNode;
}

export const CustomUIContext = ({ children }: CustomUIContextProps) => {
  // setting payment methods
  const {sellerId, sellerConfig} = useContext(DataContext) 
  const { data : paymentData, isLoading, isSuccess } = useQuery(
    ['userDetails', {sellerId}],
    FetchPaymentConfig
  );

  const [exitFormCustomReasons, setExitFormCustomReasons] = useState<any>([{ id: Date.now(), value: '' }]);
  const [sellerLogo, setSellerLogo] = useState('https://d10srchmli830n.cloudfront.net/1723543360962_Group-186257-(3).svg');
  const [preLoginBanner, setPreLoginBanner] = useState<any>({...bannerConfig})
  const [postLoginBanner, setPostLoginBanner] = useState<any>({...bannerConfig})
  const [methods, setMethods] = useState<any>(paymentMethodsData);
  const [isPostLoginBannerSame,  setIsPostLoginBannerSame] = useState(false);
  const [paymentConfig, setPaymentConfig] = useState<any>();
  const [editData, setEditData] = useState<any>();
  const [headerConfig, setHeaderConfig] = useState({...initHeaderConfig})
  const [uploadedFile, setUploadedFile] = useState<any>(false);
  const [templateConfig, setTemplateConfig] = useState({templateName:"", selectedTemplate:""})
  const [otherPaymentMethods, setOtherPaymentMethods] = useState({isActive: false,  methods: []});
  const [fomoExit, setFomoExit] = useState({ isActive:false,  reason:"Products in huge demand might run Out of Stock" })
  const [themeColor, setThemeColor] = useState({...themeColorConfig})
  const [selectedPaymentMethodColor, setSelectedPaymentMethodColor] = useState({
    isApplyGradient: false, 
    bgColor: "#ffffff", 
    textColor:"#101828"
  })
  const [exitReasons, setExitResons] = useState<any>({
    addCustomReason: false, 
    selectType:true, 
    selectedReasons: [], 
    enableExitForm: false
  })
  
  useEffect(() => {
    if(!editData){
      const paymentMethodsThroughApi = gettingPaymentMethods(paymentData?.sellerPaymentConfiguration?.other_payment_methods_display_order);
      setMethods(paymentMethodsThroughApi ? paymentMethodsThroughApi : paymentMethodsData)
      setPaymentConfig(paymentMethodsThroughApi ? paymentMethodsThroughApi : paymentMethodsData);
    }
  }, [paymentData?.sellerPaymentConfiguration?.other_payment_methods_display_order, editData])
  

  useEffect(() => {
    /* banner details set */
    if(!editData){
      if(sellerConfig?.banner_details?.pre_login){
        setPreLoginBanner({
          ...bannerConfig, 
          bannerBgColor:sellerConfig?.banner_details?.pre_login?.background_color, 
          bannerTextColor:sellerConfig?.banner_details?.pre_login?.text_color, 
          bannerText:sellerConfig?.banner_details?.pre_login?.text
        })
      }
    
      if(sellerConfig?.banner_details?.post_login){
        console.log(sellerConfig?.banner_details?.post_login?.text, 'text value')
        setPostLoginBanner({
          ...bannerConfig, 
          bannerBgColor:sellerConfig?.banner_details?.post_login?.background_color, 
          bannerTextColor:sellerConfig?.banner_details?.post_login?.text_color, 
          bannerText:sellerConfig?.banner_details?.post_login?.text
        })
      }

      /* exit reasons set */
      const selectedReasons = sellerConfig?.exit_response_details?.length ? sellerConfig?.exit_response_details : [];

      setExitResons((prev:any) => ({
        ...prev , 
        selectType: sellerConfig?.allow_single_exit_reason ? "single" : "multiple", 
        selectedReasons:[...selectedReasons], 
        enableExitForm: sellerConfig?.exit_form_enabled

      }))

      /* other payment details set */
      setOtherPaymentMethods({
        isActive: sellerConfig?.other_payment_details?.is_active, 
        methods: sellerConfig?.other_payment_details?.other_payment_name?.length ? createOtherPaymentMethods(sellerConfig?.other_payment_details?.other_payment_name) :  []
      });

      /* set seller logo */
      setSellerLogo(sellerConfig?.logo_url)

      /* scarcity */
      setFomoExit({
        isActive:sellerConfig?.scarcity_feature_choice !== "DISABLED" ? true : false, 
        reason:"Products in huge demand might run Out of Stock"
      })
  }

  }, [sellerConfig, editData])



  function extractHexColor(gradient:string) {
    const match = gradient.match(/#([0-9a-fA-F]{3,6})/);
    return match ? match[0] : null;
}

  /*---------------------------- set edit data -------------------------------------*/

  useEffect(() => {
    // have to add fallbacks
    if(editData){
      const themeColor = editData?.sellerUi?.uiConfig?.themeColor;
      const layout = editData?.sellerUi?.uiConfig?.layout;
      
    
      /* set seller Logo */
      setSellerLogo(editData?.sellerUi?.logUrl ?? sellerConfig?.logo_url)

      /* set edit theme color */
      setThemeColor((prev:any) => ({
        ...prev,
        primaryColor: themeColor?.primary,
        inactiveColor: themeColor?.secondary, 
        lightColor: themeColor?.primaryLightColor,
        bgColor: layout?.body?.bgColor, 
      }))

      /* set header values */
      if(layout?.header?.bgColor?.includes('linear-gradient')){
        setHeaderConfig((prev:any) => ({
          ...prev, 
          logoAlignment: layout?.header?.logoAlign, 
          headerBgColor: extractHexColor(layout?.header?.bgColor),  
          isHeaderGradient: true, 
          headerIconColor:layout?.header?.iconColor
        }))

      }else {
        setHeaderConfig((prev:any) => ({
          ...prev, 
          headerBgColor: layout?.header?.bgColor,
          logoAlignment: layout?.header?.logoAlign, 
          headerIconColor:layout?.header?.iconColor, 
          isHeaderGradient: false, 
        }))
      }

    /* set drawer values */
      if(layout?.drawer){
        setHeaderConfig((prev:any) => ({
          ...prev, 
          drawerHeaderColor: layout?.drawer?.drawerColor, 
          drawerHeaderBgColor: layout?.drawer?.drawerHeaderBgColor,  
        }))
      }

      /*set banner details */
      if(editData?.sellerUi?.bannerDetails?.preLogin){
        setPreLoginBanner({
          ...bannerConfig, 
          bannerBgColor:editData?.sellerUi?.bannerDetails?.preLogin?.backgroundColor ?? bannerConfig?.bannerBgColor, 
          bannerTextColor: editData?.sellerUi?.bannerDetails?.preLogin?.textColor ?? bannerConfig?.bannerTextColor, 
          bannerText: editData?.sellerUi?.bannerDetails?.preLogin?.text ?? bannerConfig?.bannerText, 
          bannerTypeSelect: editData?.sellerUi?.bannerDetails?.preLogin?.type ?? bannerConfig?.bannerTypeSelect, 
          textTypeSelect: editData?.sellerUi?.bannerDetails?.preLogin?.subType ?? bannerConfig?.textTypeSelect, 
          fontStyleSelect: editData?.sellerUi?.bannerDetails?.preLogin?.fontWeight ?? bannerConfig?.fontStyleSelect, 
          fontSizeSelect: editData?.sellerUi?.bannerDetails?.preLogin?.fontSize ?? bannerConfig?.fontSizeSelect
        })
      }
    
      if(editData?.sellerUi?.bannerDetails?.postLogin){
        setPostLoginBanner({
          ...bannerConfig, 
          bannerBgColor:editData?.sellerUi?.bannerDetails?.postLogin?.backgroundColor ?? bannerConfig?.bannerBgColor, 
          bannerTextColor: editData?.sellerUi?.bannerDetails?.postLogin?.textColor ?? bannerConfig?.bannerTextColor, 
          bannerText: editData?.sellerUi?.bannerDetails?.postLogin?.text ?? bannerConfig?.bannerText, 
          bannerTypeSelect: editData?.sellerUi?.bannerDetails?.postLogin?.type ?? bannerConfig?.bannerTypeSelect, 
          textTypeSelect: editData?.sellerUi?.bannerDetails?.postLogin?.subType ?? bannerConfig?.textTypeSelect, 
          fontStyleSelect: editData?.sellerUi?.bannerDetails?.postLogin?.fontWeight ?? bannerConfig?.fontStyleSelect, 
          fontSizeSelect: editData?.sellerUi?.bannerDetails?.postLogin?.fontSize ?? bannerConfig?.fontSizeSelect
        })
      }


      if(editData?.sellerUi?.uiConfig?.postLoginBannerSame){
        setIsPostLoginBannerSame(editData?.sellerUi?.uiConfig?.postLoginBannerSame)
        setPostLoginBanner({...preLoginBanner})
      }

      /* exit reasons set */
      const selectedReasons = editData?.sellerUi?.exitResponseDetails?.length 
      ? editData?.sellerUi?.exitResponseDetails
      : []

      setExitResons((prev:any) => ({
        ...prev , 
        selectType: editData?.sellerUi?.allowSingleExitReason ? "single" : "multiple", 
        selectedReasons:[...selectedReasons], 
        enableExitForm: editData?.sellerUi?.exitFormEnabled
      }))

      /* other payment details set */
      setOtherPaymentMethods({
        isActive: editData?.sellerUi?.otherPaymentDetails?.is_active, 
        methods: createOtherPaymentMethods(editData?.sellerUi?.otherPaymentDetails?.other_payment_name ?? [])
      });
      
      /* set payment list */
      const paymentMethodsThroughApi = gettingPaymentMethods(editData?.paymentConfig?.otherPaymentMethodsDisplayOrder);
      setMethods(paymentMethodsThroughApi ? paymentMethodsThroughApi : paymentMethodsData)
    
      /* set payment color */
      if(layout?.payment?.bgColor?.includes('linear-gradient')){
        setSelectedPaymentMethodColor((prev:any) => ({
            ...prev, 
            bgColor: extractHexColor(layout?.payment?.bgColor),  
            isApplyGradient: true, 
            textColor:layout?.payment?.color
        }))
      }else {
        setSelectedPaymentMethodColor((prev:any) => ({
          isApplyGradient: false, 
          bgColor: layout?.payment?.bgColor, 
          textColor:layout?.payment?.color
        }))
      }

      /* Set template */ 
      setTemplateConfig((prev:any) => ({...prev, selectedTemplate: editData?.sellerUi?.cardDesign === true ? "newLayout" : "oldLayout"}))

      /* set template name */
      setTemplateConfig((prev:any) => ({...prev, templateName: editData?.templateName}))

      /* scarcity */
      setFomoExit({
        isActive: editData?.sellerUi?.scarcityFeatureChoice !== "DISABLED" ? true : false, 
        reason:editData?.sellerUi?.scarcityDisplayText
      })

    }
    
  }, [editData])


  const resetConfig = () => {
      /* set template name */
      setTemplateConfig({ templateName:"", selectedTemplate:"" })
      
      /* set seller logo */
      setSellerLogo(sellerConfig?.logo_url)
      
      /* set edit theme color */
      setThemeColor((prev:any) => ({...themeColorConfig}))

      /* set header values */
      setHeaderConfig({...initHeaderConfig})
      
      /*set banner details */
      setPreLoginBanner({...bannerConfig})
      setPostLoginBanner({...bannerConfig})
    
      /* exit form */
      setExitResons({ addCustomReason: false,  selectType:'single',  selectedReasons: [], enableExitForm: false })
      setExitFormCustomReasons([{ id: Date.now(), value: '' }]);

      /* other payment details set */
      setOtherPaymentMethods({isActive: false, methods: []});
      
      /* set payment list */
      const paymentMethodsThroughApi = gettingPaymentMethods(editData?.paymentConfig?.otherPaymentMethodsDisplayOrder);
      setMethods(paymentMethodsThroughApi ? paymentMethodsThroughApi : paymentMethodsData)
    
      /* set payment color */
      setSelectedPaymentMethodColor({ isApplyGradient: false,  bgColor: "#ffffff", textColor:"#101828" })

      setIsPostLoginBannerSame(false);

      setUploadedFile("");
      setEditData(null)
  }




  return (
    <UiConfigContext.Provider
      value={{
        preLoginBanner, 
        setPreLoginBanner, 
        postLoginBanner,
        setPostLoginBanner, 
        methods, 
        setMethods, 
        otherPaymentMethods, 
        setOtherPaymentMethods, 
        selectedPaymentMethodColor, 
        setSelectedPaymentMethodColor, 
        fomoExit, 
        setFomoExit, 
        exitReasons, 
        setExitResons, 
        exitFormCustomReasons, 
        setExitFormCustomReasons, 
        themeColor, 
        setThemeColor, 
        sellerLogo, 
        setSellerLogo,
        isPostLoginBannerSame,  
        setIsPostLoginBannerSame,
        editData, 
        setEditData, 
        headerConfig, 
        setHeaderConfig, 
        templateConfig,
        setTemplateConfig, 
        paymentConfig,
        uploadedFile, 
        setUploadedFile,
        resetConfig
      }}
    >
      {children}
    </UiConfigContext.Provider>
  );
};

const bannerConfig:any = {
  bannerBgColor:'#3173E3', 
  bannerTextColor: '#ffffff', 
  bannerText: '', 
  bannerTypeSelect: 'singleBanner', 
  textTypeSelect: 'static', 
  fontStyleSelect: '400', 
  fontSizeSelect: '12px'
}

const initHeaderConfig:any ={
  logoAlignment:"center", 
  headerBgColor: "#ffffff", 
  headerIconColor: "#222222", 
  isHeaderGradient: false, 
  drawerHeaderBgColor: "#ffffff",
  drawerHeaderColor: "#222222",
  isDrawerGradient: false
}

const themeColorConfig:any = {
  primaryColor: "#3173E3",
  inactiveColor: "", 
  lightColor: "",
  bgColor: "#F9FAFD", 
  bodyPrimaryTextColor: "#222222", 
  bodySecondryTextColor: "#4e596f"
}