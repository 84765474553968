import styled from "styled-components";

export const PaymentButton = styled.button<{isCardDesign?:boolean}>`
    border:${(props:any) => props.isCardDesign ? 'none' : '0.76px solid #CCD3DE'};
    outline:none;
    width: 100%;
    background:var(--white-color);
    text-align: left;
    cursor: pointer; 
    font-family: "Metropolis";
    padding: ${(props:any) => props.isCardDesign ? '8px 8px 0' : '12px 8px 12px'}; ;
    margin-bottom: ${(props:any) => props.isCardDesign ? 'none' : '10px'};
    border-radius: ${(props:any) => props.isCardDesign ? 0 : '6px'};

    &:hover{
        border-radius: 4px;
        background:var(--payment-button-hover-bg-color);
    }

    &:hover .label > p{
        color: var(--payment-button-hover-text-color) !important;
    }

    &:hover .label{
        color: var(--payment-button-hover-text-color) !important;
    }

`

export const Divider = styled.div`
    height: 1px;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 75%, transparent 75%, transparent 100%);
    background-size: 8px 1px;
    border: none;
    margin: 16px 0;
`

export const SaveText = styled.p<{color?:string}>`
    color: ${(props:any) => props.color ?? '#238017'};
    padding: 2px 0px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Metropolis';
`

export const InlineSaveText = styled.span<{color?:string}>`
    color: ${(props:any) => props.color ?? '#238017'};
    font-size: 12px;
    font-weight: 500;
    font-family: 'Metropolis';
    padding-left: 10px;
`


export const PaymentIcon = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    border: 1px solid;
    height: 23px;
    width: 23px;
    border-radius: 4px;
    border: 0.75px solid var(--icon, #B1B1B1);
    margin-right: 8px;
    background: #ffffff;
}
`