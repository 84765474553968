import React, { useState } from 'react';
import { MdAddCircleOutline, MdOutlineRemoveCircleOutline } from "react-icons/md";
import Input from '../Input';
import {FieldContainer, AddMoreButton} from './styled'

// Define the type for each form field
interface FormField {
  id: number;
  value: string;
}

const DynamicFormFields = ({fields, setFields}:any) => {
  const addField = () => {
    setFields((prevFields:any) => [
      ...prevFields,
      { id: Date.now(), value: '' }
    ]);
  };

  const removeField = (id: number) => {
    setFields((prevFields:any) => prevFields.filter((field:any) => field.id !== id));
  };

  const handleInputChange = (id: number, newValue: string) => {
    setFields((prevFields:any) =>
      prevFields.map((field:any) =>
        field.id === id ? { ...field, value: newValue } : field
      )
    );
  };

  return (
    <div>
      {fields.map((field:any, index:number) => (
        <FieldContainer key={field.id} style={{ marginBottom: '10px' }}>
            <Input
                placeholder={`Enter reason`}
                value={field.value}
                onChange = {(value:any) => {handleInputChange(field.id, value)}}
            />
          <button
            type="button"
            onClick={() => removeField(field.id)}
            disabled={fields.length === 1}
          >
            <MdOutlineRemoveCircleOutline />
          </button>
        </FieldContainer>
      ))}
      <AddMoreButton type="button" onClick={addField}>
        <MdAddCircleOutline />  Add More
      </AddMoreButton>
    </div>
  );
};

export default DynamicFormFields;
