import React, { useState, ChangeEvent, useContext } from "react";
import { useQuery } from "react-query";
import { Heading, SubHeading } from "../styled";
import { TableContainerTw, TextStyle , FlexRowTw, PaymentStatusTag, TagsStyle} from "pages/Orders/OrdersTab/styles";
import CustomReactTable from "UiComponents/Table";
import { CustomReactInput } from "UiComponents/Select";
import Button from "../UIComponents/Button";
import { SpaceBetweenContainer } from "components/UIElements";
import {data} from './data';
import { EditIcon, RedDelete, ViewIcon } from "images/icons";
import { getAllTemplates } from "../Page/script";
import { UiConfigContext } from "context/CustomUIContext";
import PreviewDevice from "../UIComponents/Modals/PreviewDevice";
import moment from "moment";


const Home = ({callback, editId, setEditId, isEdit, setIsEdit, handlePublish, showPreview, setShowPreview}:any) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [inputValue, setInputValue] = useState<any>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('platform');
  const [prevPageNumber, setPrevPageNumber] = useState<number | null>(0)

  
  const {editData, setEditData, resetConfig} = useContext(UiConfigContext)


  const { data: allTemplates, isLoading } = useQuery(
    ["getAllTemlates", {searchTerm: searchTerm}],
    getAllTemplates
  );



  const columns: any = [
    {
      Header: 'Template Name',
      disableSortBy: false,
      accessor: (rowData: any) => {return  <TextStyle style={{textTransform: 'capitalize'}}>{rowData?.templateName}</TextStyle>}
  },
    {
      Header: "Last updated",
      // accessor: "last_updated",
      disableSortBy: true,

      // moment(record.validity?.startTime).format("YYYY-MM-DD")
      accessor: (rowData: any) => {return  <TextStyle>{moment(rowData?.updatedAt).format("YYYY-MM-DD")}</TextStyle>}
    },
    {
      Header: "Created by",
      // accessor: "created_by",
      disableSortBy: true,
      accessor: (rowData: any) => {return  rowData?.createdBy ? <TagsStyle>{rowData?.createdBy}</TagsStyle> : '-'}
    },
    {
      Header: "Created at",
      // accessor: "created_at",
      disableSortBy: true,
      accessor: (rowData: any) => {return  <TextStyle>{moment(rowData?.createdAt).format("YYYY-MM-DD")}</TextStyle>}
    },
    {
      Header: "Status",
      disableSortBy: true,
      accessor: (rowData: any) => {
        return  (
            <span className="capitalize pl-4">
                    <ul className='list-disc text-xl'>
                        <li style={{ color: rowData?.active ? '#248018' : "#0033E6"}}><PaymentStatusTag>
                            <span>{rowData?.active ? 'Published' : 'Not published'}</span>
                        </PaymentStatusTag>
                        </li>
                    </ul>
                
            </span>
        )
      }
    },
    {
      Header: 'Action',
      disableSortBy: true,
      accessor: (rowData: any) => {        
          return (
              <div className="w-max min-w-24 flex gap-4">
                <button onClick = {() => {
                    setEditData(rowData)
                    callback();
                  }}>
                  <EditIcon />
                </button>
                <button onClick = {() => {
                    setEditData(rowData)
                    setShowPreview(true)
                  }}>
                <ViewIcon />
                </button>
              </div>
          )

      }
  },
  ];

  const addOnBefore = [
    { value: 'template_name', label: 'Template Name' }
];

const onSearch = (value: string) => {
    setSearchTerm(value)
    setPageNumber(1)
}

const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setInputValue(event.target.value)
    if (!event.target.value) {
        onSearch('')
        setPageNumber(prevPageNumber)
    }
}

const onPressEnterHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!searchTerm) {
        setPrevPageNumber(pageNumber)
    }
    if (event.target.value) {
        onSearch(event.target.value)
    }
}


const onSearchKeyChange = (value: string) => {
    setSearchKey(value)
}

  const handleClosePreview =  () => {
    resetConfig()
    setShowPreview(false)
    document.querySelector('body').style.overflow = "";
  }


  return (
    <>
      <TableContainerTw>
        <Heading>Checkout templates</Heading>
        <SubHeading>View and manage all your templates in one place</SubHeading>
        {/* <TableContainerTw> */}
          <SpaceBetweenContainer style={{flexWrap: 'wrap', gap: 16}}>
          <FlexRowTw>
          <CustomReactInput
            addOnBefore={addOnBefore}
            onChangeHandler={onChangeHandler}
            onPressEnterHandler={onPressEnterHandler}
            onSearchKeyChange={onSearchKeyChange}
            searchKey={searchKey}
            defaultValue={'Template Name'}
            inputValue={inputValue}
            setSearchTerm={setSearchTerm}
          />
          </FlexRowTw>
          <div className="mr-4">
            <Button 
              title = "Create new template"
              onClick = {callback} 
            />
          </div>
          </SpaceBetweenContainer>
          <CustomReactTable
            data={allTemplates?.data ?? []}
            columns={columns}
            loading={isLoading}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalPages={20}
            checkboxes={false}
            onSelectAll={() => {}}
            pageSize={20}
            selectedRowsKeys={[]}
            defaultSortBy={[{ id: "", desc: false }]}
            newState={""}
            handleRowSelect={() => {}}
            isChecked={false}
            setHoveredRowIndex={() => {}}
          />
        {/* </TableContainerTw> */}
      </TableContainerTw>
      {showPreview && 
        <PreviewDevice 
          handleClosePreview = {handleClosePreview}
          handlePublish = {handlePublish}
        />
      } 
    </>
  );
};

export default Home;
