import React from 'react';
import {Form, Checkbox} from "antd";

const Platforms = ({props}:any) => {
    const checkListGroup= [
        { label: 'Web', value: 'Web' },
        { label: 'App', value: 'App' },
    ]

    return (
        <Form.Item
            name="platforms"
            // rules={[
            // {
            //     required: activeSwitch.paymentmodes,
            //     message: "Payment modes are required",
            // },
            // ]}
        >
                    <Checkbox.Group options={checkListGroup} />
     </Form.Item>
    )
}

export default Platforms;