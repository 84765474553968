import styled from "styled-components";

export const Tabstitle = styled.span<{isActive?:boolean}>`
    display:inline-block;
    color: ${(props:any) => props.isActive ? 'var(--primary-color)' : '#717B8C'};
    border-bottom: 2px solid ${(props:any) => props.isActive ? 'var(--primary-color)' : '#717B8C'};
    padding-bottom: 1px;
    margin-right: 12px;
    font-weight:  ${(props:any) => props.isActive ? 600 : 500};
    font-size:18px;
    cursor: pointer;
    margin-bottom: 20px;
` 