import React from 'react';
import {CustomInput, InputErrorMessage} from './styled';
import Tooltip from '../Tooltip';

const Input = ({placeholder, type = "text", title, infoText, value, onChange, error, maxLength, disabled}:any) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
      }

    return (
        <div style={{marginTop: 20}}>
            {infoText && <Tooltip label = {title} infoText ={infoText}/>}
            <CustomInput
                type = {type} 
                placeholder={placeholder} 
                value = {value}
                onChange={handleChange}
                maxLength= {maxLength ? maxLength : 100}
                disabled = {disabled}
            />
            {error && <InputErrorMessage>{error}</InputErrorMessage>}
        </div>
    )
}

export default Input;