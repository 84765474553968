import React, {useState} from 'react';
import Accrodian from '../UIComponents/Accordian';
import Header from './components/Header/index';
import { Heading, SubHeading } from '../styled';
import Banner from './components/Banner';
import PaymentMethod from './components/PaymentMethods';
import ExitForm from './components/ExitForm';

const Element = () => {
    const [isHeaderOpen, setIsHeaderOpen] = useState(false);
    const [isBannerOpen, setIsBannerOpen] = useState(true);
    const [isPaymentOpen, setIsPaymentOpen] = useState(true);
    const [isExitOpen, setIsExitOpen] = useState(true);


    return (
        <>
            <Heading>Elements Customization</Heading>
            <SubHeading>Tailor your checkout elements for a seamless checkout experience </SubHeading>
            <Accrodian heading = {'Header'} isOpen = {isHeaderOpen}  setIsOpen = {setIsHeaderOpen} >
                <Header />
            </Accrodian>
            <Accrodian heading = {'Banner'} isOpen = {isBannerOpen}  setIsOpen = {setIsBannerOpen} >
                <Banner />
            </Accrodian>
            <Accrodian heading = {'Payment'} isOpen = {isPaymentOpen}  setIsOpen = {setIsPaymentOpen} >
                <PaymentMethod />
            </Accrodian>
            <Accrodian heading = {'Exit flow'} isOpen = {isExitOpen}  setIsOpen = {setIsExitOpen}>
                <ExitForm />
            </Accrodian>
        </>
        
    )
}

export default Element;