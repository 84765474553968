import axios from 'axios';
import { GetAuthToken } from 'globalQuries';
import {sessionExpired} from '../utils/functions'

const auth = GetAuthToken();

// Create an instance of Axios
const axiosInstance = axios.create();

// Request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    config.headers['X-Auth'] = auth;
    config.headers['Authentication-info'] = localStorage.getItem('sessionToken');
    config.headers['adminLoggedIn'] = localStorage.getItem("loggedInFromAdmin") ? true : false;  
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  function (response) {
    // If the response is successful, just return the response
    return response;
  },
  
  async function (error) {
    if(error?.response?.status === 403){
      sessionExpired()
    }

    return Promise.reject(error);
  }
);


export const axiosInstanceWithHeaders = (extraHeaders = {}) => {
  const instance = axios.create();

  instance.interceptors.request.use(
    function (config) {
      config.headers['X-Auth'] = auth;
      config.headers['Authentication-info'] = localStorage.getItem('sessionToken');
      config.headers['adminLoggedIn'] = localStorage.getItem("loggedInFromAdmin") ? true : false;
      
      // Merge extra headers
      config.headers = { ...config.headers, ...extraHeaders };
      
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error?.response?.status === 403) {
        sessionExpired();
      }
      return Promise.reject(error);
    }
  );

  return instance;
};


export default axiosInstance;