import styled from "styled-components";

export const BackDrop = styled.div`
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.5);
`

export const ModalContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--white-color);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`

export const ModalHeader = styled.div`
    background: var(--drawer-bg-color);
    padding: 12px 16px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom: 8px;
`

export const ModalBody = styled.div`
    padding: 0px 16px 24px 16px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 320px;
`



export const SaveAmountBadge = styled.div`
    display: flex;
    width: fit-content;
    padding: 2px 15px 2px 5px;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 0.2px solid #238017;
    background: linear-gradient(92deg, rgba(227, 250, 240, 0.25) -25.78%, rgba(194, 244, 199, 0.25) 104.79%, rgba(227, 250, 240, 0.25) 119.91%, rgba(227, 250, 240, 0.00) 132.16%, rgba(187, 255, 206, 0.25) 132.16%);
    color: #639252;
    font-family: 'Metropolis';
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
`

export const FomoContainer = styled.div`
    background:linear-gradient(to right, rgb(255, 249, 239), rgb(255, 212, 198));
    color: rgb(233, 81, 35);
    display: flex;
    padding: 4px 12px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    img {
        height: 18px;
    }
`

export const TextArea = styled.textarea`
    width: 100%;
    height: 96px;
    padding: 16px 14px 16px 14px;
    box-sizing: border-box;
    border-radius: 6px;
    /* border: 1px solid var(--grey-lite); */
    color: var(--grey-dark);
    font-size: 16px;
    outline: none;
    touch-action: pan-y;
    border: 1px solid rgba(49, 115, 227, 0.20);
    border: 1px solid var(--Input-box, rgba(49, 115, 227, 0.20));
    border-radius: 6px;
    resize: none;
    font-family: 'Metropolis';
`