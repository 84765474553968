import React, { useContext, useState } from "react";
import FastrrConversionTable from "./FastrrConversionTable";
import { Tabs } from "antd";
import TimeDuration from "./TimeDuration";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { ExportButton } from "../styled";
import { CustomCart } from "../AddressPrefill/style";
import { ConversionContainer } from "../styled";
import { downloadConversion } from "../script";
import ChannelSource from "./ChannelSource";
import { DataContext } from "context/DataProvider";

const { TabPane } = Tabs;
const Conversion = ({ fromDate, toDate, dateDiff }: any) => {
  const [timeDuration, setTimeDuration] = useState("daily");
  const [channelSource, setChannelSource] = useState('all');
  const [shopifySession,setShopifySession] = useState(false)

  const {sellerConfig} = useContext(DataContext)
  return (
    <ConversionContainer>
      <CustomCart>
        <Tabs
          tabBarExtraContent={{
            right: (
              <>
                {sellerConfig?.mobile_app_tag &&
                  <ChannelSource
                    channelSource={channelSource}
                    setChannelSource={setChannelSource}
                  />
                }
                <TimeDuration
                  timeDuration={timeDuration}
                  setTimeDuration={setTimeDuration}
                  dateDiff={dateDiff}
                />
                <ExportButton
                /* @ts-ignore */
                  icon={<CloudDownloadOutlined />}
                  onClick={() =>
                    downloadConversion(fromDate, toDate, timeDuration, channelSource,shopifySession)
                  }
                >
                  Export
                </ExportButton>
              </>
            ),
          }}
          onChange={(activeKey : string)=>{
            if(activeKey === "2"){
              setShopifySession(true)
            }else{
              setShopifySession(false)
            }
          }}
        >
          {sellerConfig?.session_tracking_enabled &&
            <TabPane tab={"fastrr Session Conversion"} key="2">
              <FastrrConversionTable
                fromDate={fromDate}
                toDate={toDate}
                timeDuration={timeDuration}
                channelSource = {channelSource}
                shopifySessionData = {true}
              />
            </TabPane>
          }

          <TabPane tab={"Fastrr Checkout Conversion"} key="1">
            <FastrrConversionTable
              fromDate={fromDate}
              toDate={toDate}
              timeDuration={timeDuration}
              channelSource = {channelSource}
              shopifySessionData = {false}
            />
          </TabPane>
          
        </Tabs>
      </CustomCart>
    </ConversionContainer>
  );
};

export default Conversion;
