import React from 'react';
import { ModalContainer, ModalHeader, BackDrop, ModalBody} from './styled';
import { Heading } from '../styled';
import Coupon from '../Coupon';
import CouponList from './couponList';
import { CrossIcon } from 'images/icons';

 
const CouponDrawer = () => {
    return (
        <BackDrop>
            <ModalContainer>
                <ModalHeader>
                    <Heading style={{color:"var(--drawer-text-color)", marginBottom: 0}}>Apply Coupon</Heading>
                    <CrossIcon color = {"var(--drawer-text-color)"} />
                    {/* <><MdClear style={{color:"var(--drawer-text-color)"}}/></> */}
                </ModalHeader>
                <ModalBody>
                    <Coupon />
                    <CouponList />
                </ModalBody>
            </ModalContainer>
        </BackDrop>
    )
}

export default CouponDrawer;