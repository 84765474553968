import { ConverstionSrCard, ReviewCard } from "UiComponents/Cards";
import { accordianType } from "UiComponents/SideBar/data";
import { AakashIcon, AdvancedAnalyticsIcon, BeatIcon, BodyCareIcon, CaffeineIcon, CrownImage, MinusIcon, PersonalizeIcon, PlusIcon, PrefilledIcon, RelexoIcon, SRlogoPurple, ShopifyLogo, TickIcon, UltimateDiscountIcon } from "images/icons";
import React, { useState } from "react";
// import MobileImage from "../../images/Mobile.png"
const MobileImage = require("../../images/MobileBackground.png");
const DemoImage = require('../../images/DemoImage.png')
const WorkingImage = require('../../images/CTA.png')
const StepImage=require('../../images/working.png')
const Founder1=require('../../images/Founder1.png')
const Founder2=require('../../images/Founder2.png')
const Founder3=require('../../images/Founder3.png')
const Brand1=require('../../images/MarsImage.png')
const Brand2=require('../../images/BlackberrysImage.png')
const Brand3=require('../../images/SkullcandyImage.png')
const Brand4=require('../../images/RarerabbitImage.png')
const Brand5=require('../../images/AvimeeImage.png')



const LandingPage = ({ setShowLanding }: any) => {

    const [displayAccordion,setDisplayAccordion]=useState<any>({})

    const LandingArray: any = [{
        heading: 'Personalized checkout',
        content: 'Align your checkout theme with your brand, configure shipping settings, minimise COD orders & customise prepaid methods to reduce drop-offs.',
        icon: <PersonalizeIcon />,
    },
    {
        heading: 'Pre-Filled Addresses',
        content: 'Help your customers skip long address forms, ensure high address accuracy & provide a seamless web and mobile experience.',
        icon: <PrefilledIcon />,
    },
    {
        heading: 'Ultimate Discount Engine',
        content: 'Smart discount engine to generate over 10,000 unique possibilities and millions of coupon codes',
        icon: <UltimateDiscountIcon />
    },
    {
        heading: 'Advanced Analytics',
        content: 'Access detailed analytics & data insights with an integrated google analytics funnel for comprehensive tracking.',
        icon: <AdvancedAnalyticsIcon />
    }
    ]

    const PlanPoints = ['Address Intelligence with Autofill Available', 'All Payment Options UPI / COD / Debit & Credit', 'Card / Net Banking / Wallet', 'Expected Delivery Date Available', 'Discounts / Shipping Charges', 'Abandon Cart Message Integration Link Available', 'Dynamic Shipping Visibility Available', 'Custom Rules COD Charge or Blocking / Prepaid']

    const FAQArray = [{'key':1,'question': 'How does Shiprocket Checkout prefill addresses?', 'answer': 'Shiprocket has delivered orders to over 150 Million customers so far. We pre-fill the addresses on the basis of our past delivery data for all the orders we have Delivered to the customers.' }
        , {'key':2,'question': 'What is the address prefill rate?', 'answer': 'Our address pre-fill rate is more than 95%. Shiprocket has been in the delivery system for the past 11 years, and has validated delivery address data of over 150 Million customers, & adding 2 Crore+ order per month to it.' },
    { 'key':3,'question': 'How much time will it take for Shiprocket Checkout to be integrated on my store?', 'answer': 'Post receiving necessary access permissions of your online store, we will create a demo Checkout experience for you within a few hours. On receiving your approval, we will make it visible on your online store.' },
    {'key':4, 'question': 'Do I have to change my Payment Gateway?', 'answer': 'No, you don’t have to. We are integrated with majority of Payment Gateway service providers in the market like Razorpay, Billdesk, Cashfree, Billdesk, Eazebuzz, PayU, Snapmint & Cred etc . We can seamlessly integrate your existing PG account with the Gateway on Checkout. If needed, we also help you getting onboarded on any of the listed PG service providers.' },
    { 'key':5, 'question': 'Does Shiprocket Checkout support all payment modes?', 'answer': 'Yes, we support all payment modes. Majorly used Prepaid payment modes via our platform are UPI, Credit/Debit Cards, Netbanking, Wallets, BNPL and EMI options.' },
    ]

    const handleAccordionClick = (name: string) => {
        let temp: accordianType = {}
        temp[name] = !displayAccordion[name]
        setDisplayAccordion({ ...displayAccordion, ...temp })
    }

    return <>
        <div className="flex flex-col bg-white px-6 py-8">
            <div style={{ backgroundImage: `url(${MobileImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className="flex px-16 py-12">
                <div className="flex flex-col gap-2">
                    <div className="mt-12 h-12 w-48"><SRlogoPurple /></div>
                    <p className="text-5xl font-bold mt-3">Convert in Just
                        <br />
                        <span style={{
                            background: 'linear-gradient(to right,#7b62e8,#2ab95c)'
                            , WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'
                        }}>40 Seconds</span>
                    </p>
                    <p className="mt-12 font-base">Conversions made easy, Shopping made fun</p>
                    <button type="button" className="focus:outline-none mt-12 w-40 text-white bg-purpleText focus:ring-0 font-medium rounded-lg text-sm py-2.5 mb-2 dark:bg-purpleText dark:hover:bg-purpleText shadow-2xl" onClick={() => { setShowLanding(false) }}>Get Started</button>

                </div>
            </div>
            <div className="flex flex-col gap-4 mt-16 pl-8">
                <p className="text-4xl font-bold text-headingBlue">Our  <span style={{
                    background: 'linear-gradient(to right,#7b62e8,#2ab95c)'
                    , WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'
                }}>Clients</span></p>
                <p className="text-editCustomerGrey">2500+ brands are growing with us</p>
            </div>
            <div className="flex justify-evenly py-12 items-center">
                <div className="h-20 w-48 flex justify center items-center border border-1 rounded-xl border-IconBorder px-2"><img src={`${Brand1}`} alt="" /></div>
                <div className="h-20 w-48 flex justify center items-center border border-1 rounded-xl border-IconBorder px-2"><img src={`${Brand2}`} alt="" /></div>
                <div className="h-20 w-48 flex justify center items-center border border-1 rounded-xl border-IconBorder px-2"><img src={`${Brand3}`} alt="" /></div>
                <div className="h-20 w-48 flex justify center items-center border border-1 rounded-xl border-IconBorder px-2 py-4"><img src={`${Brand4}`} alt="" className="ml-4 h-36"/></div>
                <div className="h-20 w-48 flex justify center items-center border border-1 rounded-xl border-IconBorder px-2 py-4"><img src={`${Brand5}`} alt="" /></div>

            </div>
            <div className="pl-6 flex flex-col gap-6">
                <p className="font-semibold text-3xl">Boost Conversion and
                    <span className="ml-2" style={{
                        background: 'linear-gradient(to right,#7b62e8,#50e379)'
                        , WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'
                    }}>
                        elevate your Business
                    </span>
                </p>
                <div className="flex justify-between pr-20 flex-wrap">
                    {LandingArray.map((LandingObject: any) => {

                        return <ConverstionSrCard heading={LandingObject['heading']}
                            content={LandingObject['content']}
                            Icon={LandingObject['icon']}
                        />
                    })
                    }
                </div>
            </div>
            <div className="">
                <div className="flex items-center h-fit w-full gap-48 px-24 py-20">

                    <div className=""><img src={`${DemoImage}`} alt="" /></div>
                    <div className="flex flex-col w-5/12 gap-8">

                        <span className="text-4xl font-bold"><span style={{
                            background: 'linear-gradient(to right,#7a61e8,#50e379)'
                            , WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'
                        }}>Helping brands </span> Convert their <br />goals into reality<br />
                            <span className="text-base font-normal">We reached here with our hard work and dedication</span>
                        </span>
                        <div className="flex justify-between gap-8">
                            <div className="flex flex-col">
                                <p className="text-4xl text-headingBlue">60%</p>
                                <span className="text-ConversionGrey text-base tracking-wide">Increased Conversions</span>
                                <div
                                    style={{
                                        background: 'linear-gradient(to right,#95feac, #4d1ea5)',
                                        height: '4px',
                                        width: '192px'
                                    }}
                                ></div>
                            </div>

                            <div className="flex flex-col">
                                <p className="text-4xl text-headingBlue">95%</p>
                                <span className="text-ConversionGrey text-base tracking-wide">Address Pre-fill</span>
                                <div
                                    style={{
                                        background: 'linear-gradient(to right,#95feac, #4d1ea5)',
                                        height: '4px',
                                        width: '192px'
                                    }}></div>
                            </div>

                        </div>

                        <div className="flex justify-between">
                            <div className="flex flex-col">
                                <p className="text-4xl text-headingBlue">70%</p>
                                <span className="text-ConversionGrey text-base tracking-wide">Faster Checkout</span>
                                <div
                                    style={{
                                        background: 'linear-gradient(to right,#95feac, #4d1ea5)',
                                        height: '4px',
                                        width: '192px'
                                    }}
                                ></div>
                            </div>

                            <div className="flex flex-col">
                                <p className="text-4xl text-headingBlue">40%</p>
                                <span className="text-ConversionGrey text-base tracking-wide">Increased Prepaid Share</span>
                                <div
                                    style={{
                                        background: 'linear-gradient(to right,#95feac, #4d1ea5)',
                                        height: '4px',
                                        width: '192px'
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div style={{ backgroundImage: `url(${WorkingImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} className=" h-96 w-full flex items-center px-12">

                <div className="flex flex-col gap-3">
                    <span className="text-purpleText">Live Demo</span>
                    <span className="text-4xl font-bold text-headingBlue">Interested in seeing our <br /> product in action?</span>
                    <span className="text-base">Experience the power of one-click checkout firsthand</span>
                    <div className="flex gap-3">
                        <button type="button" className="focus:outline-none mt-12 w-28 text-white bg-purpleText focus:ring-0 font-medium rounded-lg text-sm py-2.5 mb-2 dark:bg-purpleText dark:hover:bg-purpleText" onClick={() => { setShowLanding(false) }}>Get Started</button>
                        <button type="button" className="focus:outline-none mt-12 w-28 text-white bg-purpleText focus:ring-0 font-medium rounded-lg text-sm py-2.5 mb-2 dark:bg-purpleText dark:hover:bg-purpleText" onClick={() => {
                            {/*// @ts-ignore */ }
                            HeadlessCheckout.InitiateDirectCheckout(
                                "", "cart",
                                [{
                                    variantId: 44370635423968,
                                    quantity: 1
                                }],
                                "", "", null)

                        }}>See in Action</button>
                    </div>
                </div>

            </div>
            <div style={{ background: 'linear-gradient(to right,#ffffff,#f4f2fd)' }} className="flex flex-col items-center flex-wrap py-16">
                <p className="text-3xl font-semibold">Our <span style={{
                    background: 'linear-gradient(to right,#7a61e8,#50e379)'
                    , WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'
                }}>Pricing</span></p>
                <div className="flex justify-evenly w-3/4 mt-16">
                    <div className="flex flex-col gap-2 h-fit w-fit px-4 py-8 relative border border-purpleText max-w-96 rounded-xl shadow-2xl bg-ProPlanBg text-white">
                        <div style={{ background: '#ffc008' }} className="text-black font-bold flex gap-2 w-fit px-4 py-1 rounded-xl absolute right-4 -top-4">Recommended</div>

                        <div><span className="text-3xl font-bold">2.5% </span> + GST per order <span className="font-bold text-lg">(PG Cost Included)</span></div>
                        <div className="font-semibold text-lg">Pro Plan</div>
                        <div className="flex flex-col gap-2">
                            {PlanPoints?.map((point) => {
                                return <span className="flex items-center gap-4"><span className="w-4">{TickIcon()}</span>{point}</span>
                            })}
                            <span style={{ color: '#1db660' }} className="flex items-center gap-4"><span className="w-4">{TickIcon()}</span>
                                Payment Gateway Partner availability - PayU | Billdesk | Easebuzz | Decentro (all)
                            </span>

                        </div>
                    </div>

                    <div className=" flex flex-col gap-2 h-fit w-fit px-4 py-8 relative border border-borderGrey max-w-96 rounded-xl shadow-2xl min-h-96 pb-24">
                        <div><span className="text-3xl font-bold text-headingBlue">2% </span> + GST per order <span className="font-bold text-lg text-headingBlue">(PG Cost excluded)</span></div>
                        <div className="font-semibold text-lg text-headingBlue">Standard Plan</div>
                        <div className="flex flex-col gap-2">
                            {PlanPoints?.map((point) => {
                                return <span className="flex items-center gap-2"><span className="w-4">{TickIcon()}</span>{point}</span>
                            })}

                        </div>
                        <div className="absolute bottom-2" style={{color:'grey',fontSize:''}}>Note - Your existing PG account will be configured on Checkout</div>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center px-36"><div  style={{backgroundImage: `url(${StepImage})`,backgroundSize: 'contain',backgroundRepeat:'no-repeat',height:'500px'}}className="w-11/12 mt-16 py-20 flex flex-col px-12 text-white">
            </div>
            </div>
            <div className="flex flex-col gap-8 mt-16">
                <div className="flex flex-col items-center justify-center">
                    <p className="text-3xl font-bold">What our <span style={{
                        background: 'linear-gradient(to right,#7a61e8,#50e379)'
                        , WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'
                    }}> Clients Say? </span></p>
                    <p className="text-base">Here is what our clients are saying about us.</p>
                    <div className="flex justify-evenly">

                    </div>

                </div>
                <div className="flex gap-2 justify-evenly flex-wrap px-40">
                    <ReviewCard Name="Divya Jain" Designation="Founder, Jhakas.com" content="We have seen growth in Prepaid orders, it has increased by around 30%. We have seen a dip a RTO orders through good quality address verification process & the RTO is around 25% reduced. The quality of addresses which they provide is really genuine and upto 90% the address are accurate. The team is really supportive and they help us in all the possible ways they can. " image={<img src={`${Founder3}`} alt="" className="rounded-3xl h-full w-full" />}
                    />
                    <ReviewCard Name="Nikita Malhotra" Designation="Co-founder  , Winston" content="Ever since we started using Shiprocket checkout we've seen a growth in the conversions by about 30%. What I like the most is that they have the best address pre-fill rate & the quality of address is amazing. Also seen downfall in the RTO by minimum of 20% and a rise in the prepaid orders. They have the best and really supportive team." image={<img src={`${Founder2}`} alt="" className="rounded-3xl h-full w-full" />} />
                    <ReviewCard Name="Mihir Mittal" Designation="Founder , The Hatke" content="The address pre-fill is best in the market and RTO has gone down by more than 25%. Checkout team is very supportive & we have been working with them day & night. Conversion has increased by about 35%, the product has been good. I cannot recommend it as muchc more than I want to."  image={<img src={`${Founder1}`} alt="" className="rounded-3xl h-full w-full" />} />

                </div>
            </div>
            <div style={{background:'#f8fafe'}} className="flex justify-evenly gap-24 pl-20 py-20 mt-2">
                <p className="text-5xl text-headingBlue font-bold">
                    Frequently<br/>
                    asked<br/>
                    questions
                </p>
                <div className="flex flex-col w-1/2">
                    {FAQArray?.map((FaqObj: any) => {
                        return <div className="mt-2">
                            <div className={` px-4 py-2 w-3/4 flex gap-4 font-bold text-lg items-center border-b-2 border-AccordionBorder`} onClick={()=>{handleAccordionClick(FaqObj?.key)}}><span className="w-4 h-4 flex items-center">{displayAccordion[FaqObj?.key]?<span><MinusIcon/></span>:<PlusIcon />} </span><span className={`${displayAccordion[FaqObj?.key]?'text-purpleText':'text-black'}`}>{FaqObj?.["question"]} </span></div>
                            <div className={`pt-4 w-3/4 overflow-hidden transition-[max-height] duration-500 ease-in-out pl-12 ${displayAccordion[FaqObj?.key] ? "max-h-96" : "max-h-0"}`
                                }>{FaqObj?.['answer']}</div>

                        </div>
                    })}
                </div>

            </div>

            {/* <div className="flex flex-col items-center justify-center mt-16">
                <p className="font-bold text-3xl text-headingBlue">FAQs</p>
                <div className="flex flex-col gap-2 w-full justify-center items-center mt-6">
                    {FAQArray?.map((FaqObj: any) => {
                        return <>
                            <div className="px-4 py-2 border border-ReviewBorder w-3/4 flex justify-between">{FaqObj?.["question"]} <PlusIcon /></div>
                        </>
                    })}
                </div>
            </div> */}
        </div>

    </>

}
export default LandingPage