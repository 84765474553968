import React from "react";
import Radio from "components/CheckoutBuilder/UIComponents/Radio";
import { 
      ModalContainer,
        ModalHeader,
        BackDrop,
        ModalBody,
 } from "../ExitFormDrawer/styled";
import { Badge, Heading } from "../styled";
import { Text, TextSmall,Button } from "../styled";
import { addressList } from "./data";
import { AddressContainer } from "./style";
import { FlexBox, SpaceBetweenContainer } from "components/UIElements";
import { CrossIcon } from "images/icons";

const AddresDrawer = ({setIntractiveCheckout, isPreview, isCardDesign}:any) => {
  const closeAddressDrawer = () => {
    if(isPreview){
      setIntractiveCheckout((prev:any) => ({...prev, previewShowAddress:false}))
    }
  }

  return (
    <BackDrop 
    onClick={closeAddressDrawer} 
    className="cursor-pointer"
    >
      <ModalContainer>
        <ModalHeader>
          <Heading style={{color: 'var(--drawer-text-color)'}}>Saved addresses</Heading>
          <CrossIcon color = {"var(--drawer-text-color)"} />
          {/* <><MdClear style={{color:"var(--drawer-text-color)"}} /></> */}
        </ModalHeader>
        <ModalBody>
        <TextSmall style={{fontWeight: 400}}>
          Including addresses related to <br /><b>+91 999999999</b> where Shiprocket has previously delivered orders.
        </TextSmall>
        <SpaceBetweenContainer>
          <Heading>All addresses</Heading>
          <Button>+Add new</Button>
        </SpaceBetweenContainer>
        {
          addressList?.map((address:any, index) => {
            return (
              <AddressContainer isCardDesign = {isCardDesign} selected = {index === 0 ? true : false }>
                <SpaceBetweenContainer>
                <FlexBox style={{alignItems:'center', gap: 4}}>
                  <Radio 
                    options={[{label: address?.name, value: address?.name}]}
                    onChange={(value) => {console.log(value);}}
                    smallFont = {true}
                    selected="Rizwan"
                    styleContainerMargin = {{marginBottom: 4}}
                    isPreview = {isPreview}
                  /> 
                    <Badge>{address.badge}</Badge>
                  </FlexBox>
                  {isCardDesign && 
                    <img src = "https://fastrr-boost-ui.pickrr.com/assets/images/threeDot.svg" />
                  }
                  </SpaceBetweenContainer>
                  <div className="ml-5">
                    <Text weight={400}>{address?.address}</Text>
                    <Text weight={400}>{address?.email}</Text>
                  </div>
                  {!isCardDesign && 
                    <FlexBox style={{alignItems:'center', gap: 4, marginLeft: 16}} >
                    <img src = "https://fastrr-boost-ui.pickrr.com/assets/images/edit-icon.svg" />
                    <img src = "https://fastrr-boost-ui.pickrr.com/assets/images/delete-icon.svg" />
                    </FlexBox>
                  }
              </AddressContainer>
            )
          })
        }
        </ModalBody>
      </ModalContainer>
    </BackDrop>
  );
};

export default AddresDrawer;
