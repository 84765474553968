export const paymentMethodsData:any = [
    {
        title:'UPI', 
        saveAmount:'', 
        totalAmount:null, 
        finalAmount:'₹8868.90', 
        paymentMethodIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/payment/codIconCard.svg', 
        discountIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/svg/discount_icon.svg', 
        showDiscount: false, 
        id: 1, 
    }, 
    {
        title:'Cash on delivery', 
        saveAmount:'Pay ₹8868.90 (Inc. ₹173.90 COD Charges)', 
        totalAmount:null, 
        finalAmount:'₹8868.90', 
        paymentMethodIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/payment/codIconCard.svg', 
        discountIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/svg/discount_icon.svg', 
        showDiscount: false, 
        id: 1, 
    }, 
    {
        title:'Pay with Emi', 
        saveAmount:'₹50', 
        totalAmount:'₹8695.00', 
        finalAmount:'₹8845.90', 
        paymentMethodIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/payment/CardbnplIcon.svg', 
        discountIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/svg/discount_icon.svg', 
        showDiscount: true, 
        id: 2, 
        key: "EMI"
    }, 
    {
        title:'Wallet', 
        saveAmount:'₹50', 
        totalAmount:'₹8695.00', 
        finalAmount:'₹8845.90', 
        paymentMethodIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/payment/CardwalletIcon.svg', 
        discountIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/svg/discount_icon.svg', 
        showDiscount: true, 
        id: 3, 
        key: "WALLETS"
    }, 
    {
        title:'NetBanking', 
        saveAmount:'₹50', 
        totalAmount:'₹8695.00', 
        finalAmount:'₹8845.90', 
        paymentMethodIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/payment/CardnetbankingIcon.svg', 
        discountIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/svg/discount_icon.svg', 
        showDiscount: true, 
        id: 4, 
        key: "NETBANKING"
    }, 
    {
        title:'Credit/Debit Card', 
        saveAmount:'₹50', 
        totalAmount:'₹8695.00', 
        finalAmount:'₹8845.90', 
        paymentMethodIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/payment/CardcardIcon.svg', 
        discountIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/svg/discount_icon.svg',
        showDiscount: true, 
        id: 5, 
        key: "CARDS"
    }, 
    {
        title:'Credit/Debit Card', 
        saveAmount:'₹50', 
        totalAmount:'₹8695.00', 
        finalAmount:'₹8845.90', 
        paymentMethodIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/payment/Cardother.svg', 
        discountIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/svg/discount_icon.svg', 
        showDiscount: false, 
        id: 6, 
    }
]