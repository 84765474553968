export const exitReasonsOptions: any = [
  { label: "I prefer not to provide my mobile number", value: "I prefer not to provide my mobile number" },
  { label: "I need to add or modify items in my cart", value: "I need to add or modify items in my cart" },
  { label: "Found a better deal elsewhere", value: "Found a better deal elsewhere" },
  { label: "I changed my mind", value: "I changed my mind" },
  { label: "Technical issues with the website", value: "Technical issues with the website" },
  { label: "I'm unsure about the return and cancellation policy", value: "I'm unsure about the return and cancellation policy" },
  { label: "Others (please specify)", value: "Others (please specify)" },
];

  export const fomoOptions:any = [
    {label: 'Products in huge demand might run Out of Stock', value: 'Products in huge demand might run Out of Stock'}, 
    // {label: 'Offer ends in 02:55:59', value: 'Offer ends in 02:55:59'}, 
    // {label: 'Grab your X% discount before it\'s gone!', value: 'Grab your X% discount before it\'s gone!'}, 
    {label: 'You might miss out on savings of ₹X', value: 'You might miss out on savings of ₹X'}, 
]


export const exitFormSelectionType:any = [
  { label: "Single select", value: 'single' },
  { label: "Multiple select", value: 'multiple' },
];