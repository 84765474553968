import { Button, Input } from 'antd'
import { FlexColContainer, PickrrHeading, SpaceBetweenContainer } from 'components/UIElements'
import React from 'react'

const StoreCodes = ({storeCodesList,setStoreCodesList}) => {
    const { TextArea } = Input;

  return (
    <FlexColContainer>
        <SpaceBetweenContainer>
        <PickrrHeading size={5}>Store Codes</PickrrHeading>
        <Button type="link" onClick={() => setStoreCodesList([])}>
          Reset
        </Button>
        </SpaceBetweenContainer>
        <TextArea
        placeholder="Add comma separated store codes list"
        rows={4}
        className="mb-16"
        value={storeCodesList?.join(',')}
        onChange={(e) =>
          e.target.value
            ? setStoreCodesList(e.target.value.split(','))
            : setStoreCodesList([])
        }
      />
    </FlexColContainer>
  )
}

export default StoreCodes