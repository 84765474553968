import styled from "styled-components";

export const AccordianContainer = styled.div`
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    margin-bottom: 24px;
`

export const AccordianHeader = styled.div`
    display:flex;
    justify-content: space-between;
    background: #929292;
    padding: 16px;
    border-radius: 4px 4px 0px 0px;
    background: rgba(183, 181, 188, 0.20);
    cursor:pointer;
`

export const AccordianHeading = styled.h4`
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
`

export const AccordianContent = styled.div`
    padding: 20px;
`

