import React, {useContext, useEffect} from "react";
import { UiConfigContext } from "context/CustomUIContext";
import ColorPicker from "../UIComponents/ColorPicker";
import ThemeColors from "./components/ThemeColors";
import Checkout from "components/Checkout";
import { Heading, SubHeading, SectionContainer } from "../styled";
import { FlexBox } from "components/UIElements";
import { ColorPickerContainer } from "./styled";
import { Section } from "../Elements/styled";
import Radio from "../UIComponents/Radio";

const ColorScheme = ({componentKey}:any) => {
  const {themeColor, setThemeColor, headerConfig, templateConfig} = useContext(UiConfigContext);
  
  useEffect(() => {
    document.documentElement.style.setProperty('--logo-alignment', headerConfig?.logoAlignment);
  }, [headerConfig?.logoAlignment])

  useEffect(() => {
    if(headerConfig?.isHeaderGradient){
        document.documentElement.style.setProperty('--header-bg-color', `linear-gradient(to right, ${headerConfig?.headerBgColor}, white)`); 
    }else {
        document.documentElement.style.setProperty('--header-bg-color', headerConfig?.headerBgColor);
    }

    document.documentElement.style.setProperty('--drawer-bg-color', headerConfig?.drawerHeaderBgColor);
    document.documentElement.style.setProperty('--drawer-text-color', headerConfig?.drawerHeaderColor);

  }, [headerConfig?.headerBgColor, headerConfig?.isHeaderGradient])

  useEffect(() => {
    document.documentElement.style.setProperty('--header-back-icon-color', headerConfig?.headerIconColor);
  }, [headerConfig?.headerIconColor])


  const bodyPrimaryColor = [
    {label: "White", value: "#f1f1f1"}, 
    {label: "Black", value: "#222222"}, 
  ]

  const bodySecondryColor = [
    {label: "Grey", value: "#4e596f"}, 
    {label: "Black", value: "#222222"}, 
    {label: "White", value: "#222222"}, 
  ] 


  return (
    <SectionContainer> 
      <Heading>Colour Scheme Selection</Heading>
      <SubHeading>
        Pick colours that resonate with your brand's personality
      </SubHeading>
      <ColorPickerContainer>
        <ColorPicker 
          label = "Theme"
          infoText = "Color changes will be reflected in all CTA buttons, icons, and highlights to guide users' attention"
          color={themeColor.primaryColor}
          onChange={(value:any) => setThemeColor((prev:any) => ({...prev, primaryColor: value}))}
          uniqueValue ={"theme"}
        />
        {templateConfig?.selectedTemplate === "newLayout" && 
          <ColorPicker 
            label = "Backkground"
            infoText = "Color changes will be applied to the background of the checkout"
            color={themeColor.bgColor}
            onChange={(value:any) => setThemeColor((prev:any) => ({...prev, bgColor: value}))}
            uniqueValue ={"bg"}
          />
        }
      </ColorPickerContainer>
      {/* <FlexBox style={{gap: 40}}>
      <Radio
          title="Body Primary Color"
          options={bodyPrimaryColor}
          selected={themeColor?.bodyPrimaryTextColor}
          onChange={(value) => {
            setThemeColor((prev:any) => ({...prev, bodyPrimaryTextColor: value}))
          }}
        />

        <Radio
          title="Body Secondary Color"
          options={bodySecondryColor}
          selected={themeColor?.bodySecondryTextColor}
          onChange={(value) => {
            setThemeColor((prev:any) => ({...prev, bodySecondryTextColor: value}))
          }}
        />
        </FlexBox> */}
      
      <ThemeColors themeColor = {themeColor} setThemeColor = {setThemeColor} />
      <Section><p>CHECKOUT PREVIEW</p></Section>
      <FlexBox>
        <Checkout 
          showLogin = {true}
        />
        <Checkout 
          showDelivery = {true}
          showShipping = {true}
          showPayment = {true}
        />
        <Checkout 
          showDelivery = {true}
          showShipping = {true} 
          showCoupon =  {true}
        />
      </FlexBox>
    </SectionContainer>
  );
};

export default ColorScheme;