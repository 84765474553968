import { theme } from "antd";

export const preparePayload = (
  paymentMethod: any,
  selectedPaymentMethodColor: any,
  // headerBgColor: string,
  // logoAlignment: string,
  // headerIconColor: string,
  sellerLogo: string,
  preLoginBanner: any,
  postLoginBanner: any,
  exitReasons: any,
  otherPaymentMethods: any,
  // selectedTemplate: any,
  themeColor: any,
  fomoExit: any,
  // templateName: any, 
  editData:any, 
  // isHeaderGradient:any, 
  isActiveTemplate:boolean, 
  headerConfig:any, 
  templateConfig:any, 
  isPostLoginBannerSame:any
) => {
  
  console.log(otherPaymentMethods, 'checking other payment methods')



  const payload: any = {
    ...(editData?.id && {id:editData?.id }),
    ...(editData?.createdBy && {createdBy:editData?.createdBy }),
    ...(editData?.createdAt && {createdAt:editData?.createdAt }),
    templateName: templateConfig?.templateName,
    active: isActiveTemplate, 
    sellerUi: {
      logUrl: sellerLogo,
      scarcityDisplayText: fomoExit?.reason,
      scarcityFeatureChoice: fomoExit?.isActive ? 'ENABLED' : 'DISABLED',
      exitResponseDetails: [...exitReasons?.selectedReasons],
      allowSingleExitReason: exitReasons?.selectType === "single" ? true : false,
      exitFormEnabled: exitReasons?.enableExitForm,
      bannerDetails: {
        preLogin: {
          ...getBannerDetails(preLoginBanner),
        },
        postLogin: {
          ...getBannerDetails(postLoginBanner),
        },
      },

     cardDesign: templateConfig?.selectedTemplate === "newLayout" ? true : false,
      uiConfig: {
        themeColor: {
          primary: themeColor?.primaryColor,
          secondary: themeColor?.inactiveColor,
          primaryLightColor: themeColor?.lightColor,
        },

        layout: {
          body: {
            bgColor: themeColor.bgColor,
          },
          header: {
            bgColor: headerConfig?.isHeaderGradient ? `linear-gradient(to right, ${headerConfig?.headerBgColor}, white)` : headerConfig?.headerBgColor,
            logoAlign: headerConfig?.logoAlignment,
            logoMargin: headerConfig?.logoAlignment === "flex-start" ? "24px" : "0px",
            iconColor: headerConfig?.headerIconColor,
          },
          payment: {
            bgColor: selectedPaymentMethodColor?.isApplyGradient
              ? `linear-gradient(to right, ${selectedPaymentMethodColor?.bgColor}, white)`
              : selectedPaymentMethodColor?.bgColor,
            color: selectedPaymentMethodColor?.textColor,
            priceTextHoverColor: selectedPaymentMethodColor?.textColor,
          },
          drawer: {
              drawerBgColor:"#FFFFFF",
              drawerColor: headerConfig?.drawerHeaderColor,
              drawerHeaderBgColor: headerConfig?.drawerHeaderBgColor
          },
        },
        postLoginBannerSame: isPostLoginBannerSame
      },
      otherPaymentDetails: {
        is_active: otherPaymentMethods?.isActive,
        ...(otherPaymentMethods?.methods?.length && {other_payment_name: otherPaymentMethods?.methods?.map((method:any) => method?.title)}),
      },
    },
    paymentConfig: {
      otherPaymentMethodsDisplayOrder: getPaymentMethodNameList(paymentMethod),
    },
  };

  console.log(payload, "checking seller logo");

  return payload;
};

const getExitFormList = (exitReasons: any) => {
  return exitReasons?.map((reason: any) => reason?.value);
};

const getPaymentMethodNameList = (paymentMethods: any) => {
  return paymentMethods?.map((value: any) => value?.title);
};

const getBannerDetails = (bannerConfig: any) => {
  const {
    bannerBgColor,
    bannerTextColor,
    bannerText,
    bannerTypeSelect,
    textTypeSelect,
    fontStyleSelect,
    fontSizeSelect,
  } = bannerConfig;

  return {
    text: bannerText,
    textColor: bannerTextColor,
    backgroundColor: bannerBgColor,
    fontSize: fontSizeSelect,
    type: bannerTypeSelect,
    subType: textTypeSelect,
    fontWeight: fontStyleSelect,
  };
};
