import React, { useContext } from "react";
import { Heading, SubHeading, SectionContainer } from "../styled";
import { Container } from "../styled";
import Button from "../UIComponents/Button";
import { FlexBox, SpaceBetweenContainer } from "components/UIElements";
import Stepper from "../Stepper";
import Template from "./Template";
import { BackIcon } from "images/icons";
import { UiConfigContext } from "context/CustomUIContext";

const TemplateSelection = ({
  stepHandle,
  currentStep,
  setCurrentStep,
  setShowTempleteSettings
}: any) => {

  const {resetConfig} = useContext(UiConfigContext)

  const handleBack = () => {
    resetConfig();
    setCurrentStep(1)
    setShowTempleteSettings(false);
  }


  return (
    <>
      <Container>
        <Stepper currentStep={currentStep} setCurrentStep={setCurrentStep} />
        <SpaceBetweenContainer style={{flexWrap: 'wrap'}}>
          <div>
            <Heading>Template Selection</Heading>
            <SubHeading>
              Select from two pre-designed templates to get started
            </SubHeading>
          </div>
          <FlexBox style={{gap: 24}}>
            <FlexBox style={{alignItems:'center', gap: 8}} onClick={handleBack}>
                <BackIcon />
                <Button type = "secondary" title = "Back to home"/>
            </FlexBox>
            <Button title="Next" onClick={stepHandle} />
          </FlexBox>
        </SpaceBetweenContainer>
      </Container>
      <Container>
        <Template />
      </Container>
    </>
  );
};

export default TemplateSelection;
