import { SuccessImage } from "images/icons";
import { useQueryClient } from "react-query";
import React, { useContext } from "react";
import {Heading, SubHeading, TextHeadingWithLine, DefaultText } from '../../styled';
import Button from "../Button";
import { DataContext } from "context/DataProvider";
import { UiConfigContext } from "context/CustomUIContext";



const SaveSuccessModal = ({setShowSuccessModal, setShowTempleteSettings, callback, loading, isSaveTemplate, currentInit }: any) => {
    const queryClient = useQueryClient();
    const { sellerConfig } = useContext(DataContext);
    const { templateConfig, resetConfig } = useContext(UiConfigContext);
    

    // const handleComplete = () => {
    //     queryClient.invalidateQueries('getAllTemlates');
    //     setShowTempleteSettings(false);
    //     setShowSuccessModal(false)
    // }

    const handleComplete = () => {
        queryClient.invalidateQueries('getAllTemlates');
        setShowTempleteSettings(false);
        setShowSuccessModal(false)
        currentInit()
        resetConfig()
    }

    const sellerDomain = sellerConfig?.domain_name?.includes("https") ? sellerConfig?.domain_name : `http://${sellerConfig?.domain_name}`

    return <>
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
            <div className="fixed inset-0 bg-black opacity-50" 
                // onClick={() => { setShowSuccessModal(false) }} 
            />
            <div className="flex flex-col justify-center items-center relative z-50 bg-refundWhiteBg rounded-lg p-10 w-2/5">
               <div className="flex items-center justify-center -mt-5"> <SuccessImage /></div>
               <Heading className="mt-4"> Your changes has been saved to {templateConfig?.templateName}</Heading>
               <div className="flex gap-2 items-center justify-center mb-6">
                    <DefaultText style={{color:"#000000"}}>Publish to see your changes live at your store</DefaultText>
                    <Button title = "Publish" type = "ghost"  size = {"xs"} onClick = {() => callback(true)} disabled = {loading} />
               </div>
               <Button title = "Done" onClick = {handleComplete} />
            </div>
            
        </div>
    </>
}
export default SaveSuccessModal