import styled from "styled-components";


export const DiscountInputContainer = styled.div`
    height: 40px;
    position:relative;
    display:flex;
    justify-content:space-between;
    gap: 10px;
    width: 100%;
`;

export const IconImage = styled.img`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
`