import styled from "styled-components";

export const Container = styled.div`
  margin: 22px 0;
  background: #fff;
  // border: 1px solid #ccc;
  // border-radius: 8px;
  // padding: 16px;
`;

interface ListItemProps {
  isDragging?: boolean;
}

export const ListItem = styled.div<ListItemProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 8px;
  background: #ffffff;
  border-radius: 8px;
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: grab;
  border: 2px solid ${(props) => (props.isDragging ? "#6F57E94D" : "#F0F0F0")};
  text-transform: capitalize;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: #f1f1f1;
    border: ${(props) => (!props.isDragging ? "2px solid #F0F0F0" : "2px solid 6F57E94D")};
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: var(--main-text-color);
  }
`;

export const Handle = styled.div`
  font-size: 20px;
  color: #888;

  &:hover {
    color: #555;
  }
`;

// export const ListItem = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 12px 16px;
//   margin-bottom: 8px;
//   background: #f9f9f9;
//   border-radius: 4px;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
//   cursor: grab;

//   &:last-child {
//     margin-bottom: 0;
//   }

//   &:hover {
//     background: #f1f1f1;
//   }

//   span {
//     font-size: 16px;
//     font-weight: 500;
//     color: #333;
//   }
// `;

// export const Handle = styled.div`
//   font-size: 20px;
//   color: #888;
//   cursor: grab;

//   &:hover {
//     color: #555;
//   }
// `;
