export const addressList = [
    {
        name: 'Rizwan', 
        address: '416, Udyog Vihar III, Sector 20, Gurugram, Haryana 122008', 
        email: 'checkout@checkout.com', 
        badge: "Home"
    }, 
    {
        name: 'Pickrr', 
        address: '6th floor, ENKAY SQUARE, 448-A, Udyog Vihar Phase V Rd, Phase V, Udyog Vihar, Sector 19, Gurugram, Haryana 122022', 
        email: 'pickrr@checkout.com', 
        badge: "office"
    }, 
    {
        name: 'Shiprocket', 
        address: '6th floor, ENKAY SQUARE, 448-A, Udyog Vihar Phase V Rd, Phase V, Udyog Vihar, Sector 19, Gurugram, Haryana 122022', 
        email: 'shiprocket@checkout.com', 
        badge: "Ghar"
    }, 
    {
        name: 'Custom UI', 
        address: '416, Udyog Vihar III, Sector 20, Gurugram, Haryana 122008', 
        email: 'custom@checkout.com', 
        badge: "Home"
    }, 
    {
        name: 'Shiprocket Checkout', 
        address: '416, Udyog Vihar III, Sector 20, Gurugram, Haryana 122008', 
        email: 'checkout@checkout.com', 
        badge: "Custom"
    }, 
]