import React, { useState } from "react";
import CustomInputBox from "components/UIElements/CustomInputBox";
import { Checkbox, Col, Select, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { getGeneralDetails } from 'components/Settings/GeneralSettings/script';
import { useQuery } from 'react-query';
import MultipalDiscounts from "./MultipalDiscounts";
import {
  FlexColContainer,
  Container,
  ContainerDisableShipping,
  ContainerCustomRule,
  LabelSliderPercentage,
  CoverageText,
  LabelSlider,
  DropdownContainer,
  DropdownHeader,
  DropdownList,
  ListItem,
  RuleContainer,
  CoverageContainer,
  CoverageTitle,
  InfoIcon,
  SliderWrapper,
  SliderContainer,
  StyledSlider,
  ValueBubble,
  PercentageLabel,
  SelectedRuleContainer,
  CustomRuleAddButton,
  SelectRuleTypeText,
  IconWrapper,
  CoverageTextContainer,
  FlagContainer,
  FlagLabel
} from "./styled";
import ProductSearch from 'components/Settings/Discount/CreateDiscountModal/Configuration/ProductSearch';
import { InfoCircleOutlined } from "@ant-design/icons";import HyperlocalConfig from './HyperlocalConfig';

const EnterValue = ({
  ruleName,
  ruleValuePercent,
  ruleValueAbsolute,
  setRuleValuePercent,
  setRuleValueAbsolute,
  fastrrShipping,
  setFastrrShipping,
  noRushShipping,
  setNoRushShipping,
  standardShipping,
  setStandardShipping,
  applyMaxValue,
  setApplyMaxValue,
  shipping_disable,
  setShippingDisable,
  payment_modes_discount,
  setPaymentModesDiscount,
  partialCodShipping,
  setPartialCodShipping,
  patialType,
  setPatialType,
  partialValue,
  setPartialValue,
  isABTesting,
  isOpen,
  setIsOpen,
  typeAisOpen,
  settypeAIsOpen,
  typeBisOpen,
  settypeBIsOpen,
  selectedRule,
  setSelectedRule,
  typeARule,
  setTypeARule,
  typeBRule,
  setTypeBRule,
  coverage,
  setCoverage,
  rules,
  handleRuleBChange,
  handleRuleBNestedChange,
  ruleBSettings,
  isCustomABRuleEditing,
  ruleATag,
  ruleBTag,
  setRuleATag,
  setRuleBTag,
  upsellItems,
  setUpsellItems,
  shippingMethods,
  setShippingMethods
}) => {
  const [isError, setIsError] = useState(false);
  const percentageRegex = /^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)%$/;
  const quantityRegex = new RegExp(/^(((?!0)[0-9]*))$/);

  const shippingDisableChange = (key) => {
    setShippingDisable((previousState) => {
      return { ...previousState, [key]: !previousState[key] };
    });
  };
  const onlyShippingDisale = (key) => {
    setPartialCodShipping((previousState) => {
      return !previousState;
    });
  };
  const { Option } = Select;
  const handleValidate = (e) => {
    setPartialValue(e.target.value);
    if (patialType === "percentage" && e.target.value > 100) {
      setIsError(true);
    } else {
      setIsError(false);
    }}
    // const quantityRegex = new RegExp(/^(((?!0)[0-9]*))$/);
    
    const { data: configData } = useQuery(["generalSettings"], getGeneralDetails);

     const [configCustomerTypes, setConfigCustomerTypes] = useState({
        buysType: "product",
        getsType: "",
      });
      const sellerConfigValues = {
        seller_id: configData?._id,
        domain_name: configData?.domain_name, 
        channel: configData?.platform_details?.channel,
      }
    // const shippingDisableChange =(key)=>{
    //     setShippingDisable((previousState)=>{
    //         return {...previousState,[key]:!previousState[key]}
    //     })
    // }

    // if()
    // if(percentageRegex.test(e?.target?.value?.toString())){
    // //   setIsError(percentRegex.test(e.target.value));
    //   console.log('valid regex')
    // }else {
    //   setIsError(false);
    // }
  // };

  const toggleRuleSelection = (ruleType) => {
    setSelectedRule((prevSelectedRules) => {
      const isRuleSelected = prevSelectedRules.some(
        (rule) => rule.key === ruleType.key
      );

      return isRuleSelected
        ? prevSelectedRules.filter((rule) => rule.key !== ruleType.key) // Remove if already selected
        : [...prevSelectedRules, ruleType]; // Add if not selected
    });
  };

  const toggleTypeARuleSelection = (ruleType) => {
    setTypeARule((prevSelectedRules) => {
      return [ruleType]; // Add if not selected
    });
  };

  const toggleTypeBRuleSelection = (ruleType) => {
    setTypeBRule((prevSelectedRules) => {
      return [ruleType]; // Add if not selected
    });
  };
  const deleteRule = (index) => {
    setSelectedRule((prevSelectedRules) =>
      prevSelectedRules.filter((_, i) => i !== index)
    );
  };

  const ruleTagRejax = /^[a-zA-Z_ ]*$/;



  const renderRuleFields = (
    field,
    ruleFor = "forTypeA",
    custom_rule = false
  ) => {
    let rule_name = field ? field : ruleName;
    switch (rule_name) {
      case "prepaid_discount":
        return (
          <MultipalDiscounts
          isABTesting={isABTesting}
            custom_rule={custom_rule}
            payment_modes_discount={
              ruleFor === "forTypeA"
                ? payment_modes_discount
                : ruleBSettings.payment_modes_discount
            }
            setPaymentModesDiscount={(value) => {
              ruleFor === "forTypeA"
                ? setPaymentModesDiscount(value)
                : handleRuleBChange("payment_modes_discount", value);
            }}
            ruleValueAbsolute={
              ruleFor === "forTypeA"
                ? ruleValueAbsolute
                : ruleBSettings.ruleValueAbsolute
            }
            setRuleValueAbsolute={(value) =>
              ruleFor === "forTypeA"
                ? setRuleValueAbsolute(value)
                : handleRuleBChange("ruleValueAbsolute", value)
            }
            ruleValuePercent={
              ruleFor === "forTypeA"
                ? ruleValuePercent
                : ruleBSettings.ruleValuePercent
            }
            setRuleValuePercent={(value) =>
              ruleFor === "forTypeA"
                ? setRuleValuePercent(value)
                : handleRuleBChange("ruleValuePercent", value)
            }
            applyMaxValue={
              ruleFor === "forTypeA"
                ? applyMaxValue
                : ruleBSettings.applyMaxValue
            }
            setApplyMaxValue={(value) =>
              ruleFor === "forTypeA"
                ? setApplyMaxValue(value)
                : handleRuleBChange("applyMaxValue", value)
            }
          />
        );
      case "cod_charge":
        return (
          <Container custom_rule={custom_rule}>
            <CustomInputBox
              label="COD charges ₹"
              setvalue={(value) =>
                ruleFor === "forTypeA"
                  ? setRuleValueAbsolute(value)
                  : handleRuleBChange("ruleValueAbsolute", value)
              }
              value={
                ruleFor === "forTypeA"
                  ? ruleValueAbsolute
                  : ruleBSettings.ruleValueAbsolute
              }
            />
            <hr style={{ border: "1px solid rgba(0, 11, 52, 0.1)" }} />
            <CustomInputBox
              label="COD percentage %"
              setvalue={(value) =>
                ruleFor === "forTypeA"
                  ? setRuleValuePercent(value)
                  : handleRuleBChange("ruleValuePercent", value)
              }
              value={
                ruleFor === "forTypeA"
                  ? ruleValuePercent
                  : ruleBSettings.ruleValuePercent
              }
            />
            <Checkbox
              checked={
                ruleFor === "forTypeA"
                  ? applyMaxValue
                  : ruleBSettings.applyMaxValue
              }
              onChange={() => {
                ruleFor === "forTypeA"
                  ? setApplyMaxValue(!applyMaxValue)
                  : handleRuleBChange(
                    "applyMaxValue",
                    !ruleBSettings.applyMaxValue
                  );
              }}
              style={{ marginTop: "20px", color: "#0C3784" }}
            >
              Apply max value?
            </Checkbox>
          </Container>
        );
      case "cod_disable":
        return (
          <Container custom_rule={custom_rule}>
            <Checkbox checked={true} style={{ color: "#0C3784" }}>
              COD Disabled
            </Checkbox>
          </Container>
        );
      case "shipping_charge":
        return (
          <Container custom_rule={custom_rule}>
            <CustomInputBox
              label="Rush ₹"
              name="fastrrShipping"
              setvalue={(value) =>
                ruleFor === "forTypeA"
                  ? setFastrrShipping(value)
                  : handleRuleBChange("fastrrShipping", value)
              }
              value={
                ruleFor === "forTypeA"
                  ? fastrrShipping
                  : ruleBSettings.fastrrShipping
              }
            />
            <hr style={{ border: "1px solid rgba(0, 11, 52, 0.1)" }} />
            <CustomInputBox
              label="Express ₹"
              name="noRushShipping"
              setvalue={(value) =>
                ruleFor === "forTypeA"
                  ? setNoRushShipping(value)
                  : handleRuleBChange("noRushShipping", value)
              }
              value={
                ruleFor === "forTypeA"
                  ? noRushShipping
                  : ruleBSettings.noRushShipping
              }
            />
            <hr style={{ border: "1px solid rgba(0, 11, 52, 0.1)" }} />
            <CustomInputBox
              label="Standard ₹"
              name="standardShipping"
              setvalue={(value) =>
                ruleFor === "forTypeA"
                  ? setStandardShipping(value)
                  : handleRuleBChange("standardShipping", value)
              }
              value={
                ruleFor === "forTypeA"
                  ? standardShipping
                  : ruleBSettings.standardShipping
              }
            />
          </Container>
        );
      case "shipping_disable":
        return (
          <ContainerDisableShipping custom_rule={custom_rule}>
            <Col span={24}>
              <Checkbox
                checked={
                  ruleFor === "forTypeA"
                    ? shipping_disable.show_rush
                    : ruleBSettings.shipping_disable.show_rush
                }
                onChange={() =>
                  ruleFor === "forTypeA"
                    ? shippingDisableChange("show_rush")
                    : handleRuleBNestedChange(
                      "shipping_disable",
                      "show_rush",
                      !ruleBSettings.shipping_disable.show_rush
                    )
                }
                style={{ color: "#0C3784" }}
              >
                Show Rush
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox
                checked={
                  ruleFor === "forTypeA"
                    ? shipping_disable.show_express
                    : ruleBSettings.shipping_disable.show_express
                }
                onChange={() =>
                  ruleFor === "forTypeA"
                    ? shippingDisableChange("show_express")
                    : handleRuleBNestedChange(
                      "shipping_disable",
                      "show_express",
                      !ruleBSettings.shipping_disable.show_express
                    )
                }
                style={{ marginTop: "20px", color: "#0C3784" }}
              >
                Show Express
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox
                checked={
                  ruleFor === "forTypeA"
                    ? shipping_disable.show_standard
                    : ruleBSettings.shipping_disable.show_standard
                }
                onChange={() =>
                  ruleFor === "forTypeA"
                    ? shippingDisableChange("show_standard")
                    : handleRuleBNestedChange(
                      "shipping_disable",
                      "show_standard",
                      !ruleBSettings.shipping_disable.show_standard
                    )
                }
                style={{ marginTop: "20px", color: "#0C3784" }}
              >
                Show Standard
              </Checkbox>
            </Col>
          </ContainerDisableShipping>
        );
      case "partial_cod":
        return (
          <ContainerDisableShipping custom_rule={custom_rule}>
            <Col>
              <div style={{ display: "flex" }}>
                <Select
                  onChange={(value) =>
                    ruleFor === "forTypeA"
                      ? setPatialType(value)
                      : handleRuleBChange("partialType", value)
                  }
                  value={
                    ruleFor === "forTypeA"
                      ? patialType
                      : ruleBSettings.partialType
                  }
                >
                  <Option value="percentage">Percentage</Option>
                  <Option value="absolute">Absolute</Option>
                </Select>
                <Input
                  name={
                    patialType === "percentage"
                      ? "rule_value_percent"
                      : "rule_value_absolute"
                  }
                  maxLength={patialType === "percentage" ? 4 : 10}
                  value={
                    ruleFor === "forTypeA"
                      ? partialValue
                      : ruleBSettings.partialValue
                  }
                  onChange={(e) =>
                    ruleFor === "forTypeA"
                      ? handleValidate(e)
                      : handleRuleBChange("partialValue", e.target.value)
                  }
                  style={{ width: 200 }}
                />
              </div>
              {isError && (
                <div style={{ fontSize: 14, color: "red", marginTop: 6 }}>
                  Enter valid number
                </div>
              )}
            </Col>
            <Col span={24}>
              <Checkbox
                checked={
                  ruleFor === "forTypeA"
                    ? partialCodShipping
                    : ruleBSettings.partialCodShipping
                }
                onChange={() =>
                  ruleFor === "forTypeA"
                    ? onlyShippingDisale()
                    : handleRuleBChange(
                      "partialCodShipping",
                      !ruleBSettings.partialCodShipping
                    )
                }
                style={{ marginTop: "20px", color: "#0C3784" }}
              >
                Include Shipping Charges
              </Checkbox>
            </Col>
          </ContainerDisableShipping>
        );
        case "upsell_checkout": return (
          <div style={{width:'100%', minHeight:'100px'}}>
              <ProductSearch
                  selectedItems={upsellItems}
                  setSelectedItems={setUpsellItems}
                  type={configCustomerTypes.buysType}
                  sellerConfigValues={sellerConfigValues}
                  bundleKey={Math.random() * 1001}
                  isUpsell={true}
              // showReset
              />
              </div>)
        case "hyperlocal_shipping" : return (
            <div>
                <HyperlocalConfig shippingMethods={shippingMethods} setShippingMethods={setShippingMethods}/>
            </div>
        )  
      case "custom_rule":
        return !isABTesting ? (
          <ContainerCustomRule
            key={1}
            className={!isABTesting ? "visible" : ""}
          >
            {selectedRule?.length > 0 &&
              selectedRule?.map((rule, index) => (
                <>
                  <DropdownContainer>
                    <DropdownHeader>
                      {rule.label}
                      <DeleteOutlined
                        onClick={() => {
                          deleteRule(index);
                        }}
                      />
                    </DropdownHeader>
                    <SelectedRuleContainer>
                      {renderRuleFields(rule.key, "forTypeA", true)}
                    </SelectedRuleContainer>
                  </DropdownContainer>
                  {/* <Divider /> */}
                </>
              ))}

            {/* Rule Type Section */}
            <DropdownContainer>
              <DropdownHeader onClick={() => setIsOpen(!isOpen)}>
                Rule Type
                <CustomRuleAddButton>Add</CustomRuleAddButton>
              </DropdownHeader>

              <DropdownList open={isOpen}>
                {rules
                  ?.filter(
                    (rule) =>
                      !selectedRule.some(
                        (selected) => selected.key === rule.key
                      ) // Compare object keys
                  )
                  .map((rule, index) => (
                    <ListItem
                      key={index}
                      selected={selectedRule.includes(rule.key)}
                      onClick={() => {
                        toggleRuleSelection(rule);
                        setIsOpen(false);
                      }}
                    >
                      {rule.label}
                    </ListItem>
                  ))}
              </DropdownList>
            </DropdownContainer>
          </ContainerCustomRule>
        ) : (
          <ContainerCustomRule key={2} className={isABTesting ? "visible" : ""}>
            <CoverageContainer>
              {/* Coverage Title */}
              <CoverageTitle>
                Coverage
                {!isCustomABRuleEditing && (
                  <InfoIcon>
                    <InfoCircleOutlined />
                  </InfoIcon>
                )}
              </CoverageTitle>
              {isCustomABRuleEditing && (
                <CoverageTextContainer>
                  <IconWrapper>
                    <InfoCircleOutlined color="#2e5bff" size={16} />
                  </IconWrapper>
                  <CoverageText>
                    Any changes in experiment coverage will trigger a
                    recalculation of key performance metrics in A/B test rules.
                    To achieve more reliable results, we recommend running the
                    A/B test for a sufficient duration without modifications.
                  </CoverageText>
                </CoverageTextContainer>
              )}
              {/* Slider Section */}
              <SliderWrapper>
                {/* Type A Label */}
                <LabelSlider>
                  Type A <br></br>
                  <LabelSliderPercentage>{coverage}%</LabelSliderPercentage>
                </LabelSlider>

                {/* Slider Container */}
                <SliderContainer>
                  {/* Value Bubble */}
                  <ValueBubble value={coverage}>{coverage}</ValueBubble>

                  {/* Slider */}
                  <StyledSlider
                    type="range"
                    min="1"
                    max="99"
                    value={coverage}
                    onChange={(e) => setCoverage(Number(e.target.value))}
                  />

                  <PercentageLabel left={50} active={coverage === 50}>
                    Recommended
                  </PercentageLabel>
                </SliderContainer>

                {/* Type B Label */}
                <LabelSlider>
                  Type B<br></br>
                  <LabelSliderPercentage>
                    {100 - coverage}%
                  </LabelSliderPercentage>
                </LabelSlider>
              </SliderWrapper>
            </CoverageContainer>
            <RuleContainer>
              <DropdownContainer>
                <DropdownHeader onClick={() => !isCustomABRuleEditing && settypeAIsOpen(!typeAisOpen)}>
                  <div>
                    Type A |{" "}
                    {typeARule[0]?.key ? (
                      typeARule[0]?.label
                    ) : (
                      <SelectRuleTypeText
                        style={{ color: "rgba(146, 146, 146, 0.6)" }}
                      >
                        Select Rule Type
                      </SelectRuleTypeText>
                    )}
                  </div>
                  {!isCustomABRuleEditing && <span>Add</span>}
                </DropdownHeader>

                <DropdownList open={typeAisOpen}>
                  {rules.map((rule, index) => (
                    <ListItem
                      key={index}
                      selected={typeARule.includes(rule.key)}
                      onClick={() => {
                        toggleTypeARuleSelection(rule);
                        settypeAIsOpen(false);
                      }}
                    >
                      {rule.label}
                    </ListItem>
                  ))}
                </DropdownList>
                {typeARule?.length > 0 &&
                  typeARule?.map((rule) => (
                    <>
                      <DropdownContainer>
                        <SelectedRuleContainer disabled={isCustomABRuleEditing}>
                          {renderRuleFields(rule.key, "forTypeA", true)}
                          <FlagLabel >Flag</FlagLabel><br />
                          <FlagContainer placeholder='No space or special characters allowed except _' value={ruleATag} onChange={(e) => { ruleTagRejax.test(e.target.value) && setRuleATag(e.target.value) }} />
                          {ruleATag?.length > 32 && (
                            <div style={{ fontSize: 14, color: "red", marginTop: 6 }}>
                              Only 32 character Allowed
                            </div>
                          )}
                        </SelectedRuleContainer>
                      </DropdownContainer>
                      {/* <Divider /> */}
                    </>
                  ))}
              </DropdownContainer>

              <DropdownContainer>
                <DropdownHeader onClick={() => {

                  !isCustomABRuleEditing && settypeBIsOpen(!typeBisOpen)
                }}>
                  <div>
                    Type B |{" "}
                    {typeBRule[0]?.key ? (
                      typeBRule[0]?.label
                    ) : (
                      <SelectRuleTypeText
                        style={{ color: "rgba(146, 146, 146, 0.6)" }}
                      >
                        Select Rule Type
                      </SelectRuleTypeText>
                    )}
                  </div>

                  {!isCustomABRuleEditing && <span>Add</span>}
                </DropdownHeader>

                <DropdownList open={typeBisOpen}>
                  {rules.map((rule, index) => (
                    <ListItem
                      key={index}
                      selected={typeBRule.includes(rule.key)}
                      onClick={() => {
                        toggleTypeBRuleSelection(rule);
                        settypeBIsOpen(false);
                      }}
                    >
                      {rule.label}
                    </ListItem>
                  ))}
                </DropdownList>
                {typeBRule?.length > 0 &&
                  typeBRule?.map((rule) => (
                    <>
                      <DropdownContainer>
                        <SelectedRuleContainer disabled={isCustomABRuleEditing}>
                          {renderRuleFields(rule.key, "forTypeB", true)}
                          <FlagLabel >Flag</FlagLabel><br />
                          <FlagContainer placeholder='No space or special characters allowed except _' value={ruleBTag} onChange={(e) => { ruleTagRejax.test(e.target.value) && setRuleBTag(e.target.value) }} />
                          {ruleBTag?.length > 32 && (
                            <div style={{ fontSize: 14, color: "red", marginTop: 6 }}>
                              Only 32 character allowed
                            </div>
                          )}
                        </SelectedRuleContainer>
                      </DropdownContainer>
                      {/* <Divider /> */}
                    </>
                  ))}
              </DropdownContainer>
              {/* </RuleCard> */}
            </RuleContainer>
          </ContainerCustomRule>
        );
    }
  };
  return (
    <FlexColContainer
      center={ruleName !== "custom_rule"}
      show_hr={ruleName !== "custom_rule"}
    >
      {renderRuleFields()}
    </FlexColContainer>
  );
};

export default EnterValue;
