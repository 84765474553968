import React, { useEffect, useState } from "react";
const Carousel = ({ banners }) => {
  const [currentSlide, setCurrentSlide] = useState(0);  
  const [pointerToSetInterval, setPointerToSetInterval] = useState(null);
  const handlePrev = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? banners.length - 1 : prev - 1
    );
  };
  const handleNext = () => {
    setCurrentSlide((prev) =>
      prev === banners.length - 1 ? 0 : prev + 1
    );
  };
  const initiateIntervalForCarousel=()=>{
   return setInterval(() => {
      handleNext();
  }, 4000);
  }
  useEffect(() => {
    const intervalId = initiateIntervalForCarousel();
    setPointerToSetInterval(intervalId);
    return () => clearInterval(pointerToSetInterval);
}, []);
  return (
    <div className="-mb-2">
    <div className="relative w-full overflow-hidden rounded-2xl" style={{height:'100%',minHeight:'100px'}}>
      <div
        className="flex transition-transform duration-500 w-full"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {banners?.map((banner) => (
          <div
            key={banner.id}
            className="min-w-full flex items-center justify-between"
            style={{
              backgroundImage: `url(${banner.backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="w-full h-full flex flex-col sm:flex-row items-center justify-between px-16 py-2">
              <div className="flex gap-4 " >
              <span>
              <img
                src={banner.ctaImage}
                alt="CTA"
                className="mb-4 sm:mb-0 sm:mr-6"
                style={{maxHeight:'100px',maxWidth:'85px'}}
              />
              </span>
              <div className="flex flex-col gap-0 justify-center text-center sm:text-left">
                <h2 className="text-lg sm:text-lg mt-4 font-bold mb-2" style={{color:banner.headerColor=='black'?'black':'white'}}>
                  {banner.heading}
                </h2>
                <p className="mb-4 text-base -mt-2 " style={{color:banner.headerColor=='black'?'black':'white'}}>
                  {banner.subheaderText}
                </p>

              </div>
              </div>
              <div className="flex align-middle justify-center py-2 px-4 min-w-fit" style={{background:banner.backgroundCtaButtonColor,borderRadius:'50px'}}>
                <span
                  onClick={()=>{window.open(banner.redirectLink)}}
                  className="inline-block px-4 py-2 bg-blue-600 font-semibold text-base cursor-pointer"
                  style={{color: banner?.ctaButtonColor ? banner.ctaButtonColor :"black"}}
                >
                  {banner.ctaText}
                </span>
              </div>
            </div>

          </div>
        ))}
      </div>
      <button
        onClick={()=>{
          clearInterval(pointerToSetInterval)
          handlePrev()
          setPointerToSetInterval(initiateIntervalForCarousel())
        }}
        className="absolute top-1/2 left-3 -translate-y-1/2 hover:bg-white/90 text-black rounded-full p-2 text-3xl"
      >
        ‹
      </button>
      <button
        onClick={()=>{
          clearInterval(pointerToSetInterval)
          handleNext() 
          setPointerToSetInterval(initiateIntervalForCarousel())
        }}
        className="absolute top-1/2 right-3 -translate-y-1/2 hover:bg-white/90 text-black rounded-full p-2 text-3xl"
      >
        ›
      </button>
    </div>
    <div className="flex justify-center items-center">
        <div className="flex space-x-2 mt-2">
          {banners.map((_, index) => (
            <div
              key={index}
              onClick={() => {
                clearInterval(pointerToSetInterval)
                setCurrentSlide(index)
                setPointerToSetInterval(initiateIntervalForCarousel())
              }}
              className={`w-2 h-2 rounded-full cursor-pointer transition-all
            ${currentSlide === index ? "bg-carouselCurrentSlide" : "bg-greytext"}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default Carousel;