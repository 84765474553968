import styled from "styled-components";

export const BannerContainer = styled.div`
    background: var(--banner-bg-color);
    color: var(--banner-text-color);
    text-align:center;
    font-size:var(--banner-font-size);
    padding: 2px 4px;
    font-weight: var(--banner-font-weight);
`

