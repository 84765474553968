import React, {useContext} from "react";
import Label from "./components/Label";
import Amount from "./components/Amount";
import PaymentMethodIcon from "./components/PaymentMethodIcon";
import { paymentMethodsData } from "./data";
import { PaymentButton, Divider } from "./styled";
import { SpaceBetweenContainer } from "components/UIElements";
import { Card, Text } from "../styled";
import { UiConfigContext } from "context/CustomUIContext";


const Payment = ({isCardDesign}:{isCardDesign:boolean}) => {
  const {methods, otherPaymentMethods} = useContext(UiConfigContext);
  
  return (
    <Card>
      <Text>Payment Methods</Text>
      {methods?.map((paymentOption: any, index: number) => {
        const commonProp = {paymentMethodDetails: paymentOption}
        return (
          <PaymentButton key={index} isCardDesign ={isCardDesign} >
            <SpaceBetweenContainer style={{alignItems:'flex-start'}}>
                <PaymentMethodIcon {...commonProp} />
                <SpaceBetweenContainer style={{flex: 1}}>
                    <Label {...commonProp} isCardDesign = {isCardDesign} />
                    <Amount {...commonProp} isCardDesign = {isCardDesign} />                 
                </SpaceBetweenContainer>
            </SpaceBetweenContainer>
            {isCardDesign && <Divider />}
          </PaymentButton>
        );
      })}

    {otherPaymentMethods?.isActive && otherPaymentMethods?.methods.length > 0 &&
    otherPaymentMethods?.methods?.map((paymentOption: any, index: number) => {
        const commonProp = {paymentMethodDetails: paymentOption}
        return (
          <PaymentButton key={index} isCardDesign ={isCardDesign}>
            <SpaceBetweenContainer style={{alignItems:'flex-start'}}>
                <PaymentMethodIcon {...commonProp} />
                <SpaceBetweenContainer style={{flex: 1}}>
                    <Label {...commonProp} isCardDesign = {isCardDesign}/>
                    <Amount {...commonProp} isCardDesign = {isCardDesign}/>                 
                </SpaceBetweenContainer>
            </SpaceBetweenContainer>
            {isCardDesign && <Divider />}
          </PaymentButton>
        );
      })}

    </Card>
  );
};

export default Payment;