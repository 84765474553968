import React, { useContext, useEffect, useState } from "react";
import Checkbox from "components/CheckoutBuilder/UIComponents/Checkbox";
import Radio from "components/CheckoutBuilder/UIComponents/Radio";
import { UiConfigContext } from "context/CustomUIContext";
import {
  ModalContainer,
  ModalHeader,
  BackDrop,
  ModalBody,
  FomoContainer,
  TextArea,
} from "./styled";
import { Heading } from "../styled";
import { Section } from "components/CheckoutBuilder/Elements/styled";
import { Text } from "../styled";
import { CrossIcon } from "images/icons";

const ExitFormDrawer = ({setIntractiveCheckout, isPreview}:any) => {
  const { fomoExit, exitReasons } = useContext(UiConfigContext);
  const[ reasonsArray, setReasonsArray]: any = useState([]);
  
  useEffect(() => {
    if(exitReasons.selectType === "single"){
      const reasonsArrays = exitReasons.selectedReasons?.map((reason: string) => ({
          label: reason,
          value: reason,
        }));

      setReasonsArray(reasonsArrays)
      console.log(reasonsArrays, 'checking values')
      

    }
  }, [exitReasons.selectType, exitReasons.selectedReasons])


  const closeExitForm = () => {
    if(isPreview){
      setIntractiveCheckout((prev:any) => ({...prev, previewShowExitForm:false}))
    }
  }



  return (
    <BackDrop onClick={closeExitForm} className="cursor-pointer">
      <ModalContainer>
        <ModalHeader>
          <Heading style={{color: 'var(--drawer-text-color)'}}>Sorry to see you go..</Heading>
          <CrossIcon color = {"var(--drawer-text-color)"} />
          {/* <><MdClear style={{color:"var(--drawer-text-color)"}} /></> */}
        </ModalHeader>
        {fomoExit.isActive ? (
          <Section>
            <FomoContainer>
              <img
                src="https://fastrr-boost-ui.pickrr.com/assets/images/hourglassRed.gif"
                alt="timer"
              />
              {fomoExit.reason}
            </FomoContainer>
          </Section>
        ) : (
          ""
        )}
        <ModalBody>
          {exitReasons.enableExitForm && 
          <>
            <Section>
              <Text>What stopped you from completing your purchase?</Text>
              {exitReasons.selectType !== "single" && exitReasons.selectedReasons?.map((reason: any) => {
                  return (
                    <div style={{ marginBottom: 6 }}>
                      <Checkbox
                        label={reason}
                        onChange={(checked: any) => console.log(checked)}
                      />
                    </div>
                  );
                })}
              {exitReasons.selectType !== "single" && (
                <div style={{ marginBottom: 6 }}>
                  <Checkbox
                    label={"Others"}
                    onChange={(checked: any) => console.log(checked)}
                  />
                </div>
              )} 

            {exitReasons.selectType === "single" && 
              <Radio 
                options={reasonsArray}
                direction = "column"
                // selected={selectedLayout}
                onChange={(value) => {
                  console.log(value);
                }}
                smallFont = {isPreview}
              />
            }

              {exitReasons.selectType === "single" && (
                <div style={{ marginBottom: 6 }}>
                  <Radio 
                    options={[{label: 'other', value: 'others'}]}
                    // selected={selectedLayout}
                    onChange={(value) => {
                      console.log(value);
                    }}
                    
                  />
                </div>
              )} 



            </Section>
            <TextArea placeholder="others (please specify)" />
          </>
          }
        </ModalBody>
      </ModalContainer>
    </BackDrop>
  );
};

export default ExitFormDrawer;
