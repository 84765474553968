import React, { useState } from 'react';
import { AccordianHeader, AccordianHeading, AccordianContainer, AccordianContent } from './styled';
import { MdExpandMore, MdExpandLess } from "react-icons/md";

const Accordion = ({ heading, children, isOpen, setIsOpen }:any) => {
    // const [isOpen, setIsOpen] = useState(false);

    return (
        <AccordianContainer>
            <AccordianHeader onClick={() => setIsOpen(!isOpen)}>
                <AccordianHeading>{heading}</AccordianHeading>
                {!isOpen ? <MdExpandLess /> : <MdExpandMore />}
            </AccordianHeader>
            {!isOpen && <AccordianContent>{children}</AccordianContent>}
        </AccordianContainer>
    );
};

export default Accordion;





// import React from 'react';
// import { AccordianHeader, AccordianHeading, AccordianContainer, AccordianContent } from './styled';
// import { MdExpandMore, MdExpandLess } from "react-icons/md";



// const Accrodian = ({heading, children}:any) => {
//     return (
//         <AccordianContainer>
//             <AccordianHeader>
//                 <AccordianHeading>{heading}</AccordianHeading>
//                 <MdExpandLess />
//             </AccordianHeader>
//             <AccordianContent>
//                 {children}
//             </AccordianContent>
//         </AccordianContainer>
//     )
// }

// export default Accrodian;