import styled from "styled-components";

export const CustomInput = styled.input<{disabled?: true}>`
    border:1px solid #E0E4EC;
    border-radius: 8px;
    padding: 10px 16px;
    width: 100%;
    color: #333333;
    cursor: ${(props:any) => props.disabled ? 'not-allowed' : ""};
    &::placehoder{
        color: #8D98AA;
    }
`

export const InputErrorMessage = styled.p`
    font-size: 12px;
    margin-top: 4px;
    color:#ec0000;
`