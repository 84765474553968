import styled from "styled-components";

export const HeaderContainer = styled.div`
    background: var(--header-bg-color);
    height: 56px;
    display:flex;
    padding: 8px 12px;
    align-items:center;
    position:relative;
    .back_icon{
        position: absolute;
        left: 20px;
        color: var(--header-back-icon-color);
    }

    
`

export const  LogoContainer = styled.div`
    width: 100%;
    display:flex;
    justify-content:var(--logo-alignment);
    img {
        height: 30px;
        padding: 0 24px;
    }

`