import styled from 'styled-components';

export const ColorPickerContainer = styled.div<{disabled?: true}>`
    background: #FFFFFF;
    border: 1px solid var(--grey-lite);
    padding: 4px 8px 4px 14px;
    border-radius: 8px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    max-width: 100%;
    margin-bottom: 24px;
    opacity: ${(props:any) => props.disabled ? '.4' : 1};
    cursor: ${(props:any) => props.disabled ? 'not-allowed' : ""}
`

export const CustomInput = styled.input<{disabled?: true}>`
    outline: none;
    border: none;
    width: 100%;
    color: var(--grey-color);
    font-size:14px;
    cursor: ${(props:any) => props.disabled ? 'not-allowed' : ""};
    &:focus {
        outline: none;
        border:none !important;
    }
`;

export const Text = styled.p`
    color: var(--grey-color);
    font-size:14px;
    margin-bottom: 0;
`

export const ColorInput = styled.input<{disabled?: true}>`
    background: transparent;
    border:none;
    outline:none;
    height: 30px;
    width: 28px;
    border-radius: 24px;
    cursor: ${(props:any) => props.disabled ? 'not-allowed' : ""};
     &:focus {
        outline:none;
        border:none !important;
    }
`
