import { notification } from 'antd';
import axiosInstance from 'HTTP/Http';

export const FetchPaymentConfig = async ({queryKey}:any) => {
    const [unusedArgument, {sellerId}] = queryKey;
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/payment-config/${sellerId}?api_version=v3`
      );
      return response.data;
    } catch (error) {
      notification.error({message: error.message});
    }
  };