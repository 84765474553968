import { Form, Input, Select } from 'antd';
import { PickrrHeading } from 'components/UIElements'
import React, { useState } from 'react'
const { Option } = Select;

const InventorySource = ({inventorySource, setInventorySource, inventoryBuffer, setInventoryBuffer}) => {


  return (
    <div>
         <PickrrHeading size={5}>Select Inventory Source</PickrrHeading>
         <Select 
            placeholder="Select inventory source" 
            onChange={(value) => {
              setInventorySource(value)
            }} 
            defaultValue={inventorySource}
            allowClear={true}
        >
          <Option value="unicommerce">Unicommerce</Option>
        </Select>

    { inventorySource && <Form.Item label="Inventory Buffer" style={{margin: '12px', width: '50%'}}>
      <Input
        style={{ borderRadius: "8px", height: "32px" }}
        type='number'
        value={inventoryBuffer}
        required={inventorySource ? true : false}
        onChange={(e) => setInventoryBuffer(parseInt(Number(e.target.value)))}
        min={0}
        >
        </Input>
      </Form.Item>}
      
    </div>
  )
}

export default InventorySource