import styled from "styled-components";

export const AddressContainer = styled.div<{isCardDesign?: boolean, selected?: boolean}>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 8px;
    background: #FFF;
    box-shadow:${(props:any) => props.isCardDesign ? "4px 4px 11px 0px rgba(208, 219, 247, 0.15), -2px -2px 10px 0px rgba(208, 219, 247, 0.15)" : "none"};
    border: ${(props:any) => props.isCardDesign ? '0.75px solid var(rgba(49, 115, 227, 0.10))' : props.selected ? '1px solid var(--checkout-primary-color)' : 'none'};
    border-bottom: ${(props:any) => props.isCardDesign ? '0.75px solid var(rgba(49, 115, 227, 0.10))' : `1px solid ${props.selected ? 'var(--checkout-primary-color)' : '#d1d1d1'} `};
`
