import React, {Dispatch, SetStateAction} from 'react';
import {StepContainer, Step, StepTitle, Line} from './styled';
import { FlexContainer, SpaceBetweenContainer } from 'components/UIElements';
import { IoCheckmarkSharp } from "react-icons/io5";

interface StepperProps {
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<number>>;
}


const Stepper = ({currentStep, setCurrentStep}: StepperProps) => {
    const steps = [
        {stepNo: '01', title: 'Template'}, 
        {stepNo: '02', title: 'Colour scheme'}, 
        {stepNo: '03', title: 'Elements'}
    ]

    return (
        <div>
            <SpaceBetweenContainer>
                {steps.map((value, index:number) => {
                    return (
                        <div>
                            <StepContainer isBorder = {currentStep === (index + 1) ? true : false} >
                                <Step isCompelete = {(currentStep > (index + 1))}>
                                    {(currentStep > (index + 1) ) ? <IoCheckmarkSharp /> : value?.stepNo}                                    
                                </Step>
                                <StepTitle>{value?.title}</StepTitle>
                            </StepContainer>
                        </div>
                )})}
            </SpaceBetweenContainer>  
            <Line />
        </div>    
    )
}

export default Stepper;