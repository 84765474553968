import styled from "styled-components";

export const InfoContainer = styled.div`
    border-radius: 6px;
    background: #F1F5FA;
    display:flex;
    align-items:center;
    gap: 6px;
    padding: 4px 20px 4px 8px;
    color: var(--main-text-color);
    font-size:12px;
    width: fit-content;
    max-width: 100%;
    margin-bottom: 24px;
    display:flex;
    align-items:flex-start;

    span{
        display:inline-block;
        margin-top: 4px;
        width: 10px;
        height: 10px;
    }
`