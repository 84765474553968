import styled from "styled-components";

const Container = styled.div`
  font-family: Arial, sans-serif;
  background-color: white;
  padding: 20px;
  width:80% ;
`;

const Card = styled.div`
  background-color: white;
  margin-bottom: 20px;
  border-bottom:1px;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #2c2c2c;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExportButton = styled.button`
  background: none;
  border: none;
  color: #999;
  font-size: 14px;
  cursor: pointer;
`;

const RulesContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const RuleSection = styled.div`
  flex: 1;
`;

const Tabs = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
`;

const Tab = styled.div`
  color: ${(props) => (props.active ? "#5C6EF8" : "#aaa")};
  background: rgba(235, 241, 251, 1);
  width: 70px;
  cursor: pointer;
  padding: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19.49px;
  letter-spacing: 0%;
  text-align: center;
  margin-bottom:5px;
`;

const BadgeContainer = styled.div`
  display: flex;
`;

const Badge = styled.div`
  background: rgba(242, 242, 242, 1);
  color: rgba(169, 169, 169, 1);
  font-weight: 500;
  font-size: 12px;
  line-height: 14.96px;
  letter-spacing: 0%;
  text-align: center;

  padding: 6px 12px;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  border: 1px solid #ddd;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  padding: 12px;
  font-size: 14px;
  border-right: 1px solid #ddd;
`;

const SeeResults = styled.span`
  color: #5c6ef8;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
`;

export {
  SeeResults,
  TableCell,
  TableRow,
  Table,
  Badge,
  BadgeContainer,
  Tab,
  Tabs,
  RuleSection,
  Container,
  Card,
  Header,
  ExportButton,
  RulesContainer,
};
