import styled from "styled-components";

export const FlexColContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.center ? "center" : "flex-start"}; /* Default to flex-start */
  align-items: ${(props) =>
    props.center ? "center" : "stretch"}; /* Default to stretch */
  width: 100%;

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const NewFlexCol = styled.div`
    display: flex;
    flex-direction: column;

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
    }
`

export const Container = styled.div`
  width: 50%;
  margin: ${(props) => (props.custom_rule ? "10px 10px" : "40px 40px")};
`;


export const ContainerDisableShipping = styled.div`
  margin: ${(props) => (props.custom_rule ? "10px 10px" : "40px 40px")};
`;

export const ContainerDiscount = styled.div`
  /* width: 50%; */
  margin: 40px 20px;
`;

export const ContainerCustomRule = styled.div`
  margin: 10px 10px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out,
    background-color 1s ease-in-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Section = styled.div`
  margin: 20px 0;
  padding: 10px;
  background: #f6f6f6;
  border-radius: 6px;
`;

// Label
export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
`;

// Input Container
export const InputContainer = styled.div`
  display: flex;
  gap: 10px;
`;

// Input
export const Input = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

// Select
export const Select = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 120px;
`;

// Add Button
export const AddButton = styled.button`
  background: none;
  border: none;
  color: #2e5bff;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

// Checkbox
export const Checkbox = styled.input`
  width: 16px;
  height: 16px;
`;
export const Divider = styled.hr`
  margin: 20px 0;
  border: 0.5px solid #ddd;
`;
// Rule Type Container
export const RuleTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 0;
  margin-bottom:20px;
`;

export const DropdownHeader = styled.div`
  background: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
`;

export const SelectedRuleContainer = styled.div`
  border-style: solid;
  border-color: rgba(230, 230, 230, 1);
  border-top-color: white;
  border-top-width: 0.5px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none; /* Disable interactions */
    
    /* Backdrop Blur Effect */
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.4); /* Light overlay */
      // backdrop-filter: blur(5px); /* Blur effect */
      border-radius: inherit;
    }
  `}
`;

export const CoverageText = styled.span`
font-weight: 500;
font-size: 12px;
line-height: 16px;
letter-spacing: 0%;

`

export const CoverageTextContainer = styled.div`
 display: flex;
  align-items: center;
  background-color: #f3f5fc; /* Light blue background */
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  max-width: 800px;
`
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  margin-right: 10px;
`;

export const CustomRuleAddButton = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17.05px;
  letter-spacing: 0%;
  color: rgba(111, 87, 233, 1);
`;
export const SelectRuleTypeText = styled.span`
font-weight: 500;
font-size: 16px;
line-height: 19.95px;
letter-spacing: 0.2px;
`

export const DropdownList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50px;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: ${({ open }) => (open ? "block" : "none")};
  z-index: 1;
`;

export const ListItem = styled.li`
  padding: 12px;
  cursor: pointer;
  transition: background 0.2s;
  z-index: 1;
  &:hover {
    background: rgba(111, 87, 233, 0.3);
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const CoverageSection = styled.div`
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  margin-bottom: 20px;
`;

const Slider = styled.input`
  width: 200px;
  margin: 0 10px;
`;

const Percentage = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #666;
`;

// Rule Card
const RuleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  
  /* background:black ; */
`;

const RuleCard = styled.div`
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  /* background-color: #f8f8f8; */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const RuleHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const InputAB = styled.input`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;

  input {
    margin-right: 8px;
  }
`;

const FlagInput = styled.input`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 10px;
  font-size: 14px;
`;

const CoverageContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
`;

// Title and Info
const CoverageTitle = styled.div`
  display: flex;
  /* align-items: center; */
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 15.74px;
  text-align: left;
  text-decoration-skip-ink: none;
`;

const InfoIcon = styled.span`
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
  color: #888;
`;

// Coverage Slider Wrapper
const SliderWrapper = styled.div`
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
`;

// Labels for Type A and Type B
const LabelSlider = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: black;
  width: 80px;
  text-align: center;
`;

// Slider Container
const SliderContainer = styled.div`
  position: relative;
  width: 200px;
  display: flex;
  align-items: center;
`;

// Styled Range Input (Slider)
const StyledSlider = styled.input`
  width: 100%;
  appearance: none;
  background: linear-gradient(
    to right,
    #6842ef ${(props) => props.value}%,
    #e2e2e2 ${(props) => props.value}%
  );
  height: 6px;
  border-radius: 5px;
  outline: none;
  transition: background 0.2s;
  cursor: pointer;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    background: white;
    border: 2px solid #6842ef;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: white;
    border: 2px solid #6842ef;
    border-radius: 50%;
    cursor: pointer;
  }
`;

// Value Bubble
const ValueBubble = styled.div`
  position: absolute;
  top: -9px;
  left: ${(props) => props.value}%;
  transform: translateX(-50%);
  background-color: #6842ef;
  color: white;
  /* padding: 5px 8px; */
  border-radius: 6px;
  font-size: 12px;
  font-weight: bold;
  transition: left 0.2s;
  width: 30px;
  text-align: center;
`;

// Percentage Labels Under Slider
const PercentageLabel = styled.div`
  position: absolute;
  bottom: -5px;
  left: ${(props) => props.left}%;
  transform: translateX(-50%);
  font-size: 10px;
  color: ${(props) => (props.active ? "#6842ef" : "#aaa")};
  font-weight: bold;
`;
const LabelSliderPercentage = styled.span`
  width: 33px;
  height: 15px;
  top: 24px;
  left: 5px;
  padding: 2px 6px 2px 6px;
  gap: 10px;
  border-radius: 4px 0px 0px 0px;
  opacity: 0px;
  background: rgba(235, 241, 251, 1);
  color: rgba(116, 91, 231, 1);
  font-family: Proxima Nova;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.47px;
  text-align: center;
  text-decoration-skip-ink: none;
`;

const FlagContainer = styled.input`
width: 330px;
height: 40px;
padding:10px;
margin-left: 20px;
 border: 2px solid #eaeaea !important;
  border-radius: 8px;
  margin-bottom:10px;

&:focus{
border: 2px solid #eaeaea !important;
}
&:focus-visible{
border: 2px solid #eaeaea !important;
}
`;
const FlagLabel = styled.label`
width: 27px;
height: 16px;
margin-left: 20px;
font-weight: 500;
font-size: 14px;
line-height: 15.74px;
letter-spacing: 0%;
`;

export {
  CoverageContainer,
  LabelSlider,
  LabelSliderPercentage,
  CoverageTitle,
  InfoIcon,
  SliderWrapper,
  SliderContainer,
  StyledSlider,
  FlagLabel,
  ValueBubble,
  PercentageLabel,
  Options,
  CheckboxLabel,
  FlagInput,
  InputAB,
  RuleHeader,
  RuleCard,
  RuleContainer,
  Percentage,
  Slider,
  CoverageSection,
  FlagContainer
};
