import React, { useState, useEffect } from 'react'
import { Form, Checkbox, Switch, Radio, Input } from "antd";
import { SwitchText } from './styled';

const ApplicableModes = (props: any) => {

  const [switchValue, setSwitchValue] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [amount, setAmount] = useState('');


  useEffect(() => {
    if (props?.criteriaUpdate?.freebieText) {
      setSwitchValue(props?.criteriaUpdate.freebieText);

      if (props?.criteriaUpdate?.freebieText === "Free Gift") {
        setSelectedOption("freeGift");
      } else if (props?.criteriaUpdate?.freebieText && props?.criteriaUpdate?.freebieText?.includes("₹")) {
        setSelectedOption("freeGiftWithValue");
        setAmount(props?.criteriaUpdate?.freebieText.split('₹')[1]);
      }

    }
  }, [props?.criteriaUpdate?.freebieText]);

  useEffect(()=>{
    if(selectedOption && switchValue) {
      props.setData({
        ...props.data,
        discountConfig: {
          ...props.data.discountConfig,
          freebieText: selectedOption === "freeGift" ? "Free Gift" : `Free Gift worth ₹${amount}`
        },
      })
    }

    if(!switchValue) {
      props.setData({
        ...props.data,
        discountConfig: {
          ...props.data.discountConfig,
          freebieText: null
        },
      })
    }
  }, [switchValue, selectedOption, amount])

  const handleSwitchChange = (checked: boolean) => {
    setSwitchValue(checked);
  };


  const HandleUpdate = () => {
    props.setCriteriaUpdate({
      ...props.criteriaUpdate,
      paymentMethodsCriteria: props.form.getFieldValue('paymentMethodsCriteria')
    })
  }
  return (
    <>
      <Form.Item
        name={"paymentMethodsCriteria"}
        valuePropName="checked"
      >
        <Checkbox
          onChange={HandleUpdate}
          value="paymentMethodsCriteria"
        >
          Prepaid
        </Checkbox>
      </Form.Item>

      <Switch checked={switchValue} onChange={handleSwitchChange} disabled={!props.form.getFieldValue('paymentMethodsCriteria')} />
      <SwitchText style={{ marginLeft: "10px" }}>
        Display Free gift on payment methods
      </SwitchText>


      {switchValue ?
        <Form.Item style={{marginLeft: "50px", marginTop: "10px"}}>
          <Radio.Group onChange={(e) => setSelectedOption(e.target.value)} value={selectedOption}>
            <Radio value="freeGift">Free Gift</Radio>
            <Radio value="freeGiftWithValue">Free Gift worth ₹
              <Input type='number' value={amount} onChange={(e)=> setAmount(e.target.value)} disabled={selectedOption !== "freeGiftWithValue"} style={{width: "30%", marginLeft: "8px"}}></Input>
            </Radio>
          </Radio.Group>
        </Form.Item>
        : null

      }


    </>
  )
}

export default ApplicableModes