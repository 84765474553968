import React, { useState, useContext, useEffect } from "react";
import Radio from "components/CheckoutBuilder/UIComponents/Radio";
import Info from "components/CheckoutBuilder/UIComponents/Info";
import { DefaultText } from "components/CheckoutBuilder/styled";
import Checkbox from "components/CheckoutBuilder/UIComponents/Checkbox";
import Input from "components/CheckoutBuilder/UIComponents/Input";
import ColorPicker from "components/CheckoutBuilder/UIComponents/ColorPicker";
import PaymentMethods from "./PaymentMethods";
import Checkout from "components/Checkout";
import { Container, AccordianLeftSide, Section } from "../../styled";
import { UiConfigContext } from "context/CustomUIContext";
import { layoutOption, paymentPostTextSelectionColor} from "./data";
import Tooltip from "components/CheckoutBuilder/UIComponents/Tooltip";

const PaymentMethod = () => {
  const {
    methods, 
    setMethods, 
    otherPaymentMethods, 
    setOtherPaymentMethods, 
    selectedPaymentMethodColor, 
    setSelectedPaymentMethodColor, 
    templateConfig
  } = useContext(UiConfigContext);

  const [selectedLayout, setSelectedLayout] = useState("layout1");
  const [otherPaymentMethodInputValue, setOtherPaymentMethodInputValue] = useState<string>(otherPaymentMethods?.methods?.join(""));

  const [paymentModes, setPaymentModes] = useState([
    'Prepaid', 
    'Cash on delivery'
  ])

  useEffect(() => {
    if(otherPaymentMethods?.methods?.length){
      const inputValue = otherPaymentMethods?.methods?.map((method:any) => method?.title)?.join()
      setOtherPaymentMethodInputValue(inputValue);
    }
  }, [])



  const handleOtherPaymentOptions = (methods:any) => {
    console.log(methods, 'checking the methods')


    setOtherPaymentMethodInputValue(methods);
    let splitMethods:any  
    if(methods?.length > 0 ){
      splitMethods = methods
      ?.trim()
      ?.split(",")
      ?.map((method: string) => ({
        title: method.trim(),
        finalAmount: "₹8845.00",
        paymentMethodIcon:
          "https://fastrr-boost-ui.pickrr.com/assets/images/payment/other.svg",
      }));
    }else {
      splitMethods  = []
    }
    setOtherPaymentMethods((prev:any) => ({...prev, methods:splitMethods}));
  }

  useEffect(() => {
    const { bgColor, textColor, isApplyGradient } = selectedPaymentMethodColor;
    if(templateConfig?.selectedTemplate === "newLayout"){
      if(isApplyGradient){
        document.documentElement.style.setProperty("--payment-button-hover-bg-color", 
          `linear-gradient(to right, ${bgColor}, white)`
      );
      }else {
        document.documentElement.style.setProperty("--payment-button-hover-bg-color", bgColor);
      }
      document.documentElement.style.setProperty("--payment-button-hover-text-color", textColor);
    }else{
      document.documentElement.style.setProperty("--payment-button-hover-bg-color", "#000000");
      document.documentElement.style.setProperty("--payment-button-hover-bg-color", "#ffffff");
      document.documentElement.style.setProperty("--payment-button-hover-text-color", "#101828");
    }

  }, [
    selectedPaymentMethodColor.bgColor, 
    selectedPaymentMethodColor.textColor, 
    selectedPaymentMethodColor.isApplyGradient
  ]);



 const infoText = 'Add payment options which are supported by shopify checkout only like store credits, etc. Customer will be redirected to shopify checkout with prefilled address.'
  return (
    <Container>
      <AccordianLeftSide style={{width: "58%"}}>
        <Radio
          title="Payment Layout"
          options={layoutOption}
          selected={selectedLayout}
          onChange={(value) => {
            setSelectedLayout(value);
          }}
        />
        {selectedLayout === "layout2" && <PaymentMethods methods = {paymentModes} setMethods = {setPaymentModes} title = "Payment modes: Drag to re-arrange"  componentKey = "cod_layout"/>}
        <PaymentMethods methods = {methods} setMethods = {setMethods} title = "Prepaid payment methods: Drag to re-arrange" componentKey = "prepaid_payment_methods" />
        
        <DefaultText style={{margin: '20px 0 10px'}}>Shopify payment methods</DefaultText>
        <Section>
        <Checkbox 
          // label="Show Shopify payment methods"
          label="Enable Shopify payment methods"
          checked={otherPaymentMethods.isActive}
          onChange={(checked:boolean) => setOtherPaymentMethods((prev:any) => ({...prev, isActive:checked}))}
          infoText="Add comma seprated value for multiple methods"
        />
        {otherPaymentMethods.isActive 
          ? 
          
            <Input 
                title = "Shopify native checkout Payments"
                placeholder = "Use commas to separate multiple payment options"
                value = {otherPaymentMethodInputValue}
                // infoText = "This colour highlights the payment methods as you scroll, making it easier for users to identify their choice."
                onChange = {(methods:any) => {handleOtherPaymentOptions(methods)}}
            />
         
        : ''}
         </Section>
    <Info text = {infoText}/>
    {templateConfig?.selectedTemplate === "newLayout" &&
        <div style={{marginTop: 20}}>
        <ColorPicker 
          label = "Payment method highlight colour, it not selected yet"
          infoText = "This colour highlights the payment methods as you scroll, making it easier for users to identify their choice."
          color={selectedPaymentMethodColor.bgColor}
          onChange={(value:any) => setSelectedPaymentMethodColor((prev:any) => ({...prev, bgColor: value}))}
        />
        <Section>
          <Checkbox 
            label="Apply gradient"
            checked={selectedPaymentMethodColor.isApplyGradient}
            onChange={(checked:boolean) => setSelectedPaymentMethodColor((prev:any) => ({...prev, isApplyGradient: checked}))}
            infoText = "Selecting the gradient option will apply a predefined gradient"
          />
        </Section>
              <Radio
                title="Payment card post selection button text color"
                options={paymentPostTextSelectionColor}
                selected={selectedPaymentMethodColor.textColor?.trim()}
                onChange={(value) => {
                  setSelectedPaymentMethodColor((prev:any) => ({...prev, textColor: value}))
                }}
                infoText = "Choose a  text colour to display when a payment method is highlighted, making it stand out clearly for users"
              />
        </div>
    }
      </AccordianLeftSide>
      <Checkout 
        showPayment={true}
      />
    </Container>
  );
};

export default PaymentMethod;
