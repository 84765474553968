import styled from "styled-components";

interface CustomCheckboxProps {
  checked: boolean;
}

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #333;

  input {
    display: none;
  }
`;

export const CustomCheckbox = styled.div<CustomCheckboxProps>`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ checked }) => (checked ? "var(--primary-color)" : "#fff")};
  border: 2px solid ${({ checked }) => (checked ? "var(--primary-color)" : "#ccc")};
  transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.2s ease;
  transform: ${({ checked }) => (checked ? "scale(1.1)" : "scale(1)")};

  svg {
    display: ${({ checked }) => (checked ? "block" : "none")};
    width: 16px;
    height: 16px;
    fill: #fff;
    opacity: ${({ checked }) => (checked ? 1 : 0)};
    transition: opacity 0.3s ease;
  }

  &:hover {
    border-color: #6c63ff;
  }
`;
