import styled from "styled-components";

export const HeadingContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction column;
`

export const TemplateWrapper = styled.div<{selected?:boolean}>`
    border: 1px solid ${(props:any) => props.selected ? 'var(--primary-color)': '#DFDFDF'};
    border-radius: 8px;
    padding: 24px 12px;
    max-width: 324px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    gap: 24px;
`

export const TemplateContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    gap: 24px;
    margin-top: 24px;
`

export const LayoutInfo = styled.div`
    background: #F1F5FA;
    border-radius:4px;
    color: #191919;
    font-size: 10px;
    text-align:center;
    padding: 2px 4px;

`