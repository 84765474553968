import { Checkbox, Form, Input } from 'antd'
import React from 'react'
import { NewFlexCol } from './styled';


const startCase = (input) =>
    input
      .split(/[_ ]+/)                       
      .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase()) 
      .join(' ');



const HyperlocalConfig = ({shippingMethods, setShippingMethods}) => {

    const handleCheckboxChange = (method) => {
        setShippingMethods((prev) => ({
            ...prev,
            [method]: {
                ...prev[method],
                checked: !prev[method].checked,
            },
        }));
    };

    const handleChargeChange = (method, value) => {
        setShippingMethods((prev) => ({
            ...prev,
            [method]: {
                ...prev[method],
                charge: parseFloat(value),
            },
        }));
    };

    return (
        <NewFlexCol>
            {Object.entries(shippingMethods).map(([key, { checked, charge }]) => {
                return(
                <div style={{margin: "12px", fontWeight: '600'}}>
                    <Checkbox checked={checked} onChange={(e) => handleCheckboxChange(key)} disabled={key==="hyperlocal"? true : null}>
                        {startCase(key)}
                    </Checkbox>

                    {checked &&
                        <Form.Item label={'Charges'} style={{marginLeft: '8px'}}>
                            <Input
                                style={{ borderRadius: "8px", height: "32px" }}
                                type='number'
                                value={charge}
                                required={checked}
                                onChange={(e) => handleChargeChange(key, e.target.value)}></Input>
                        </Form.Item>

                    }
                </div>
                )
})}

        </NewFlexCol>
    )
}

export default HyperlocalConfig