import React from 'react';
import { Card, Heading, TextSmall} from '../styled';
import { SpaceBetweenContainer, FlexBox } from 'components/UIElements';
import { MdArrowBackIosNew } from "react-icons/md";


                     
const OrderSummary = ({isCardDesign, showExistDrawer, isPreview}:{isCardDesign:boolean, showExistDrawer:any, isPreview:boolean}) => {
    const layoutStyle =  {
        alignItems: 'center', 
        gap: 2
    }
    return (
        <Card isCardDesign = {isCardDesign}>
            <SpaceBetweenContainer>
                <FlexBox style={layoutStyle}>
                    <Heading>Order Summary</Heading><TextSmall className='mb-1'>(1 item)</TextSmall>
                </FlexBox>
                <FlexBox style={layoutStyle}>
                    <TextSmall strike = {true} className='mb-1'>₹1605</TextSmall>
                    <TextSmall className='mb-1'>₹1500</TextSmall>
                    <div style={{transform: 'rotateZ(270deg)', fontSize: 12, marginLeft: 2}}>
                        <MdArrowBackIosNew/>
                    </div>
                </FlexBox>
            </SpaceBetweenContainer>
        </Card>
    )
}

export default OrderSummary;