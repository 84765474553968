import React, { useState, useContext} from 'react';
import Radio from '../../../../UIComponents/Radio';
import { TextSecondarySmall } from 'components/Checkout/styled';
import Switch from '../../../../UIComponents/Switch';
import { fomoOptions } from '../data';
import { UiConfigContext } from 'context/CustomUIContext';

const Fomo = () => {
    const {fomoExit, setFomoExit} = useContext(UiConfigContext);
    
    return  (
        <div>
            <Switch 
                label="Urgency triggers"
                checked={fomoExit.isActive}
                onChange={(toggled) => {setFomoExit((prev:any) => ({...prev, isActive: toggled}))}}
            />
            {fomoExit.isActive ? 
            <React.Fragment>
                <TextSecondarySmall 
                    style={{marginTop: 16, textAlign:'left'}}
                >
                    Choose urgency text
                </TextSecondarySmall>
                    <Radio 
                        options ={fomoOptions}
                        selected = {fomoExit.reason}
                        onChange={(reason:any) => setFomoExit((prev:any) => ({...prev, reason: reason}))}
                        direction ={'column'}
                    />
            </React.Fragment>
        : ''}
        </div>
    )
}

export default Fomo;