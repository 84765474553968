import styled from "styled-components";

export const Heading = styled.h1`
    font-size: 24px;
    font-weight: 600;
    line-height: 29.23px;
    color: var(--main-heading-color);
`

export const DefaultText = styled.p`
    font-size:14px;
    line-height: 16px;
    color:var(--main-text-color);
    font-weight: 500;
    margin-bottom: 0;
`

export const SubHeading = styled.h2`
    font-size:16px;
    font-weight: 500;
    line-height: 20px;
    color: var(--grey-color);
    margin-bottom: 20px;
`

export const TextVarible = styled.p<{size?:string}>`
    color: #9C9AA5;
    text-align: center;
    font-size: ${(props:any) => props.size ? props.size : '12px'}; 
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
`




export const SectionContainer = styled.div`
    padding-bottom: 16px;
`

export const InputLabel = styled.p<{gap?:string}>`
    font-size:14px;
    color: var(--black-v2);
    display:flex;
    align-items:center;
    gap: ${(props:any) => props.gap ? props.gap :'4px'};
    margin-bottom: 8px;
`




export const Text = styled.p<{weight?: number, strike?:boolean}>`
    font-family: 'Metropolis';    
    color: ${(props:any) => props.strike ? '#959494': '#2F2F2F'};;
    font-size: 14px;
    font-style: normal;
    font-weight: ${(props:any) => props.weight ?? 500};
    line-height: 18px;
    margin-bottom: 6px;
    text-decoration: ${(props:any) => props.strike ? 'line-through' : 'none'}
`

export const Container = styled.div`
    border-radius: 8px;
    padding: 24px 24px 24px;
    box-shadow: 0px 10px 20px 0px #C2C2C229;
    background: var(--white-color);
    margin-bottom: 24px;
`

export const TextHeadingWithLine = styled.p`
    position:relative;
    color: #808695;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    padding: 0 10px;
    margin:16px 0;
    &::after{
        content:"";
        position:absolute;
        top: 50%;
        transform:traslateY(-50%);
        left:100%;
        max-width: 40%;
        height:1px;
        width:100%;
        background: #808695;
        margin 0 4px;
    }
    &::before{
        content:"";
        position:absolute;
        top: 50%;
        transform:traslateY(-50%);
        right:100%;
        max-width: 40%;
        height:1px;
        width:100%;
        background: #808695;
        margin 0 4px;
    }

`