export const bannerTypeOptions = [
    {label: 'Single', value: 'singleBanner'}, 
    {label: 'Carousel', value: 'carouselBanner'}
]

export const textBehaviorOptions = [
    {label: 'Static', value: 'static'}, 
    {label: 'Blink', value: 'blink'}
]

export const fontStyleOptions = [
    {label: 'Regular', value: '400'}, 
    {label: 'Medium (Recommended)', value: '500'},
    {label: 'Bold', value: '600'},
]

export const fontSizeOptions = [
    {label: '10px', value: '10px'}, 
    {label: '12px (Recommended)', value: '12px'},
    {label: '14px', value: '14px'},
]

export const headerIconOptions  = [
    {label: 'Black', value:"#333333"}, 
    {label: 'White', value: "#ffffff"}
]


export const drawerTextColor  = [
    {label: 'Black', value:"#222222"}, 
    {label: 'White', value: "#f1f1f1"}
]