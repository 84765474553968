import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import Stepper from 'components/CheckoutBuilder/Stepper';
import Button from 'components/CheckoutBuilder/UIComponents/Button';
import TemplateSelection from 'components/CheckoutBuilder/TemplateSelection';
import ColorScheme from 'components/CheckoutBuilder/ColorScheme';
import Element from 'components/CheckoutBuilder/Elements';
import { CustomUIContext, UiConfigContext } from 'context/CustomUIContext';
import CustomReactTable from 'UiComponents/Table';
import { Container } from './styled';
import { TextStyle } from 'pages/Orders/OrdersTab/styles';
import moment from 'moment';
import Home from 'components/CheckoutBuilder/Home';
import Page from 'components/CheckoutBuilder/Page';
// import { ExportIcon, FilterIcon, ToastErrorCross, ToastSuccessTick } from 'images/icons';
const CheckoutBuilder = () => {
    // const [currentStep, setCurrentStep] = useState<number>(1);
    // // const {selectedTemplate} = useContext(UiConfigContext)


    // const stepHandle = () => {
    //     // console.log(selectedTemplate, 'adsfjhahbdsfdasbfh')
    //     // if(currentStep === 1 && )


    //     if(currentStep < 3){   
    //         setCurrentStep((prev:number) => prev + 1)
    //     }

    // };

    // // toast.error(error.message, {
    // //     icon: ToastErrorCross
    // //   })


    return (
        <CustomUIContext>

            <Page />
        {/* <Container> */}
            {/* <Home /> */}
            {/* <Stepper  */}
                {/* // currentStep = {currentStep} 
            //     setCurrentStep = {setCurrentStep}
            // /> */}
            {/* <Element /> */}

            {/* <Element /> */}
            {/* <ColorScheme />
            <Template /> */}
            {/* <Button 
                title = {'Next'} 
                onClick = {stepHandle}
            /> */}
        {/* </Container> */}
            {/* <TemplateSelection 
                stepHandle = {stepHandle}
                currentStep = {currentStep} 
                setCurrentStep = {setCurrentStep}
            /> */}
        </CustomUIContext>
    )
}
export default CheckoutBuilder;


// loading={isLoading}
//                 data={refundData?.refunds || []}
//                 pageNumber={pageNumber}
//                 columns={columns}
//                 setPageNumber={setPageNumber}
//                 totalPages={refundData?.total_refunds}
//                 checkboxes={true}
//                 onSelectAll={onSelectAll}
//                 pageSize={20}
//                 selectedRowsKeys={selectedRowKeys}
//                 defaultSortBy={defaultSortBy}
//                 newState={viewMoreState}
//                 handleRowSelect={onSelect}
//                 isChecked={isChecked}
//                 setHoveredRowIndex={()=>{}}