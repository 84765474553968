import React, { useEffect, useState } from "react";
import { BannerContainer } from "./styled";

const CheckoutBanner = ({ 
    bannerText = "Checkout Banner", 
    behavior = "static", 
    bannerType = "" }: any) => {
  const [index, setIndex] = useState(0);
  const [bannerTextModify, setBannerTextModify] = useState(bannerText);

  useEffect(() => {
    if(bannerType === "carouselBanner"){
      setBannerTextModify(bannerText?.split(","))
    }else {
      setBannerTextModify(bannerText)
    }
  }, [bannerText, bannerType])

  

  useEffect(() => {
    if(bannerType === "carouselBanner"){
      const lastIndex = bannerTextModify?.length - 1;
      if (index < 0) setIndex(lastIndex);
      if (index > lastIndex)  setIndex(0)
    }
  }, [index, bannerType])


    useEffect(() => {
      if(bannerType === "carouselBanner"){
        let slider = setInterval(() => {
            setIndex(index + 1);
        }, 1000);
        return () => clearInterval(slider);
    }
  }, [index, bannerType]);

  return (
    <BannerContainer
      className={`${behavior === "blink" ? "textAnimation" : ""}`}
    >
      {bannerType === "carouselBanner" ? bannerTextModify[index] : bannerTextModify}
    </BannerContainer>
  );
};

export default CheckoutBanner;
