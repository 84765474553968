import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    position:relative;
    border: .33px dashed #745BE7;
    border-radius:2px;
    height:120px;
    width: 120px;
    background: #C9C9C91A;
    overflow: hidden;
    margin: 10px 0 16px;
`

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    color:#6F60DF;
    cursor: pointer;
    p{
        font-size:14px;
        margin: 4px 0;
        font-weight: 500;
    }
    span{
        font-size:10px;
    }
`

export const FileInput = styled.input`
    inset: 0px;
    height: 100%;
    width: 100%;
    z-index: 1111;
    opacity: 0;
    position: absolute;
    cursor: pointer;
`

export const ErrorMessage = styled.p`
    color: red;
    font-size: 0.875rem;
    margin-top: 10px;
`;

export const FilePreview = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content:center;
    gap: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
    max-width: 85%;

    img {
        width: 100px;
        max-height: 50px;
        object-fit: contain;
    }
`;

export const DeleteButton = styled.button`
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;