import styled from "styled-components";

export const AppContainer = styled.div`
    width: 100%;
    max-width: 320px;
    // border: 1px solid red;
    // transform: scale(.2);
    // min-height: 100%;
    display:flex;
    flex-direction: column;
    overflow:hidden;
    position: relative;
    height: 600px;

`
export const AppInnerContainer = styled.div<{isCardDesign?:boolean}>`
    background: ${(props:any) => props.isCardDesign ? 'var(--checkout-bg-color)' : 'var(--white-color)'} ;
    padding: 4px 8px;
    height: 100%;
    overflow:scroll;
`

export const Card = styled.div<{isCardDesign?:boolean}>`
    background: var(--white-color);
    padding: ${(props:any) => props.isCardDesign ? '16px 12px' : '12px 12px'};
    flex-direction: column;
    border-radius: 14px;
    margin: 8px 0;
    box-shadow:  ${(props:any) => props.isCardDesign ? '4px 4px 11px 0px rgba(208, 219, 247, 0.15), -2px -2px 10px 0px rgba(208, 219, 247, 0.15)' : ''};
    border: ${(props:any) => props.isCardDesign ? '0.4px solid #b9b9b93d' : ''};
    overflow:hidden;
`

export const Heading = styled.h1`
    font-family: Metropolis;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
`

export const TextSmall = styled.p<{strike?:boolean}>`
    font-family: Metropolis;
    font-size: 12px;
    font-weight: ${(props:any) => props.strike ? 400: 500};
    line-height: 12px;
    text-align: left;
    color: ${(props:any) => props.striket ? '#959494': '#2F2F2F'};
    text-decoration: ${(props:any) => props.strike ? 'line-through' : 'none'}
`

export const TextSecondarySmall = styled.p`
    font-family: Metropolis;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: #928f8f;
    text-align:center;
`

export const Text = styled.p<{weight?: number, strike?:boolean}>`
    font-family: 'Metropolis';    
    color: ${(props:any) => props.strike ? '#959494': '#2F2F2F'};;
    font-size: 14px;
    font-style: normal;
    font-weight: ${(props:any) => props.weight ?? 500};
    line-height: 18px;
    margin-bottom: 6px;
    text-transform: capitalize;
    text-decoration: ${(props:any) => props.strike ? 'line-through' : 'none'}
`

export const Button = styled.button<{mutaed?:boolean}>`
    border:none;
    outline:none;
    background: none;
    font-family: 'Metropolis';
    font-size: 13px;
    font-weight: 500 !important;
    line-height: 16px;
    color: ${(props:any) => props.mutaed ? '#ACACACAC' : 'var(--checkout-primary-color)'};
`

export const InputBox = styled.input<{paddingLeft?:string, focus?:boolean}>`
    width: 100%;
    border: 1px solid ${(props:any) => props.focus ? 'var(--checkout-primary-color)' : 'var(--checkout-inactive-color)'};
    height: 40px;
    width: 100%;
    border-radius:6px;
    padding: 0 12px 0 36px;
    padding-left: ${(props:any) => props.paddingLeft ?? '34px'};
    outline: none;
        &:focus{
            border-color: var(--checkout-primary-color) !important
        }

`

export const Badge = styled.span`
    margin-left: 6px;
    border-radius: 50px;
    padding: 2px 8px 1px;
    font-size: 10px;
    background: var(--checkout-inactive-color);
    color: #222222;
    text-transform: capitalize;
    border: 1px solid #3173E333;
    line-height: 10px;
`