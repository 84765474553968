import React, { useEffect, useState } from "react";
import {
  SeeResults,
  TableCell,
  TableRow,
  Table,
  Badge,
  BadgeContainer,
  Tab,
  Tabs,
  RuleSection,
  Container,
  Card,
  Header,
  ExportButton,
  RulesContainer,
} from "./styled";
// Styled Components
import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchABTestingRuleById, exportABTestingRuleById } from "../script";
// Main Component
const AbTesting = (props) => {
  const { ruleList } = props;
  const [abTestingData, setIsABTestingData] = useState([]);
  const [selectedRuleId, setSelectedRuleId] = useState("");
  useEffect(() => {
    let _temp = ruleList.filter((data) => data.rule_type === "ab_testing");
    setIsABTestingData(_temp);
  }, [ruleList]);
  const getName = (ruleName) => {
    if (ruleName === "prepaid_discount") return "Prepaid Discount";
    if (ruleName === "cod_charge") return "COD Charge";
    if (ruleName === "shipping_charge") return "Shipping Charge";
    if (ruleName === "cod_disable") return "Disable COD";
    if (ruleName === "shipping_disable") return "Shipping Visibility";
    if (ruleName === "partial_cod") return "Partial COD";
    if (ruleName === "custom_rule") return "Custom Rule";
  };
  const {
    isLoading,
    data: selectedRuleData,
    refetch,
  } = useQuery(
    "SelectedABRuleData",
    () => fetchABTestingRuleById(selectedRuleId),
    {
      enabled: false, // Prevent automatic fetching
    }
  );
  const {
    data: exportData,
    refetch: refetch_export,
  } = useQuery(
    "ExportAbRule",
    () => exportABTestingRuleById(selectedRuleId),
    {
      enabled: false, // Prevent automatic fetching
    }
  );
  const exportReport = () => {
    refetch_export();
  }
  const seeResult = (rule) => {
    setSelectedRuleId(rule._id);
    setTimeout(() => {
      refetch();
    }, 300);
  };
  const tableLabels = {
    "codOrdersCount": { "value": "No. of COD orders", "type": "count" },
    "codOrdersRevenue": { "value": "COD orders revenue", "type": "currency" },
    "orderPlacedCount": { "value": "Total orders placed", "type": "count" },
    "orderPlacedRevenue": { "value": "Total orders revenue", "type": "currency" },
    "orderScreenConversionRate": { "value": "Conversion from Order screen (%)", "type": "percent" },
    "orderScreenCount": { "value": "No. of carts on order screen stage", "type": "count" },
    "overallConversionRate": { "value": "Overall conversion (%)", "type": "percent" },
    "prepaidOrderPercentage": { "value": "Prepaid orders (%)", "type": "percent" },
    "prepaidOrdersCount": { "value": "No. of Prepaid orders", "type": "count" },
    "prepaidOrdersRevenue": { "value": "Prepaid orders revenue", "type": "currency" },
    "partialCodCount":{"value":"No. of partially paid orders","type":"count"},
    "checkoutInitiated":{"value":"Checkout Initiated","type":"count"}
  }

  return (
    <Container>
      {abTestingData.map((data, index) => {
        return (
          <Card key={index}>
            <Header>
              {data['ab_testing'].name}
              {selectedRuleId !== data._id && (
                <SeeResults onClick={() => seeResult(data)}>
                  See results
                </SeeResults>
              )}
              {selectedRuleId === data._id && (
                <ExportButton onClick={() => { exportReport() }}>📤 Export</ExportButton>
              )}
            </Header>
            {/* Tabs for both sections */}
            <RulesContainer>
              {/* Type A Section */}
              <RuleSection>
                <Tab active>Type A</Tab>
                <BadgeContainer>
                  {data.ab_testing?.variantIdExperimentList["A"].map((badge) => {
                    return <Badge>{getName(badge.rule_type)}</Badge>;
                  })}
                  {data.custom_rule?.variantIdExperimentList["A"].map((badge) => {
                    return <Badge>{getName(badge.rule_type)}</Badge>;
                  })}
                </BadgeContainer>

                {selectedRuleId == data._id && (
                  <Table>
                    <tbody>
                      {selectedRuleData?.data["A"] && Object.keys(selectedRuleData?.data["A"]).map(__key => {
                        return (<TableRow key={__key}>
                          <TableCell>{tableLabels[__key]?.value}</TableCell>
                          <TableCell>{selectedRuleData?.data["A"][__key]}{tableLabels[__key]?.type == 'percent'?"%":''}</TableCell>
                        </TableRow>)
                      })}
                    </tbody>
                  </Table>
                )}
              </RuleSection>

              {/* Type B Section */}
              <RuleSection>
                <Tab active>Type B</Tab>
                <BadgeContainer>
                  <BadgeContainer>
                    {data?.ab_testing?.variantIdExperimentList["B"]?.map(
                      (badge) => {
                        return <Badge>{getName(badge.rule_type)}</Badge>;
                      }
                    )}
                    {data?.custom_rule?.variantIdExperimentList["B"]?.map(
                      (badge) => {
                        return <Badge>{getName(badge.rule_type)}</Badge>;
                      }
                    )}
                  </BadgeContainer>
                </BadgeContainer>

                {selectedRuleId === data._id && (
                  <Table>
                    <tbody>
                      {selectedRuleData?.data["B"] && Object.keys(selectedRuleData?.data["B"]).map(__key => {
                        return (<TableRow key={__key}>
                          <TableCell>{tableLabels[__key]?.value}</TableCell>
                          <TableCell>{selectedRuleData?.data["B"][__key]}{tableLabels[__key]?.type == 'percent'?"%":''}</TableCell>
                        </TableRow>)
                      })}
                    </tbody>
                  </Table>
                )}
              </RuleSection>
            </RulesContainer>
          </Card>
        );
      })}
    </Container>
  );
};

export default AbTesting;
