import axios from 'axios';
import { GetAuthToken } from "globalQuries";

const auth = GetAuthToken();
const API_URL = `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/upload/img/`;

export const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(API_URL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: auth,
            },
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error.response?.data?.message || 'File upload failed. Please try again.');
    }
};
