import styled from "styled-components";

export const AccordianLeftSide = styled.div`
    min-width: 60%;
`

export const Container = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
`

export const Section = styled.div`
    margin-bottom: 24px;
`