import axios from 'axios';
import { GetAuthToken } from 'globalQuries';
import { notification } from 'antd';

const auth = GetAuthToken();

const userToken = localStorage.getItem("userManagementToken");
const authenticationInfo = localStorage.getItem('sessionToken');
const adminLogin = localStorage.getItem("loggedInFromAdmin") ? true : false
  
export const getAllTemplates = async ({queryKey}: any) => {
  try {
    const[unusedArgument, {searchTerm}] = queryKey 

    const response = await axios.get(`${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/custom-ui`, {
      headers: {
        'X-AUTH': auth,
        'user-token': userToken, 
        'Authentication-info': authenticationInfo, 
        'adminLoggedIn': adminLogin
      },
      params: {search: searchTerm}
    });

    return response?.data
  } catch (error) {
    notification.error({message: "Something went wrong"});
    console.error('Error fetching data:', error);
  }
};





export const updateUiConfig = async (postData:any) => {
  console.log(postData, 'checking post data')
  try {
    const response = await axios.post(`${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/custom-ui`, 
      postData,
      {
        headers: {
          'X-AUTH': auth,
          'user-token': userToken, 
          'Authentication-info': authenticationInfo, 
          'adminLoggedIn': adminLogin
        },
      }
    );

    if(response?.data){
      return response.data;
    }
  } catch (error) {
    console.log(error, 'checking error')
    notification.error({message: "Something went wrong"});
    return error;
  }
};



// export const updateUiConfig = async (postData:any) => {
    
//     // const response = await axiosInstance.post(
//     //   `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/custom-ui`,
//     //   postData
//     // )
    
//     return 'response.data.data';
// }


