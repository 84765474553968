import React, { useContext } from "react";
import {Heading, TextVarible } from '../../styled';
import Button from "../Button";
import Input from "../Input";
import { UiConfigContext } from "context/CustomUIContext";


const ConfirmModal = ({setShowConfirmModal, callback, errors, loading, isSaveTemplate }: any) => {
    const {templateConfig, setTemplateConfig} = useContext(UiConfigContext);
    return <>
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
            <div className="fixed inset-0 bg-black opacity-50" 
                // onClick={() => { setShowConfirmModal(false) }} 
            />
            <div className="flex flex-col justify-center items-center relative z-50 bg-refundWhiteBg rounded-lg p-8 w-1/3">
               <Heading className="mt-4">{isSaveTemplate ? 'Save your progress' : 'Publish Template'}</Heading>
               <TextVarible className="mt-2">Your changes will override the current template. 
               Do you want to continue?</TextVarible>
               <div className="w-2/3">
                <Input 
                    placeholder = "Enter your template name"
                    value={templateConfig?.templateName}
                    onChange={(value:any) => setTemplateConfig((prev:any) => ({...prev, templateName: value}))}
                    error = {errors?.templateName}
                />
               </div>
               <div className="flex gap-4 items-center justify-center mt-6">
                <Button title = {isSaveTemplate ? "Save" : "Publish"} size = {"sm"} onClick = {() => callback(isSaveTemplate ? false : true)} disabled = {loading}/>
                <Button type = "secondary" title = "Cancel" onClick={() => { setShowConfirmModal(false) }} />
               </div>
               
            </div>
            
        </div>
    </>
}
export default ConfirmModal