import React from "react";
import { ToggleWrapper, CustomSwitch, Slider } from "./styled";
import { Text } from "components/CheckoutBuilder/styled";

interface ToggleSwitchProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const Switch = ({ label, checked, onChange }: ToggleSwitchProps) => {
  return (
    <ToggleWrapper>
      <Text style={{marginBottom: 0}}>{label}</Text>
      <CustomSwitch>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <Slider checked={checked} />
      </CustomSwitch>
    </ToggleWrapper>
  );
};

export default Switch;
