import React from "react";
import { PrimaryButton, LinkButton, GhostButton, SecondaryButton } from "./styled";

const Button = ({ title, onClick, type, size, disabled }: any) => {
  switch(type) {
    case "link": 
      return <LinkButton onClick={onClick} disabled = {disabled}>{title}</LinkButton>
    case "ghost": 
      return <GhostButton onClick={onClick} disabled = {disabled} size = {size}>{title}</GhostButton>
    case "secondary": 
      return <SecondaryButton onClick={onClick} disabled = {disabled}>{title}</SecondaryButton>
    default: 
      return <PrimaryButton onClick={onClick} size = {size} disabled = {disabled}>{title}</PrimaryButton>
  }
  // secondary

  // return (
  //   <>
    
  //     {type === "link" ? (
  //       <LinkButton onClick={onClick}>{title}</LinkButton>
  //     ) : (
  //       <PrimaryButton onClick={onClick}>{title}</PrimaryButton>
  //     )}
  //   </>
  // );
};

export default Button;
