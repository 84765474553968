import React, { useEffect } from 'react';
import { ColorBoxHeading, ColorBox, ColorBoxContainer} from './styled';
import { FlexBox } from 'components/UIElements';

const ThemeColors = ({themeColor, setThemeColor}:any) => {
    function addOpacityToHex(hex: string, opacity: number): string {
        const alpha = Math.round((opacity / 100) * 255).toString(16).padStart(2, '0');
        return `${hex}${alpha}`;
    }
      
    const colorList:any = [
        {
            title: 'Theme colour', 
            bgColor: themeColor.primaryColor
        }, 
        {
            title: 'Interactive colour', 
            bgColor: addOpacityToHex(themeColor.primaryColor, 20) // 20% of theme color 
        }, 
        {
            title: 'Background colour', 
            bgColor: themeColor.bgColor
        }, 
        {
            title: 'Accessible text', 
            bgColor: '#121212'
        }
    ]


    

    useEffect(() => {
        document.documentElement.style.setProperty('--checkout-primary-color', themeColor.primaryColor)
        document.documentElement.style.setProperty('--checkout-inactive-color', addOpacityToHex(themeColor.primaryColor, 20))
        document.documentElement.style.setProperty('--checkout-bg-color', themeColor.bgColor)
        
        setThemeColor((prev:any) => ({...prev, inactiveColor:colorList[1]?.bgColor}))

     }, [themeColor.primaryColor, themeColor.bgColor])


    return (
        <FlexBox style={{gap: 16, marginBottom: 24}}>
            {colorList?.map((themeShades:any) => {
                return (
                    <ColorBoxContainer>
                        <ColorBoxHeading>{themeShades.title}</ColorBoxHeading>
                        <ColorBox bgColor = {themeShades.bgColor} />
                    </ColorBoxContainer>
                )
            })}

        </FlexBox>
    )
}

export default ThemeColors;