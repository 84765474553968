import React, { useEffect } from "react";
import { CheckboxDescriptipon, CheckboxText, FormContainer, SubHeading } from "../styled";
import { CustomForm, PickrrButton } from "components/UIElements";
import { Checkbox, Col, Form, Input, notification, Row } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getGeneralDetails, updateSellerCheckoutConfig } from "components/Settings/GeneralSettings/script";
const CleverTap = ({ tabKey }: { tabKey: string }) => {
    const [form] = Form.useForm()
    const queryClient = useQueryClient();

    const { data } = useQuery(
        'generalSettings',
        getGeneralDetails
    );

    const mutation = useMutation(updateSellerCheckoutConfig, {
        onError: () => {
            notification.error({ message: 'Something went wrong.' });
        },
        onSuccess: () => {
            queryClient.invalidateQueries('generalSettings');
            notification.success({ message: 'Updated seller details.' });
        },
    });

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                base_url: data?.marketing_config?.clever_tap?.base_url,
                "X-CleverTap-Account-Id": data?.marketing_config?.clever_tap?.['X-CleverTap-Account-Id'],
                "X-CleverTap-Passcode": data?.marketing_config?.clever_tap?.["X-CleverTap-Passcode"],
                InitiateCheckout_SR: data?.marketing_config?.clever_tap?.events?.InitiateCheckout_SR,
                logged_in: data?.marketing_config?.clever_tap?.events?.logged_in,
                address_selected: data?.marketing_config?.clever_tap?.events?.address_selected,
                shipping_selected: data?.marketing_config?.clever_tap?.events?.shipping_selected,
                AddressAddedSR: data?.marketing_config?.clever_tap?.events?.AddressAddedSR,
                PaymentInitSR: data?.marketing_config?.clever_tap?.events?.PaymentInitSR,
                requested_SR: data?.marketing_config?.clever_tap?.events?.requested_SR,
                ChargedSR: data?.marketing_config?.clever_tap?.events?.ChargedSR,
                entered_SR: data?.marketing_config?.clever_tap?.events?.entered_SR,
            })
        }
    }, [data, tabKey])

    const submitForm = () => {
        const postData = { ...form.getFieldsValue() }
        mutation.mutate({
            marketing_config: {
                ...data?.marketing_config,
                clever_tap: {
                    base_url: postData.base_url,
                    "X-CleverTap-Account-Id": postData?.["X-CleverTap-Account-Id"],
                    "X-CleverTap-Passcode": postData?.["X-CleverTap-Passcode"],
                    events: {
                        InitiateCheckout_SR: postData.InitiateCheckout_SR,
                        AddressAddedSR: postData.AddressAddedSR,
                        PaymentInitSR: postData.PaymentInitSR,
                        requested_SR: postData.requested_SR,
                        ChargedSR: postData.ChargedSR,
                        entered_SR: postData.entered_SR,
                    }
                }
            }
        })
    }
    return <>
        <FormContainer>
            <CustomForm layout="vertical" onFinish={submitForm} form={form}>
                <Row gutter={[50, 0]}>
                    <Col span={10}>
                        <Form.Item
                            label="Base URL"
                            name="base_url"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="Account ID"
                            name="X-CleverTap-Account-Id"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="Pass Code"
                            name="X-CleverTap-Passcode"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <SubHeading>Please select the Events you want to trigger :</SubHeading>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="InitiateCheckout_SR" valuePropName="checked">
                            <Checkbox>
                                <CheckboxText>InitiateCheckout_SR</CheckboxText>
                            </Checkbox>
                        </Form.Item>
                        <CheckboxDescriptipon>
                            Triggered when the user clicks "Buy Now".
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="requested_SR" valuePropName="checked">
                            <Checkbox>
                                <CheckboxText>requested_SR</CheckboxText>
                            </Checkbox>
                        </Form.Item>
                        <CheckboxDescriptipon>
                            Triggered when user enters the mobile number and requests OTP.
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="entered_SR" valuePropName="checked">
                            <Checkbox>
                                <CheckboxText>entered_SR</CheckboxText>
                            </Checkbox>
                        </Form.Item>
                        <CheckboxDescriptipon>
                            Triggered when user enters OTP.
                        </CheckboxDescriptipon>
                    </Col>

                    <Col span={24}>
                        <Form.Item name="AddressAddedSR" valuePropName="checked">
                            <Checkbox>
                                <CheckboxText>AddressAddedSR</CheckboxText>
                            </Checkbox>
                        </Form.Item>
                        <CheckboxDescriptipon>
                            Triggered when an address is added or fetched.
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="PaymentInitSR" valuePropName="checked">
                            <Checkbox>
                                <CheckboxText>PaymentInitSR</CheckboxText>
                            </Checkbox>
                        </Form.Item>
                        <CheckboxDescriptipon>
                            Triggered when payment is initiated.
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="ChargedSR" valuePropName="checked">
                            <Checkbox>
                                <CheckboxText>ChargedSR</CheckboxText>
                            </Checkbox>
                        </Form.Item>
                        <CheckboxDescriptipon>
                            Triggered when the order is successfully placed.
                        </CheckboxDescriptipon>
                    </Col>


                    <Col span={4} style={{ paddingBottom: '15px', paddingTop: '15px' }}>
                        <PickrrButton htmlType="submit" color="checkout-blue">
                            Save
                        </PickrrButton>
                    </Col>
                </Row>
            </CustomForm>
        </FormContainer>
    </>
}
export default CleverTap