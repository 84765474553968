import React, { useEffect, useState } from "react";
import { Steps, Tooltip } from "antd";
import { FlexRow } from "./styled";
import SelectRuleType from "./SelectRuleTypeCard";
import AddCriteria from "./AddCriteria";
import EnterValue from "./EnterValue";
import { LeftOutlined, CloseOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { notification } from "antd";
import { FlexColCenterContainer } from "components/UIElements";
import {
  CustomModal,
  CancelButton,
  BackButton,
  NextButton,
  Heading,
  SubHeading,
  ToggleContainer,
  ToggleSwitch,
  ToggleLabel,
  HeadingContainer,
  ABTestingText,
  TextInput,
  FormButton,
  ButtonGroup,
  HowItworkText
} from "./styled";
import { createNewRule, updateRule } from "./functions";
import { fetchSellerCheckoutConfig } from "../Discount/functions";
import { GetAuthToken } from "globalQuries";
import {InfoCircleOutlined} from '@ant-design/icons'
import {GreenRightIcon} from 'images/icons'
const { Step } = Steps;

const CreateRuleModal = (props) => {
  const queryClient = useQueryClient();

  const {
    data: configData,
    isLoading,
    isSuccess,
  } = useQuery("configData", fetchSellerCheckoutConfig);

  const postRuleMutation = useMutation(createNewRule, {
    onError: (error) => {
      if (
        error.response.data.message === "Rule for this priority already exists."
      ) {
        notification.error({ message: error.response.data.message });
      } else {
        notification.error({ message: "Something went wrong." });
      }
    },
    onSuccess: () => {
      setIsRuleDone(true);
      if (ruleName === "custom_rule") {
        handleCancel();
      }
    },
  });

  const updateRuleMutation = useMutation(updateRule, {
    onError: () => {
      notification.error({ message: "Something went wrong." });
    },
    onSuccess: () => {
      props.handleQueryReFetch();
      notification.success({ message: "Updated existing rule." });
      handleCancel();
    },
  });

  const weightListArray = props?.editData?.determining_factors?.weight?.length
    ? props?.editData?.determining_factors?.weight
    : undefined;

  const codCountBlockArray = props?.editData?.determining_factors
    ?.cod_count_block?.length
    ? props?.editData?.determining_factors?.cod_count_block
    : undefined;

  const codValueBlockArray = props?.editData?.determining_factors
    ?.cod_value_block?.length
    ? props?.editData?.determining_factors?.cod_value_block
    : undefined;

  const invoiceListArray = props?.editData?.determining_factors?.invoice_value
    ?.length
    ? props?.editData?.determining_factors?.invoice_value
    : undefined;

  const laneListArray = props?.editData?.determining_factors?.lane?.length
    ? props?.editData?.determining_factors?.lane.map((lane, index) => {
        return {
          origin_cities: lane.source,
          destination_cities: lane.destination,
          id: index,
        };
      })
    : undefined;

  const zoneListArray = props?.editData?.determining_factors?.zone?.length
    ? props?.editData?.determining_factors?.zone
    : undefined;

  const pickupPincodeArray = props?.editData?.determining_factors
    ?.pickup_pincode?.length
    ? props?.editData?.determining_factors?.pickup_pincode
    : undefined;

  const destinationPincodeArray = props?.editData?.determining_factors
    ?.destination_pincode?.length
    ? props?.editData?.determining_factors?.destination_pincode
    : undefined;

  const phoneNumbersArray =
    props?.editData?.determining_factors?.block_phone_no;

  const [current, setCurrent] = useState(0);
  const [ruleName, setRuleName] = useState(
    props?.editData?.rule_type ?? "prepaid_discount"
  );
  const [productList, setProductList] = useState(
    props?.editData?.determining_factors?.product_sku?.length
      ? props?.editData?.determining_factors?.product_sku
      : []
  );
  const [pickupPincodeList, setPickupPincodeList] = useState(
    pickupPincodeArray?.length ? pickupPincodeArray : []
  );
  const [destinationPincodeList, setDestinationPincodeList] = useState(
    destinationPincodeArray?.length ? destinationPincodeArray : []
  );
  const [weightList, setWeightList] = useState(
    weightListArray?.length ? weightListArray : [{}]
  );
  const [codCountBlockList, setCodCountBlockList] = useState(
    codCountBlockArray?.length ? codCountBlockArray : [{}]
  );
  const [codValueBlockList, setCodValueBlockList] = useState(
    codValueBlockArray?.length ? codValueBlockArray : [{}]
  );
  const [invoiceValueList, setInvoiceValueList] = useState(
    invoiceListArray?.length ? invoiceListArray : [{}]
  );
  const [zoneList, setZoneList] = useState(
    zoneListArray?.length ? zoneListArray : []
  );
  const [laneList, setLaneList] = useState(
    laneListArray?.length ? laneListArray : [{}]
  );
  const [riskProfile, setRiskProfile] = useState(
    props?.editData?.determining_factors?.risk_profile_config
      ?.risk_profile_high ?? false
  );
  const [riskProfileVeryHigh, setRiskProfileVeryHigh] = useState(false);
  const [phoneNumberList, setPhoneNumberList] = useState(
    phoneNumbersArray?.length ? phoneNumbersArray : []
  );

  const [applyMaxValue, setApplyMaxValue] = useState(
    props?.editData?.apply_maximum_value ?? true
  );
  const [ruleValuePercent, setRuleValuePercent] = useState(
    props?.editData?.rule_value_percent ?? 0
  );
  const [ruleValueAbsolute, setRuleValueAbsolute] = useState(
    props?.editData?.rule_value_absolute ?? 0
  );
  const [ruleBSettings, setRuleBSettings] = useState({
    payment_modes_discount: {},
    ruleValueAbsolute: 0,
    ruleValuePercent: 0,
    applyMaxValue: false,
    fastrrShipping: null,
    noRushShipping: null,
    standardShipping: null,
    shipping_disable: {
      show_rush: true,
      show_express: true,
      show_standard: true,
    },
    partialCodShipping: false,
    partialValue: 0,
    partialType: "percentage",
  });

  const [fastrrShipping, setFastrrShipping] = useState(
    props?.editData?.shipping?.fastrr ?? 0
  );
  const [noRushShipping, setNoRushShipping] = useState(
    props?.editData?.shipping?.no_rush ?? 0
  );
  const [standardShipping, setStandardShipping] = useState(
    props?.editData?.shipping?.standard ?? 0
  );
  const [isRuleDone, setIsRuleDone] = useState(false);
  const [ruleATag, setRuleATag] = useState('');
  const [ruleBTag, setRuleBTag] = useState('');
  const [shipping_disable, setShippingDisable] = useState(
    props?.editData?.edd_config
      ? props?.editData?.edd_config
      : { show_rush: true, show_express: true, show_standard: true }
  );
  const [payment_modes_discount, setPaymentModesDiscount] = useState({});

  const [utmList, setUtmList] = useState(
    props?.editData?.determining_factors?.utm_param?.length
      ? props?.editData?.determining_factors?.utm_param
      : [{}]
  );

  const [cartAttributesList, setCartAttributesList] = useState(
    props?.editData?.determining_factors?.cart_attributes?.length
      ? props?.editData?.determining_factors?.cart_attributes
      : [{}]
  );

  const [productTagsList, setProductTagsList] = useState();
  const [storeCodesList, setStoreCodesList] = useState(
    props?.editData?.determining_factors?.warehouse_code?.length
    ? props?.editData?.determining_factors?.warehouse_code
    : []);

  const [productCollectionList, setProductCollectionList] = useState(
    props?.editData?.determining_factors?.product_collection_id?.length
      ? props?.editData?.determining_factors?.product_collection_id
      : []
  );
  const [selectedRiskTags, setSelectedRiskTags] = useState();
  const [riskType, setRiskType] = useState("risk_profile");
  const [partialCodShipping, setPartialCodShipping] = useState(false);
  const [patialType, setPatialType] = useState("percentage");
  const [partialValue, setPartialValue] = useState(0);
  const [isABTesting, setIsABTesting] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [typeAisOpen, settypeAIsOpen] = useState(false);
  const [typeBisOpen, settypeBIsOpen] = useState(false);
  const [selectedRule, setSelectedRule] = useState([]);
  const [typeARule, setTypeARule] = useState([]);
  const [typeBRule, setTypeBRule] = useState([]);
  const [coverage, setCoverage] = useState(50);
  const [customRuleName, setCustomRuleName] = useState("Custom Rule");
  const [isCustomABRuleEditing, setCustomABRuleEditing] = useState(false);
  const [shippingMethods, setShippingMethods] = useState({
    hyperlocal: {
        checked: true,
        charge: props.editData?.hyperlocal_shipping_config?.hyperlocal_charge,
    },
    standard: {
        checked: props.editData?.hyperlocal_shipping_config?.standard_charge !== null,
        charge: props.editData?.hyperlocal_shipping_config?.standard_charge,
    },
    express: {
        checked: props.editData?.hyperlocal_shipping_config?.no_rush_charge !== null,
        charge: props.editData?.hyperlocal_shipping_config?.no_rush_charge,
    },
    rush: {
        checked: props.editData?.hyperlocal_shipping_config?.fastrr_charge !== null,
        charge: props.editData?.hyperlocal_shipping_config?.fastrr_charge,
    },
});

  const [upsellItems,setUpsellItems]=useState([])

  const [inventorySource, setInventorySource] = useState(props?.editData?.determining_factors?.hyperlocal_order_factors?.inventory_source || null);
  const [inventoryBuffer, setInventoryBuffer] = useState(props?.editData?.determining_factors?.hyperlocal_order_factors?.inventory_buffer || 0);


  const hourlyCappingList = props?.editData?.determining_factors?.hyperlocal_order_factors?.hourly_capping_list || [];

  const sortedData = {
    TODAY: hourlyCappingList.filter(item => item.delivery_slot === "Today"),
    TOMORROW: hourlyCappingList.filter(item => item.delivery_slot === "Tomorrow")
};

  const [timingsList, setTimingsList] = useState({
    TODAY: sortedData.TODAY ||[{}],
    TOMORROW: sortedData.TOMORROW || [{}],
  });


  useEffect(() => {
    if (
      props?.editData?.determining_factors?.risk_profile_config
        ?.risk_profile_very_high
    ) {
      setRiskProfileVeryHigh(
        props?.editData?.determining_factors?.risk_profile_config
          ?.risk_profile_very_high
      );
    } else {
      setRiskProfileVeryHigh(false);
    }
    if (props.editData?.partial_cod_config) {
      if (props?.editData?.partial_cod_config?.rule_value_absolute) {
        const data = props?.editData?.partial_cod_config?.rule_value_absolute
          ? props?.editData?.partial_cod_config?.rule_value_absolute
          : 0;
        setPartialValue(data);
        setPatialType("absolute");
      } else {
        const data = props?.editData?.partial_cod_config?.rule_value_percent
          ? props?.editData?.partial_cod_config?.rule_value_percent
          : 0;
        setPartialValue(data);
        setPatialType("percentage");
      }
    }
    if (props.editData?.partial_cod_config?.include_shipping) {
      const data = props.editData.partial_cod_config?.include_shipping
        ? props?.editData?.partial_cod_config?.include_shipping
        : false;
      setPartialCodShipping(data);
    }
      if (props?.editData?.upsell_checkout_detail){
        setUpsellItems(props?.editData?.upsell_checkout_detail)
      }

    if (
      props?.editData?.determining_factors?.risk_profile_config?.risk_tags
        ?.length
    ) {
      const data = props?.editData?.determining_factors?.risk_profile_config
        ?.risk_tags?.length
        ? props?.editData?.determining_factors?.risk_profile_config?.risk_tags
        : [];
      setSelectedRiskTags(data);
      setRiskType("risk_tags");
    }

    if (props?.editData?.determining_factors?.product_tag?.length) {
      const data = props?.editData?.determining_factors?.product_tag?.length
        ? props?.editData?.determining_factors.product_tag
        : [];
      setProductTagsList(data);
    }
    handleCustomRule();
  }, []);

  const handleRuleBChange = (key, value) => {
    setRuleBSettings((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // Function to update nested values inside `shipping_disable`
  const handleRuleBNestedChange = (parentKey, childKey, value) => {
    setRuleBSettings((prev) => ({
      ...prev,
      [parentKey]: {
        ...prev[parentKey],
        [childKey]: value,
      },
    }));
  };
  const rules = [
    { label: "Partial COD", key: "partial_cod" },
    { label: "COD Charge", key: "cod_charge" },
    { label: "Shipping Charge", key: "shipping_charge" },
    { label: "Shipping Visibility", key: "shipping_disable" },
    { label: "Prepaid Discount", key: "prepaid_discount" },
    { label: "Disable COD", key: "cod_disable" },
  ];

  const handleCustomRule = () => {
    if (
      props.editData &&
      (props.editData.rule_type === "custom_rule" ||
        props.editData.rule_type === "ab_testing")
    ) {
      const { ab_testing } = props.editData;
      if (!!ab_testing) {
        setCoverage(ab_testing.splitMap["A"]);
        setIsABTesting(true);
        let _typeArule = rules.filter((val) =>
          ab_testing.variantIdExperimentList["A"].some(
            (exp) => exp.rule_type === val.key
          )
        );
        setCustomABRuleEditing(true)
        setTypeARule(_typeArule);
        ab_testing.variantIdExperimentList["A"].map(_rules => {
        setRuleATag(_rules.rule_tag)

          if (_rules.rule_type === "shipping_disable") {
            setShippingDisable(_rules.edd_config)
          } else if (_rules.rule_type === "partial_cod") {
            _rules.partial_cod_config.rule_value_percent === 0 ? setPatialType('absolute') :setPatialType('percentage')
            setPartialValue(_rules.partial_cod_config.rule_value_percent !== 0 ? _rules.partial_cod_config.rule_value_percent : _rules.partial_cod_config.rule_value_absolute)
            setPartialCodShipping(_rules.partial_cod_config.include_shipping)
          }
        })
       
        let _typeBrule = rules.filter((val) =>
          ab_testing.variantIdExperimentList["B"].some(
            (exp) => exp.rule_type === val.key
          )
        );
        let _brule = ab_testing.variantIdExperimentList["B"][0];
        setRuleBTag(_brule.rule_tag)

        let _brule_filterData = {
          payment_modes_discount: _brule?.payment_modes_discount,
          ruleValueAbsolute: _brule?.rule_value_absolute,
          ruleValuePercent: _brule?.rule_value_percent,
          applyMaxValue: _brule?.apply_maximum_value,
          fastrrShipping: _brule?.shipping.fastrr,
          noRushShipping: _brule?.shipping.no_rush,
          standardShipping: _brule?.shipping?.standard,

          partialCodShipping: _brule?.partial_cod_config?.include_shipping
            ? _brule?.partial_cod_config?.include_shipping
            : false,
          partialValue: _brule?.partial_cod_config?.rule_value_absolute
            ? _brule?.partial_cod_config?.rule_value_absolute
            : _brule?.partial_cod_config?.rule_value_percent,
          partialType: _brule?.partial_cod_config?.rule_value_percent
            ? "percentage"
            : "absolute",
        };
        if (_brule.edd_config) {
          _brule_filterData["shipping_disable"] = _brule.edd_config;
        }
        setRuleBSettings((prevdata) => {
          return { ...prevdata, ..._brule_filterData };
        });
        setTypeBRule(_typeBrule);
        setCustomRuleName(ab_testing.name);
      } else {
        const { custom_rule } = props.editData;
        setCustomRuleName(custom_rule.name);
        let _typeArule = rules.filter((val) =>
          custom_rule.variantIdExperimentList["A"].some(
            (exp) => exp.rule_type === val.key
          )
        );
        setSelectedRule(_typeArule);
        custom_rule.variantIdExperimentList["A"].map(_rules => {
          if (_rules.rule_type === "shipping_disable") {
            setShippingDisable(_rules.edd_config)
          } else if (_rules.rule_type === "partial_cod") {
            _rules.partial_cod_config.rule_value_percent === 0 ? setPatialType('absolute') :setPatialType('percentage')
            setPartialValue(_rules.partial_cod_config.rule_value_percent !== 0 ? _rules.partial_cod_config.rule_value_percent : _rules.partial_cod_config.rule_value_absolute)
            setPartialCodShipping(_rules.partial_cod_config.include_shipping)
          }
        })
      }
      setRuleName("custom_rule");
    }
  };
  const handleBack = () => {
    if (current > 0) {
      const newCurrent = current - 1;
      setCurrent(newCurrent);
    }
  };

  useEffect(() => {
    if (props.editData?.payment_modes_discount) {
      setPaymentModesDiscount(props.editData?.payment_modes_discount);
    }
  }, [props.editData?.payment_modes_discount]);

  const handleNext = () => {
    let pickUpString = pickupPincodeList.length
      ? pickupPincodeList.join()
      : null;

    let destinationString = destinationPincodeList
      ? destinationPincodeList.join()
      : null;

    let phoneNumberString = phoneNumberList.length
      ? phoneNumberList.join()
      : null;

    let productSkuString = productList.length ? productList.join() : null;

    let phoneNumberError = false;
    let pickupError = false;
    let destinationError = false;
    let singleValueExpectedError = false;
    let productSkuError = false;

    if (current === 1) {
      if (productSkuString) {
        if (
          productSkuString[productSkuString.length - 1] === "," ||
          productSkuString[0] === "," ||
          productSkuString.match(/\s+/g)
        ) {
          productSkuError = true;
          notification.error({
            message: "Please enter product sku list in correct format.",
          });
        } else {
          productSkuError = false;
        }
      } else {
        productSkuError = false;
      }

      if (pickUpString) {
        if (
          pickUpString[pickUpString.length - 1] === "," ||
          pickUpString[0] === "," ||
          !pickUpString.match(/^([1-9][0-9]{5}\,?)+$/g)
        ) {
          pickupError = true;
          notification.error({
            message: "Please enter pickup pincodes in correct format.",
          });
        } else {
          pickupError = false;
        }
      } else {
        pickupError = false;
      }

      if (destinationString) {
        if (
          destinationString[destinationString.length - 1] === "," ||
          destinationString[0] === "," ||
          !destinationString.match(/^([1-9][0-9]{5}\,?)+$/g)
        ) {
          destinationError = true;
          notification.error({
            message: "Please enter destination pincodes in correct format.",
          });
        } else {
          destinationError = false;
        }
      } else {
        destinationError = false;
      }

      if (phoneNumberString) {
        if (
          phoneNumberString[phoneNumberString.length - 1] === "," ||
          phoneNumberString[0] === "," ||
          !phoneNumberString.match(/^([0-9]{10}\,?)+$/g)
        ) {
          phoneNumberError = true;
          notification.error({
            message: "Please enter phone numbers in correct format.",
          });
        } else {
          phoneNumberError = false;
        }
      } else {
        phoneNumberError = false;
      }

      if (
        riskProfile ||
        riskProfileVeryHigh ||
        productList.length ||
        zoneList.length ||
        pickupPincodeList.length ||
        destinationPincodeList.length ||
        phoneNumberList.length ||
        (invoiceValueList.length &&
          invoiceValueList[0].min >= 0 &&
          invoiceValueList[0].max) ||
        (weightList.length && weightList[0].min && weightList[0].max) ||
        (codCountBlockList?.length &&
          codCountBlockList[0].count > 0 &&
          codCountBlockList[0].occurence_window_in_seconds) ||
        (codValueBlockList?.length &&
          codValueBlockList[0].value > 0 &&
          codValueBlockList[0].occurence_window_in_seconds) ||
        (laneList?.length &&
          laneList?.[0]?.origin_cities &&
          laneList?.[0]?.destination_cities) ||
        utmList.length ||
        productTagsList.length ||
        productCollectionList?.length
      ) {
        singleValueExpectedError = false;
      } else {
        singleValueExpectedError = true;
        notification.error({ message: "Select atleast one criteria." });
      }

      if (
        !pickupError &&
        !destinationError &&
        !singleValueExpectedError &&
        !phoneNumberError &&
        !productSkuError
      ) {
        if (ruleName === "cod_disable") {
          handleCreate();
        } else {
          const newCurrent = current + 1;
          setCurrent(newCurrent);
        }
      }
    } else if (current === 2) {
      if (!valid_custom_rule()) {
        notification.error({ message: "Select Values" });
        return;
      }
      if (ruleName === "custom_rule") {
        setIsRuleDone(true);
      } else {
        handleCreate();
      }
    } else if (current === 0) {
      const newCurrent = current + 1;
      setCurrent(newCurrent);
    }
  };

  const valid_custom_rule = () => {
    if (ruleName !== "custom_rule") {
      return true;
    } else {
      if (!isABTesting && selectedRule.length === 0) {
        return false;
      } else if (
        isABTesting &&
        (typeARule.length === 0 || typeBRule.length === 0)
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  const handleCancel = () => {
    props.handleQueryReFetch();
    props.handleCloseModal();
    setCurrent(0);
    setRuleName("");
    setProductList([]);
    setPickupPincodeList([]);
    setDestinationPincodeList([]);
    setPhoneNumberList([]);
    setWeightList([]);
    setInvoiceValueList([]);
    setZoneList([]);
    setLaneList([]);
    setRiskProfile(null);
    setRiskProfileVeryHigh(null);
    setApplyMaxValue(true);
    setRuleValueAbsolute(0);
    setRuleValuePercent(0);
    setFastrrShipping(null);
    setNoRushShipping(null);
    setStandardShipping(null);
    setIsRuleDone(false);
    setPaymentModesDiscount({});
    setProductCollectionList([]);
    setUtmList([]);
    setProductTagsList([]);
    setRiskType("risk_profile");
    setSelectedRiskTags();;
    setStoreCodesList([]);
  };

  const getName = (ruleName) => {
    if (ruleName === "prepaid_discount") return "Prepaid Discount";
    if (ruleName === "cod_charge") return "COD Charge";
    if (ruleName === "shipping_charge") return "Shipping Charge";
    if (ruleName === "cod_disable") return "Disable COD";
    if (ruleName === "shipping_disable") return "Shipping Visibility";
    if (ruleName === "partial_cod") return "Partial COD";
    if (ruleName === "custom_rule") return "Custom Rule";
    if (ruleName === 'upsell_checkout')return 'Upsell Checkout';
    if (ruleName === 'hyperlocal_shipping') return 'Hyperlocal Shipping';
  };

  const getRulePayload = (rulename, ruleFor = "forTypeA") => {
    if (rulename === "shipping_disable") {
      return ruleFor === "forTypeA"
        ? { ...shipping_disable }
        : { ...ruleBSettings.shipping_disable };
    }

    if (rulename === "partial_cod") {
      return {
        rule_value_percent:
          ruleFor === "forTypeA"
            ? patialType === "percentage"
              ? partialValue
              : 0
            : ruleBSettings.partialType === "percentage"
            ? ruleBSettings.partialValue
            : 0,
        rule_value_absolute:
          ruleFor === "forTypeA"
            ? patialType === "absolute"
              ? partialValue
              : 0
            : ruleBSettings.partialType === "absolute"
            ? ruleBSettings.partialValue
            : 0,
        include_shipping:
          ruleFor === "forTypeA"
            ? partialCodShipping
              ? partialCodShipping
              : false
            : ruleBSettings.partialCodShipping
            ? ruleBSettings.partialCodShipping
            : false,
      };
    }
  };

  const rules_payload = (rulename, ruleFor = "forTypeA") => {
    const auth = GetAuthToken();
    const cod_value_block_filtered = codValueBlockList?.filter((data) => {
      if (data?.occurence_window_in_seconds && data?.value) {
        return {
          occurence_window_in_seconds: data.occurence_window_in_seconds,
          value: data.value,
        };
      }
    });
    const cod_count_block_filtered = codCountBlockList?.filter((data) => {
      if (data?.occurence_window_in_seconds && data?.count) {
        return {
          occurence_window_in_seconds: data.occurence_window_in_seconds,
          count: data.count,
        };
      }
    })
    let upsell_checkout_config=[]
    upsellItems?.map((upsell)=>{
      upsell_checkout_config = [...upsell_checkout_config, upsell?.selectedItems?.length > 0 ? upsell?.selectedItems?.[0] : upsell?.selected_items?.length > 0 ? upsell.selected_items?.[0] : []]
      return 
    })
    let postData = {
      priority: props?.ruleList?.length
        ? props?.ruleList[props?.ruleList?.length - 1].priority - 1
        : 100,
      rule_type: rulename,
      rule_value_percent:
        ruleFor === "forTypeA"
          ? ruleValuePercent
          : ruleBSettings.ruleValuePercent,
      rule_value_absolute:
        ruleFor === "forTypeA"
          ? ruleValueAbsolute
          : ruleBSettings.ruleValueAbsolute,
      payment_modes_discount:
        ruleFor === "forTypeA"
          ? payment_modes_discount
          : ruleBSettings.payment_modes_discount,
          upsell_checkout_config : upsell_checkout_config,
          upsell_checkout_detail : upsellItems.map((upsellItem)=>{return {id: upsellItem.id , selected_items: upsellItem?.selectedItems?.length > 0 ? upsellItem?.selectedItems : upsellItem?.selected_items?.length > 0 ? upsellItem.selected_items:[],name: upsellItem.name}}),
      hyperlocal_shipping_config : {
        fastrr_charge: shippingMethods?.rush.checked ? shippingMethods?.rush?.charge : null,
        hyperlocal_charge: shippingMethods?.hyperlocal?.checked ? shippingMethods?.hyperlocal?.charge : null,
        no_rush_charge: shippingMethods?.express?.checked ? shippingMethods?.express?.charge : null,
        standard_charge: shippingMethods?.standard?.checked ? shippingMethods?.standard?.charge : null
      },
      determining_factors: {
        block_phone_no: phoneNumberList,
        pickup_pincode: pickupPincodeList,
        destination_pincode: destinationPincodeList,
        risk_profile_config: {
          ...(riskType === "risk_profile" && {
            risk_profile_high: riskProfile,
          }),
          ...(riskType === "risk_profile" && {
            risk_profile_very_high: riskProfileVeryHigh,
          }),
          ...(selectedRiskTags &&
            riskType !== "risk_profile" && {
              risk_tags: [...selectedRiskTags],
            }),
        },
        cod_value_block: cod_value_block_filtered,
        cod_count_block: cod_count_block_filtered,
      },
      seller_id: auth,
      apply_maximum_value:
        ruleFor === "forTypeA" ? applyMaxValue : ruleBSettings.applyMaxValue,
      shipping: {
        fastrr:
          ruleFor === "forTypeA"
            ? fastrrShipping
            : ruleBSettings.fastrrShipping,
        standard:
          ruleFor === "forTypeA"
            ? standardShipping
            : ruleBSettings.standardShipping,
        no_rush:
          ruleFor === "forTypeA"
            ? noRushShipping
            : ruleBSettings.noRushShipping,
      },
    };
    if (productList.length) {
      postData = {
        ...postData,
        determining_factors: {
          ...postData.determining_factors,
          product_sku: productList,
        },
      };
    }
    if (rulename === "shipping_disable") {
      postData = {
        ...postData,
        edd_config: getRulePayload(rulename, ruleFor),
      };
    }

    if (rulename === "partial_cod") {
      postData = {
        ...postData,
        partial_cod_config: getRulePayload(rulename, ruleFor),
      };
    }
    if(rulename === 'hyperlocal_shipping') {
      postData={
        ...postData,
        hyperlocal_shipping_config : {
          ...postData?.hyperlocal_shipping_config,
          ...(postData?.hyperlocal_shipping_config?.hyperlocal_charge >= 0 && { hyperlocal_charge : postData?.hyperlocal_shipping_config?.hyperlocal_charge} ),
          ...(postData?.hyperlocal_shipping_config?.fastrr_charge >= 0 && {fastrr_charge : postData?.hyperlocal_shipping_config?.fastrr_charge} ),
          ...(postData?.hyperlocal_shipping_config?.no_rush_charge >= 0 && {no_rush_charge : postData?.hyperlocal_shipping_config?.no_rush_charge} ),
          ...(postData?.hyperlocal_shipping_config?.standard_charge >= 0 && {standard_charge : postData?.hyperlocal_shipping_config?.standard_charge} ),
          ...(postData?.hyperlocal_shipping_config?.hyperlocal_text && {hyperlocal_text : postData?.hyperlocal_shipping_config?.hyperlocal_text} ),
        },
        determining_factors: {
          ...postData?.determining_factors,
          hyperlocal_order_factors: {
            ...postData?.determining_factors?.hyperlocal_order_factors,
            inventory_source: inventorySource,
            inventoryBuffer: inventoryBuffer,
            hourly_capping_list: [
              ...timingsList?.TODAY.map(slot => ({
                ...slot,
                delivery_slot: "Today",
                ...(slot.order_count && {order_count: parseInt(slot.order_count)})
            })),
            ...timingsList?.TOMORROW.map(slot => ({
                ...slot,
                delivery_slot: "Tomorrow",
                ...(slot.order_count && {order_count: parseInt(slot.order_count)})
            }))
            ]
          }
        }
      }
    }

    if (weightList?.length > 0 && Object.keys(weightList[0])?.length !== 0) {
      const filteredWeightList = weightList.filter((weight) => {
        if (weight.min !== null && weight.max)
          return { min: weight.min, max: weight.max };
      });
      if (filteredWeightList.length > 0) {
        postData = {
          ...postData,
          determining_factors: {
            ...postData.determining_factors,
            weight: filteredWeightList,
          },
        };
      }
    }
    if (invoiceValueList.length) {
      const filteredInvoiceList = invoiceValueList.filter((inv) => {
        if (inv.min >= 0 && inv.max) return { min: inv.min, max: inv.max };
      });
      if (filteredInvoiceList.length) {
        postData = {
          ...postData,
          determining_factors: {
            ...postData.determining_factors,
            invoice_value: filteredInvoiceList,
          },
        };
      }
    }
    if (zoneList.length) {
      postData = {
        ...postData,
        determining_factors: {
          ...postData.determining_factors,
          zone: zoneList,
        },
      };
    }
    if (laneList.length) {
      const filteredLaneList = laneList.filter((lane) => {
        if (lane?.origin_cities?.length && lane?.destination_cities?.length)
          return lane;
      });
      if (filteredLaneList.length) {
        postData = {
          ...postData,
          determining_factors: {
            ...postData.determining_factors,
            lane: filteredLaneList.map((lane) => {
              return {
                source: lane.origin_cities,
                destination: lane.destination_cities,
              };
            }),
          },
        };
      }
    }

    if (productTagsList?.length) {
      postData = {
        ...postData,
        determining_factors: {
          ...postData.determining_factors,
          product_tag: productTagsList,
        },
      };
    }

    if(storeCodesList?.length) {
      postData = {
        ...postData,
        determining_factors: {
          ...postData.determining_factors,
          warehouse_code: storeCodesList,
        },
      };
    }

    if (utmList.length) {
      const filteredUtmList = utmList.filter((utm) => {
        if (utm.source || utm.medium || utm.campaign)
          return {
            source: utm.source,
            medium: utm.medium,
            campaign: utm.campaign,
          };
      });
      if (filteredUtmList.length) {
        postData = {
          ...postData,
          determining_factors: {
            ...postData.determining_factors,
            utm_param: filteredUtmList,
          },
        };
      }
    }

    if (cartAttributesList?.length) {
      const filteredCartAttributesList = cartAttributesList.filter(
        (customAttribute) => {
          if (customAttribute.key || customAttribute.value)
            return { key: customAttribute.key, value: customAttribute.value };
        }
      );
      if (filteredCartAttributesList.length) {
        postData = {
          ...postData,
          determining_factors: {
            ...postData.determining_factors,
            cart_attributes: filteredCartAttributesList,
          },
        };
      }
    }
    if (productCollectionList.length) {
      postData = {
        ...postData,
        determining_factors: {
          ...postData.determining_factors,
          product_collection_id: productCollectionList.map((collection) => {
            return {
              id: collection.id,
              name: collection.name,
            };
          }),
        },
      };
    }
    if(isABTesting){
      postData.rule_tag = ruleFor === "forTypeA" ? ruleATag : ruleBTag
    }
    return postData;
  };

  const handleCreate = async () => {
    delete payment_modes_discount["Default"];
    if(isABTesting){
      delete ruleBSettings.payment_modes_discount["Default"]
    }
    if(ruleName === 'custom_rule' && customRuleName ===''){
      return
    }
    let postData = rules_payload(ruleName);

    if (ruleName === "custom_rule") {
      let _key = isABTesting ? "ab_testing" : "custom_rule";
      postData = {
        ...postData,
        rule_type:_key,
        [_key]: {
          name: customRuleName,
          variantIdExperimentList: {
            ...(!isABTesting && {
              A: selectedRule.map((rule_label) => {
                return rules_payload(rule_label.key);
              }),
            }),
            ...(isABTesting && {
              A: typeARule.map((rule_label) => {
                return rules_payload(rule_label.key);
              }),
              B: typeBRule.map((rule_label) => {
                return rules_payload(rule_label.key, "forTypeB");
              }),
            }),
          },
          splitMap: !isABTesting
            ? {
                A: 100,
              }
            : { A: coverage, B: 100 - coverage },
        },
      };
    }

    if (!props.editData) {
      postRuleMutation.mutate(postData);
    } else {
      const editedRule = [
        {
          ...postData,
          rule_id: props.editData._id,
          is_active: props.editData.is_active,
          priority: props.editData.priority,
        },
      ];
      updateRuleMutation.mutate({ rules: editedRule });
    }
  };

  const setAbTestData = () => {
    setIsABTesting((_val=>{
      let _tempval = !_val
      if(_tempval){
        selectedRule.map((_rule, i) => {
          if (i == 0) {
            setTypeARule([_rule]);
          }
          if (i == 1) {
            setTypeBRule([_rule]);
            let _tmp = JSON.parse(JSON.stringify(rules_payload(_rule.key)));
            let _payload = {...ruleBSettings,..._tmp}
            _payload.applyMaxValue = _payload.apply_maximum_value
            _payload.ruleValuePercent = _payload.rule_value_percent
            if(_rule.key == "shipping_charge"){
              _payload.fastrrShipping = _payload.shipping.fastrr
              _payload.noRushShipping = _payload.shipping.no_rush
              _payload.standardShipping = _payload.shipping.standard
              setNoRushShipping(null);
              setStandardShipping(null);
              setFastrrShipping(null);
            }else if(_rule.key == "partial_cod"){
              if (_payload?.partial_cod_config?.rule_value_absolute) {
                const data = _payload?.partial_cod_config?.rule_value_absolute
                  ? _payload?.partial_cod_config?.rule_value_absolute
                  : 0;
                  _payload.partialValue = data
                _payload.partialType = "absolute"
              } else {
                const data = _payload.partial_cod_config?.rule_value_percent
                  ? _payload?.partial_cod_config?.rule_value_percent
                  : 0;
                   _payload.partialValue = data
                _payload.partialType = "percentage"

              }
              _payload.applyMaxValue = applyMaxValue
              setPatialType('percentage');
              setPartialValue(0)
            }
            else if(_rule.key == 'shipping_disable'){
              _payload.shipping_disable = _payload.edd_config
              setShippingDisable( { show_rush: true, show_express: true, show_standard: true })
            }
            else if(_rule.key === 'prepaid_discount'){
              setPaymentModesDiscount({})
            }
            else if(_rule.key === "cod_charge"){
            _payload.ruleValueAbsolute = ruleValueAbsolute
            _payload.ruleValuePercent = ruleValuePercent
            setRuleValueAbsolute(null);
            setRuleValuePercent(null);
            }
            setRuleBSettings(_payload)
          }
        });
      }
      return _tempval
    }));
   
  };

  const switchStepContent = () => {
    switch (current) {
      case 0:
        return <SelectRuleType setRuleName={setRuleName} ruleName={ruleName} />;
      case 1:
        return (
          <>
            <Heading>{getName(ruleName)}</Heading>
            <SubHeading>Select criteria and add condition</SubHeading>
            <AddCriteria
              ruleName={ruleName}
              setRuleName={setRuleName}
              productList={productList}
              setProductList={setProductList}
              pickupPincodeList={pickupPincodeList}
              setPickupPincodeList={setPickupPincodeList}
              destinationPincodeList={destinationPincodeList}
              setDestinationPincodeList={setDestinationPincodeList}
              weightList={weightList}
              setWeightList={setWeightList}
              invoiceValueList={invoiceValueList}
              setInvoiceValueList={setInvoiceValueList}
              zoneList={zoneList}
              setZoneList={setZoneList}
              laneList={laneList}
              setLaneList={setLaneList}
              riskProfile={riskProfile}
              setRiskProfile={setRiskProfile}
              riskProfileVeryHigh={riskProfileVeryHigh}
              setRiskProfileVeryHigh={setRiskProfileVeryHigh}
              phoneNumberList={phoneNumberList}
              setPhoneNumberList={setPhoneNumberList}
              codCountBlockList={codCountBlockList}
              setCodCountBlockList={setCodCountBlockList}
              codValueBlockList={codValueBlockList}
              setCodValueBlockList={setCodValueBlockList}
              productTagsList={productTagsList}
              setProductTagsList={setProductTagsList}
              utmList={utmList}
              setUtmList={setUtmList}
              productCollectionList={productCollectionList}
              setProductCollectionList={setProductCollectionList}
              selectedRiskTags={selectedRiskTags}
              setSelectedRiskTags={setSelectedRiskTags}
              riskType={riskType}
              setRiskType={setRiskType}
              cartAttributesList={cartAttributesList}
              setCartAttributesList={setCartAttributesList}
              storeCodesList={storeCodesList}
              setStoreCodesList={setStoreCodesList}
              timingsList={timingsList}
              setTimingsList={setTimingsList}
              inventorySource={inventorySource}
              setInventorySource={setInventorySource}
              inventoryBuffer={inventoryBuffer}
              setInventoryBuffer={setInventoryBuffer}
            />
          </>
        );
      case 2:
        return (
          <>
            <HeadingContainer>
              <Heading>{getName(ruleName)}</Heading>
              {ruleName === "custom_rule" && (
                <ToggleContainer
                  active={isABTesting}
                  onClick={() => {
                    if (selectedRule.length <= 2 && !isCustomABRuleEditing) {
                      setAbTestData();
                    }
                  }}
                >
                  <Tooltip title={selectedRule.length >= 2 ? "A/B test is possible with only 2 rule types":""}>
                  <ABTestingText active={selectedRule.length <= 2 && !isCustomABRuleEditing}>
                    A/B testing
                  </ABTestingText>
                  </Tooltip>

                  <ToggleSwitch
                    active={isABTesting}
                    onClick={() => {
                      if (selectedRule.length <= 2 && !isCustomABRuleEditing) {
                        setAbTestData();
                      }
                    }}
                    // disabled={selectedRule.length > 2 && !isCustomABRuleEditing}
                  >
                    <span onClick={() => {
                      if (selectedRule.length <= 2 && !isCustomABRuleEditing) {
                        setAbTestData();
                      }
                    }}></span>
                  </ToggleSwitch>
                  
                </ToggleContainer>
              )}
              <HowItworkText onClick={()=>{window.open("https://docs.google.com/document/d/1jQEhvKOTnvYt4b-wY_UqGTFohXbDRs0A9k9vN1p3NNw/edit?tab=t.0","_blank")}}><InfoCircleOutlined />&nbsp;How it works</HowItworkText>
            </HeadingContainer>
            <EnterValue
              ruleName={ruleName}
              ruleValueAbsolute={ruleValueAbsolute}
              setRuleValueAbsolute={setRuleValueAbsolute}
              ruleValuePercent={ruleValuePercent}
              setRuleValuePercent={setRuleValuePercent}
              fastrrShipping={fastrrShipping}
              setFastrrShipping={setFastrrShipping}
              noRushShipping={noRushShipping}
              setNoRushShipping={setNoRushShipping}
              standardShipping={standardShipping}
              setStandardShipping={setStandardShipping}
              applyMaxValue={applyMaxValue}
              setApplyMaxValue={setApplyMaxValue}
              shipping_disable={shipping_disable}
              setShippingDisable={setShippingDisable}
              payment_modes_discount={payment_modes_discount}
              setPaymentModesDiscount={setPaymentModesDiscount}
              partialCodShipping={partialCodShipping}
              setPartialCodShipping={setPartialCodShipping}
              patialType={patialType}
              setPatialType={setPatialType}
              partialValue={partialValue}
              setPartialValue={setPartialValue}
              isABTesting={isABTesting}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              typeAisOpen={typeAisOpen}
              settypeAIsOpen={settypeAIsOpen}
              typeBisOpen={typeBisOpen}
              settypeBIsOpen={settypeBIsOpen}
              selectedRule={selectedRule}
              setSelectedRule={setSelectedRule}
              typeARule={typeARule}
              setTypeARule={setTypeARule}
              typeBRule={typeBRule}
              setTypeBRule={setTypeBRule}
              coverage={coverage}
              setCoverage={setCoverage}
              rules={rules}
              handleRuleBChange={handleRuleBChange}
              handleRuleBNestedChange={handleRuleBNestedChange}
              ruleBSettings={ruleBSettings}
              isCustomABRuleEditing={isCustomABRuleEditing}
              ruleATag = {ruleATag}
              ruleBTag = {ruleBTag}
              setRuleATag ={setRuleATag}
              setRuleBTag = {setRuleBTag}
              upsellItems={upsellItems}
              setUpsellItems={setUpsellItems}
              shippingMethods={shippingMethods}
              setShippingMethods={setShippingMethods}
            />
          </>
        );
      default:
        return null;
    }
  };

  const createCustomRule = () => {
    handleCreate();
  };

  return (
    <CustomModal
      visible={props.visible}
      footer={null}
      width={1100}
      closable={(ruleName !== "custom_rule" || !isRuleDone)}
      centered={true}
      keyboard={true}
      onCancel={handleCancel}
    >
      {isRuleDone ? (
        <>
          <FlexColCenterContainer>
            {/* <img
              src="https://d10srchmli830n.cloudfront.net/1648623069359_Group-26963NewDiscount.svg"
              alt="dicount-created"
              style={{ marginTop: "30px", marginBottom: "10px" }}
            /> */}
            <GreenRightIcon />
           {!isCustomABRuleEditing ?  <Heading>New rule for {getName(ruleName)} is created.</Heading> :<Heading>{getName(ruleName)} edited successfully</Heading>}
           {(!isCustomABRuleEditing || !isABTesting) ? <SubHeading>
              you can prioritize, edit or delete rule from custom rule tab
            </SubHeading> :
             <SubHeading>A new A/B test has started, replacing the previous one. Data from the old test will no longer be collected, and metrics will reflect the new parameters.</SubHeading>
             }

            {ruleName == "custom_rule" && (
              <>
              {!isCustomABRuleEditing &&  <TextInput
                  type="text"
                  value={customRuleName}
                  onChange={(e) => {
                    setCustomRuleName(e.target.value);
                  }}
                />}
                {customRuleName == '' && (
                <div style={{ fontSize: 14, color: "red", marginTop: 6 }}>
                  Rule name is required
                </div>
              )}
                <FlexRow style={{ cursor: "pointer", margin: "30px 50px" }}>
                  {!isCustomABRuleEditing && <FormButton
                    onClick={() => {
                      createCustomRule();
                    }}
                  >
                    Rename
                  </FormButton>}
                  <FormButton
                    onClick={() => {
                      createCustomRule();
                    }}
                  >
                    Close
                  </FormButton>
                </FlexRow>
              </>
            )}
            <ButtonGroup></ButtonGroup>
            {ruleName !== "custom_rule" && (
              <FlexRow style={{ cursor: "pointer", margin: "30px 50px" }}>
                <CancelButton onClick={handleCancel}>CLOSE</CancelButton>
              </FlexRow>
            )}
          </FlexColCenterContainer>
        </>
      ) : (
        <>
          <Steps type="navigation" current={current}>
            <Step title="Select type"></Step>
            <Step title="Select criteria"></Step>
            {ruleName !== "cod_disable" && <Step title="Enter value"></Step>}
          </Steps>
          {switchStepContent()}
          <FlexRow>
            <FlexRow>
              {/* <CloseOutlined
                style={{
                  color: '#0C3784',
                  cursor: 'pointer',
                }}
              /> */}
              <CancelButton
                onClick={() => {
                  handleCancel();
                }}
              >
                CANCEL
              </CancelButton>
            </FlexRow>
            <FlexRow>
              <FlexRow>
                {current !== 0 && (
                  <>
                    <LeftOutlined
                      style={{
                        color: "#0C3784",
                        cursor: "pointer",
                      }}
                    />
                    <BackButton onClick={handleBack}>BACK</BackButton>
                  </>
                )}
              </FlexRow>
              <NextButton onClick={handleNext}>NEXT</NextButton>
            </FlexRow>
          </FlexRow>
        </>
      )}
    </CustomModal>
  );
};

export default CreateRuleModal;
