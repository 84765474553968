import { CustomSelectTickImage, DownIcon, SearchIcon } from "images/icons";
import React, { useEffect, useRef, useState } from "react";
export const CustomReactInput = ({ addOnBefore, onChangeHandler, onPressEnterHandler, onSearchKeyChange, searchKey, defaultValue, inputValue, setSearchTerm }: any) => {
    const [selectedOption, setSelectedOption] = useState(defaultValue);
    const [isOpen, setIsOpen] = useState(false);


    function useOutsideAlerter(ref: any) {
        useEffect(() => {
          function handleClickOutside(event: Event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setIsOpen(false)
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref, searchKey]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    
    return (
        <div className="relative flex" ref={wrapperRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="h-10 w-52 shadow-sm border rounded-md rounded-r-none border-outline focus:outline-none text-sm text-sortByColor"
            >
                
                <div className="flex justify-between items-center pl-5 pr-3 ">
                    {selectedOption ? selectedOption : "Order Id"}
                    {DownIcon(isOpen)} 
                </div>
                 
            </button>
            <span className="flex">
                <input className="h-10 w-72 text-searchColor border rounded-r-md border-l-0 border-outline shadow-sm pl-4 pr-2 focus:outline-none" placeholder="Search..." onChange={(e:any) => { onChangeHandler(e) }}
                    onKeyDown={(e) => { if (e.key === 'Enter') { onPressEnterHandler(e) } }}></input>
                <span className="absolute right-4 top-3 cursor-pointer" onClick={()=>{setSearchTerm(inputValue)}}><SearchIcon /></span>
            </span>
            {isOpen && (
                <div className="absolute top-full bg-white border border-outline rounded-md shadow-md w-52 h-fit text-sortByColor z-10">
                    {addOnBefore?.map((option: any) => (
                        <div
                            key={option.value}
                            onClick={() => {
                                onSearchKeyChange(option.value)
                                setSelectedOption(option.label)
                                setIsOpen(false)
                            }}
                            className={` flex items-center justify-between py-2 px-4 cursor-pointer hover:bg-selectHover hover:text-purpleText`}
                        >
                            <span> {option.label}</span>{searchKey == option.value && <CustomSelectTickImage />}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
