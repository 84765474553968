import React, { useContext, useState } from 'react'
import { DataContext } from "context/DataProvider";
import { useQuery } from 'react-query';
import { fetchRechargeHistory } from './script';
import { TableContainerTw, TextStyle } from './style';
import moment from 'moment';
import CustomReactTable from 'UiComponents/Table';


const NewRechargeHistory = ({ fromDate, toDate }: any) => {
    const [pageNumber, setPageNumber] = useState<number>(1)
    // fetch wallet id from config once deployed
    const { sellerId, walletId } = useContext(DataContext)

    const { data: rechargeHistoryData, isLoading, isError } = useQuery(
        ['rechargeHistory', { walletId, fromDate, toDate, pageNumber }],
        fetchRechargeHistory,
    );

    const columns: any = [
        {
            Header: 'Recharge Amount',
            accessor: 'amount',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24 py-2 pl-2'>
                    {value ? `₹ ${value.toFixed(2)}` : '-'}
                </TextStyle>
            )
        },
        {
            Header: 'Transaction Type',
            accessor: 'source',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24'>
                    <TextStyle className='min-w-32'>{value ? value : '-'}</TextStyle>
                </TextStyle>
            )
        },
        {
            Header: 'Recharge Date',
            accessor: 'recharge_timestamp',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24'>
                    <TextStyle className='min-w-32'>{value ? value : '-'}</TextStyle>
                </TextStyle>
            )
        },
        {
            Header: 'Transaction ID',
            accessor: 'transaction_id',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24'>
                    {value ? value : '-'}
                </TextStyle>
            )
        },
        {
            Header: 'Recharge Status',
            accessor: 'status',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24'>
                    {value ? value : '-'}
                </TextStyle>
            )
        },
        {
            Header: 'Remarks',
            accessor: 'remarks',
            disableSortBy: true,
            Cell: ({ value }: any) => {
                {/*// @ts-ignore */}
                return <TextStyle className='min-w-24'>
                    {value ? value : '-'}
                </TextStyle>
            }
        },
    ]


    return (
        <div>
            <TableContainerTw>
                <CustomReactTable
                    data={rechargeHistoryData?.result || []}
                    columns={columns} 
                    loading={isLoading}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={rechargeHistoryData?.totalPages}
                    checkboxes={false}
                    onSelectAll={() => { }}
                    pageSize={20}
                    selectedRowsKeys={[]}
                    defaultSortBy={[{ id: '', desc: false }]}
                    newState={''}
                    handleRowSelect={() => { }}
                    isChecked={false}
                    setHoveredRowIndex={() => { }}
                />

            </TableContainerTw>
        </div>
    )
}

export default NewRechargeHistory