import React, { useState, useContext, useEffect } from "react";
import BannerConfig from "./BannerConfig";
import Tabs from "components/CheckoutBuilder/UIComponents/Tabs";
import { Container, AccordianLeftSide } from "../styled";
import Checkout from "components/Checkout";
import { UiConfigContext } from "context/CustomUIContext";

const Banner = () => {
  const {
    preLoginBanner, 
    setPreLoginBanner,
    postLoginBanner, 
    setPostLoginBanner, 
    isPostLoginBannerSame,
    setIsPostLoginBannerSame
  } = useContext(UiConfigContext);

  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    { label: "Pre-login banner", value: 1 },
    { label: "Post-login banner", value: 2 },
  ];

  const handleCheckPostLoginSame = (value:boolean) => {
    setIsPostLoginBannerSame((prev:boolean) => !prev);

    if(value) {
      setPostLoginBanner((prev:any) => ({...prev, ...preLoginBanner}))
    }
  }

  useEffect(() => {
    if(isPostLoginBannerSame){
      setPostLoginBanner((prev:any) => ({...prev, ...preLoginBanner}))
    }
  }, [isPostLoginBannerSame, preLoginBanner])


  
  const preLoginOrPost = activeTab === 1 ? preLoginBanner : postLoginBanner;
  
  
  return (
    <Container>
      <AccordianLeftSide>
        <Tabs
          tabOptions={tabs}
          activeTab={activeTab}
          onChange={(value: number) => setActiveTab(value)}
        />
        {activeTab === 1 ? (
          <BannerConfig
            key={activeTab}
            bannerConfigData = {preLoginBanner} 
            setBannerConfigData ={setPreLoginBanner}
            isPostLogin = {false}
            callback = {handleCheckPostLoginSame}
            isPostLoginBannerSame = {isPostLoginBannerSame}
          />
        ) : (
          <BannerConfig 
            key={activeTab}
            bannerConfigData = {postLoginBanner} 
            setBannerConfigData ={setPostLoginBanner}
            isPostLogin = {true}
            callback = {handleCheckPostLoginSame}
            isPostLoginBannerSame = {isPostLoginBannerSame}
           />
        )}
      </AccordianLeftSide>
      <Checkout 
        bannerText={preLoginOrPost.bannerText} 
        behavior={preLoginOrPost.textTypeSelect} 
        bannerType = {preLoginOrPost.bannerTypeSelect}
        showBanner ={true}
        showLogin = {true}
      />
    </Container>
  );
};
export default Banner;
