import React, {useState} from 'react';
import { uploadFile } from './script';
import { Container, FileInput, InnerContainer, ErrorMessage, FilePreview, DeleteButton } from './styled';
import { IoCloudUploadOutline, IoTrashOutline } from "react-icons/io5";

const FileUploader = ({file, setFile, uploadedFile, setUploadedFile, backupFile}:any) => {
    const [error, setError] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setError(null);
        const file = e.target.files?.[0];

        if (!file) return;

        setIsUploading(true);
        setIsDeleted(false);

        try {
            const response = await uploadFile(file);
            setUploadedFile(file)
            setFile(response?.data.url);
        } catch (err) {
            setError((err as Error).message);
        } finally {
            setIsUploading(false);
        }
    };

    const handleDelete = () => {
        console.log(backupFile, 'asdhabfjkasd')
        setFile(backupFile);
        setIsDeleted(true);
        setUploadedFile("")
    };

    return (
        <Container>
            {uploadedFile && !isDeleted ? (
                <FilePreview>
                    <img src={file} alt="Uploaded File"  />
                    <DeleteButton onClick={handleDelete}>
                        <IoTrashOutline />
                    </DeleteButton>
                </FilePreview>
            ) : (
                <>
                    <InnerContainer>
                        <IoCloudUploadOutline />
                        <p>{isUploading ? 'Uploading...' : 'Upload'}</p>
                        <span>PNG, JPG, JPEG</span>
                    </InnerContainer>
                    <FileInput
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileChange}
                        disabled={isUploading}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
            )}
        </Container>
    );
};

export default FileUploader;