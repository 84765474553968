import React, {useContext, useEffect} from 'react';
import Radio from 'components/CheckoutBuilder/UIComponents/Radio';
import ColorPicker from 'components/CheckoutBuilder/UIComponents/ColorPicker';
import { AccordianLeftSide, Container } from '../../styled';
import Checkout from 'components/Checkout';
import { UiConfigContext } from 'context/CustomUIContext';
import Checkbox from 'components/CheckoutBuilder/UIComponents/Checkbox';
import {drawerTextColor} from '../data';


const DrawerHeader = () => {
    const {headerConfig, setHeaderConfig} = useContext(UiConfigContext)
    console.log(headerConfig, 'asdasdasdfads')


    useEffect(() => {
        if(headerConfig?.drawerHeaderBgColor){
            document.documentElement.style.setProperty('--drawer-bg-color', headerConfig?.drawerHeaderBgColor);
        }
        document.documentElement.style.setProperty('--drawer-text-color', headerConfig?.drawerHeaderColor); 

    }, [headerConfig?.drawerHeaderBgColor, headerConfig?.isDrawerGradient, headerConfig?.drawerHeaderColor])



    
    return (
        <Container>
        <AccordianLeftSide>
            <ColorPicker 
                label = {'Drawer background colour'} 
                infoText = {'The selected color will be applied to the drawer header'}
                color = {headerConfig?.drawerHeaderBgColor} 
                onChange={(value:any) => setHeaderConfig((prev:any) => ({...prev, drawerHeaderBgColor: value}))}
            />
            <Radio 
                options = {drawerTextColor}
                selected={headerConfig?.drawerHeaderColor}
                onChange={(value:any) => setHeaderConfig((prev:any) => ({...prev, drawerHeaderColor: value}))}
                title = "Text Color"
            />   
            
        </AccordianLeftSide>
        <Checkout 
            showLogin = {true}
            showCoupon = {true}
        />
        </Container>
    )
}

export default DrawerHeader;