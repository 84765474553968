import React, {useState} from 'react'
import Header from 'components/CommonComponents/Header'
import { Container } from '../GeneralSettings/style'
import { CustomTabs } from '../Shipping/style'
import FacebookTrackingPage from './FacebookTrackingPage'
import GA4TrackingPage from './GA4TrackingPage'
import ContloTrackingPage from './ContloTrackingPage'
import MoEngageTrackingPage from './MoEngageTrackingPage'
import GoogleAdsTrackingPage from './GoogleAdsTrackingPage'
import WigzoTrackingPage from './WigzoTrackingPage'
import { useNavigate } from 'react-router-dom';
import CleverTap from './CleverTap'
const { TabPane } = CustomTabs;

const TrackingInfo = () => {
    const navigate = useNavigate();
    const [tabKey, setTabKey] = useState<string>("1");
    const queryParameters = new URLSearchParams(window.location.search);

    const onTabChange = (key: string) => {
        setTabKey(key);
    navigate(`/settings/tracking-info?tab=${key}`);
        
    }
   useState(()=>{
   
    const tab_id = queryParameters.get("tab")
    const auth_code = queryParameters.get("code")
    if(tab_id){
        setTabKey(tab_id)
    }
    if (auth_code){
        document.cookie = `auth_key_analytics=${auth_code};max-age=604800`;
    }
   })

    return (
        <Container>
        {/*// @ts-ignore */}
        <Header
          title="Tracking Info"
        />
        <CustomTabs defaultActiveKey={tabKey} onChange={onTabChange}>
            <TabPane tab="Facebook" key="1">
                <FacebookTrackingPage tabKey={tabKey}/>
            </TabPane>
            <TabPane tab="GA4" key="2">
                <GA4TrackingPage tabKey={tabKey} />
            </TabPane>
{/*             <TabPane tab="CONTLO" key="3">
                <ContloTrackingPage tabKey={tabKey} />
            </TabPane> */}
            <TabPane tab="MOENGAGE" key="4">
                <MoEngageTrackingPage tabKey={tabKey} />
            </TabPane>
            <TabPane tab="Google Ads" key="5">
                <GoogleAdsTrackingPage tabKey={tabKey} />
            </TabPane>
            <TabPane tab="Wigzo" key="6">
                <WigzoTrackingPage tabKey={tabKey} />
            </TabPane>
            <TabPane tab="Clever Tap" key="7">
                <CleverTap tabKey={tabKey}/>
            </TabPane>
        </CustomTabs>
        </Container>
    )
}

export default TrackingInfo