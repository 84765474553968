import styled from "styled-components";


export const Line = styled.div`
    border-bottom: 2px solid var(--grey-lite);
    margin-top: -3px;
    margin-bottom: 3px;
    margin-bottom: 26px;

`

export const StepContainer = styled.div<{ isBorder?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    border-bottom: ${(props: any) => props.isBorder ? '4px solid var(--primary-color)' : ""};
    padding-bottom: 12px;    
`

export const Step = styled.div<{isCompelete?:boolean}>`
    height:30px;
    width: 30px;
    background:${(props:any) => props?.isCompelete ? 'var(--green-color)' : 'var(--primary-color)'};
    color: var(--white-color);
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:12px;
    border-radius: 50px;
`

export const StepTitle = styled.div`
    font-size:16px;
    font-weight: 500;
    color: var(--black-color);
`