// import styled from 'styled-components';

// export const Container = styled.div<{ direction?: string }>`
//   display: flex;
//   flex-direction: ${(props: any) => (props.direction ? 'column' : 'row')};
//   gap: 20px;
//   margin: 10px 0 24px;
// `;

// export const RadioLabel = styled.label<{ disabled?: boolean }>`
//   display: flex;
//   align-items: flex-start;
//   cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
//   opacity: ${(props) => (props.disabled ? '0.5' : '1')};
// `;

// export const RadioInput = styled.input`
//   display: none;
// `;

// export const CustomRadio = styled.span<{ disabled?: boolean }>`
//   min-width: 20px;
//   width: 20px;
//   height: 20px;
//   border: 2px solid #c4c4c4;
//   border-radius: 50%;
//   display: inline-block;
//   margin-right: 8px;
//   position: relative;
//   transition: all .4s;
//   margin-top: 2px;
//   background-color: ${(props) => (props.disabled ? '#e0e0e0' : '')};

//   ${RadioInput}:checked + &::before {
//     content: '';
//     width: 10px;
//     height: 10px;
//     background-color: ${(props) => (!props.disabled ? 'var(--primary-color)' : '#a0a0a0' )};
//     border-radius: 50%;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transition: all .4s;
//     transform: translate(-50%, -50%);
//   }
// `;

// export const LabelText = styled.span`
//   font-size: 16px;
//   color: #000;
// `;


import styled from 'styled-components';

export const Container = styled.div<{direction?:string}>`
  display: flex;
  flex-direction: ${(props:any) => props.direction ? 'column' : 'row'};
  gap: 16px;
  margin: 10px 0 24px;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;

export const RadioInput = styled.input`
  display: none; /* Hide the default radio button */
`;

export const CustomRadio = styled.span<{smallFont?: boolean, isPreview?:boolean}>`
  min-width: ${(props:any) => props.smallFont ? '18px' : '20px'};
  width: ${(props:any) => props.smallFont ? '18px' : '20px'};
  height: ${(props:any) => props.smallFont ? '18px' : '20px'};
  border: 2px solid #c4c4c4;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  transition: all .4s;
  margin-top: 2px;

  /* Disable styling based on sibling input */
  ${RadioInput}:disabled + & {
    background-color: #e0e0e0;
    border-color: #a0a0a0;
    cursor: not-allowed;
  }

  /* Inner circle for the checked state */
  ${RadioInput}:checked + &::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color:${(props:any) => props.isPreview ? 'var(--checkout-primary-color)' : 'var(--primary-color)'};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: all .4s;
    transform: translate(-50%, -50%);
  }
`;

export const LabelText = styled.span<{smallFont?:boolean}>`
  // font-size: 16px;
  color: #000; /* Black text */
  font-size: ${(props:any) => props.smallFont ? '13px' : '16px'}
`;
