import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { InfoIconMd } from 'images/icons';
import { InputLabel } from 'components/CheckoutBuilder/styled';


const tooltipStyle:any = {
    background: "#ffffff", 
    boxShadow: '0px 4px 25px 0px #00000033', 
    fontSize:'12px',
    color: 'var(--black-color)', 
    maxWidth: 400, 
    textAlign: 'center'
}



const Tooltip = ({label, infoText, style}:any) => {
    const uniqueId = 
    label ? label?.trim()?.split(" ")?.[0] : infoText?.trim()?.split(" ")?.[0];
    

    return (
        <React.Fragment>
            <InputLabel style = {{...style}}>{label} 
                {infoText && 
                <div data-tooltip-id = {uniqueId}>
                   <InfoIconMd />
                   </div>
                }
            </InputLabel>

            <ReactTooltip id={uniqueId} place='bottom' style={tooltipStyle}>
                {infoText}
            </ReactTooltip>
        </React.Fragment>
    )
}

export default Tooltip;