import React, { useState } from 'react';
import Tooltip from '../Tooltip';
import { ColorPickerContainer, Text, ColorInput, CustomInput } from './styled';

const isValidHex = (hex:any) => {
    return /^#([0-9A-F]{3}){1,2}$/i.test(hex);
};

const ColorPicker = ({label, infoText, color, setColor, onChange, disabled, isPostLoginBannerSame, uniqueValue}:any) => {
    const [inputColor, setInputColor] = useState(color);
    const [isValid, setIsValid] = useState(true);

    const handleChange = (event:any) => {
        const newColor = event.target.value;
        setInputColor(newColor);
        setIsValid(isValidHex(newColor));
        if (isValidHex(newColor)) {
            onChange(newColor);
        }
    };

    const handleBlur = () => {
        if (!isValidHex(inputColor)) {
            setInputColor(color);
            setIsValid(true);
        }
    };

    return (
        <div style={{width: '100%'}}>
            <Tooltip label = {label} infoText = {infoText}/>
            <ColorPickerContainer disabled = {disabled}>
                <CustomInput
                    value={inputColor}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onMouseOver={() => setIsValid(isValidHex(inputColor))}
                    style={{ borderColor: isValid ? 'initial' : 'red' }}
                    disabled = {disabled}
                />
                <ColorInput
                    type="color"
                    value={isValidHex(inputColor) ? inputColor : color}
                    onChange={handleChange}
                    disabled = {disabled}
                />
            </ColorPickerContainer>
        </div>
    );
};

export default ColorPicker;