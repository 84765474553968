import React from "react";
import { SpaceBetweenContainer, FlexBox } from "components/UIElements";
import { Text} from "../../styled";
import { SaveText, InlineSaveText } from "../styled";

const Label = ({ paymentMethodDetails, isCardDesign }: any) => {
  const { discountIcon, title, saveAmount, showDiscount } =
    paymentMethodDetails;
  return (
    <div className = "label">
      <Text style={{marginBottom: 0}}>{title} 
      {!isCardDesign && <InlineSaveText>Save <b>₹10</b></InlineSaveText>}
      </Text>
      {saveAmount && isCardDesign ?
      <SaveText color= {showDiscount ? "#238017" : "#959494"}>
        <FlexBox style={{ alignItems: "flex-start", gap: 4 }}>
          <img src={discountIcon} alt={title} />
          {saveAmount}
        </FlexBox>
      </SaveText>
      : ''}
    </div>
  );
};

export default Label;
