import React from 'react';
import { Tabstitle } from './styled';

const Tabs = ({tabOptions, activeTab, onChange}:any) => {
    return (
        tabOptions?.map((tab:any) => {
            return (
                <Tabstitle
                    isActive={activeTab === tab.value}
                    onClick = {() => onChange(tab.value)}
                >
                    {tab.label}
                </Tabstitle>
            )
        })  
    )
}

export default Tabs;