import React, { useContext, useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";
import { Container } from "./styled";
import { DefaultText } from "components/CheckoutBuilder/styled";
import { FetchPaymentConfig } from "components/Settings/Payments/script";
import { useQuery } from "react-query";
import { DataContext } from "context/DataProvider";

const PaymentMethods = ({methods, setMethods, title, componentKey}:any) => {
  // getting active payment list

  const {sellerId} = useContext(DataContext) 
  const { data : PaymentData, isLoading, isSuccess } = useQuery(
    ['userDetails', {sellerId}],
    FetchPaymentConfig
  );

  // PaymentData?.sellerPaymentConfiguration?.other_payment_methods_display_order

  // console.log(PaymentData?.sellerPaymentConfiguration?.other_payment_methods_display_order, 'checking data')




  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    console.log(active, over,'checking active and over');
    if (over && active.id !== over.id) {
      setMethods((items:any) => {
        const oldIndex = items.findIndex((item:any) => item.id === active.id);
        const newIndex = items.findIndex((item:any) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };



  return (
    <Container>
      <DefaultText style={{marginBottom: 6}}>{title}</DefaultText>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={methods} strategy={verticalListSortingStrategy}>
          {methods.map((method:any) => (
            <SortableItem key={method?.id + 1} id={method?.id} label={method?.title} />
          ))}
        </SortableContext>
      </DndContext>
      
    </Container>
  );
};

export default PaymentMethods;
