import React from 'react';
import { ShippingBox, ShippingText } from './styled';
import { Card, Heading, Text } from '../styled';
import { SpaceBetweenContainer} from 'components/UIElements';

const Shipping = ({isCardDesign}:{isCardDesign:boolean}) => {
    const shippingColors:any = {
        'STANDARD':'#f3b627', 
        'EXPRESS':'#0051bf', 
        'RUSH':'#238017'
    }

    const shippingOptions = [
        {
            title: 'STANDARD', 
            edd: 'Thursday, Nov 21', 
            price: 'FREE'
        },
        {   
            title: 'EXPRESS', 
            price: 'FREE',
            edd: 'Thursday, Nov 21'
        },
        {
            title: 'RUSH', 
            price: 'FREE',
            edd: 'Tuesday, Nov 19'
        },
    ]


    return (
        <Card>
            <Heading>Shipping Options</Heading>
            <SpaceBetweenContainer style={{overflowX: 'scroll'}}>
                {shippingOptions?.map((shipping:any, index:number) => {
                    return (
                        <ShippingBox>
                            <ShippingText>
                                {shipping.title}
                            </ShippingText>
                            <Text 
                                weight = {400} 
                                style={{marginBottom: 0, padding: '2px 8px', color: `${index === 0 ? 'var(--checkout-primary-color)' : "#000000"}`}}
                            >
                                {shipping.edd}
                            </Text>
                            <Text style={{fontSize:10, textAlign:'center', marginBottom: 2}}>
                                {shipping.price}
                            </Text>
                        </ShippingBox>
                    )
                })}
            </SpaceBetweenContainer>
        </Card>
    )
}

export default Shipping;