import React, { useContext, useState } from 'react';
import { AppContainer, AppInnerContainer } from './styled';
import Header from './Header';
import OrderSummary from './OrderSummary';
import Coupon from './Coupon';
import Phone from './Phone';
import Footer from './Footer';
import Delivery from './Delivery';
import Shipping from './Shipping';
import Payment from './Payment';
import CouponDrawer from './CouponDrawer';
import { Card } from './styled';
import Device from 'components/CheckoutBuilder/UIComponents/Device';
import CheckoutBanner from './Banner';
import ExitFormDrawer from './ExitFormDrawer';
import { UiConfigContext } from 'context/CustomUIContext';
import AddresDrawer from './AddressDrawer';

const Checkout = ({
        bannerText, 
        behavior, 
        bannerType, 
        showPayment, 
        showShipping, 
        showDelivery, 
        showLogin, 
        showCoupon, 
        showBanner, 
        showExistDrawer, 
        isPreview
    }:any) => {

    const {templateConfig, postLoginBanner} = useContext(UiConfigContext);
    const isCardDesign = templateConfig?.selectedTemplate === "newLayout" ? true : false;
    const [intractiveCheckout, setIntractiveCheckout] = useState({
        previewShowExitForm: false,
        previewShowAddress:false

    })


    const previewBannerConfig = {
        bannerText: isPreview ? postLoginBanner?.bannerText : bannerText, 
        behavior: isPreview ? postLoginBanner?.textTypeSelect : behavior, 
        bannerType: isPreview ? postLoginBanner?.bannerTypeSelect : bannerType
    }


    if(isPreview && postLoginBanner){
        document.documentElement.style.setProperty('--banner-text-color', `${postLoginBanner?.bannerTextColor}`); 
        document.documentElement.style.setProperty('--banner-font-size', `${postLoginBanner?.fontSizeSelect}`); 
        document.documentElement.style.setProperty('--banner-font-weight', `${postLoginBanner?.fontStyleSelect}`); 
    }



    return (
    <Device isPreview = {true}>
        <AppContainer >
            <Header 
                isCardDesign = {isCardDesign}
                setIntractiveCheckout = {setIntractiveCheckout}
                isPreview = {isPreview}

            />
            {showBanner && 
            <CheckoutBanner 
                bannerText = {previewBannerConfig?.bannerText} 
                behavior = {previewBannerConfig?.behavior}
                bannerType = {previewBannerConfig?.bannerType}
            />
        }
            <AppInnerContainer isCardDesign={isCardDesign}>
                <OrderSummary 
                    isCardDesign = {isCardDesign} 
                    showExistDrawer = {showExistDrawer} 
                    isPreview = {isPreview}
                />
                <Card><Coupon isCardDesign = {isCardDesign} /></Card>
                {showLogin && <Phone isCardDesign = {isCardDesign} />}
                {showDelivery && <Delivery isCardDesign = {isCardDesign} setIntractiveCheckout = {setIntractiveCheckout} isPreview = {isPreview}/>}
                {showShipping && <Shipping isCardDesign = {isCardDesign} />}
                {showPayment && <Payment isCardDesign = {isCardDesign} />}
                <Footer />
            </AppInnerContainer>
            {(showExistDrawer || intractiveCheckout?.previewShowExitForm) && 
                <ExitFormDrawer 
                    setIntractiveCheckout = {setIntractiveCheckout}
                    isPreview = {isPreview}
                />}
            {showCoupon && <CouponDrawer />}
            {intractiveCheckout.previewShowAddress && <AddresDrawer isPreview = {isPreview} isCardDesign = {isCardDesign}  setIntractiveCheckout = {setIntractiveCheckout} /> }
        </AppContainer>
    </Device>
    )
}

export default Checkout;