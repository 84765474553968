import React, { useContext } from 'react';
import Button from '../UIComponents/Button';
import { SpaceBetweenContainer, FlexBox } from 'components/UIElements';
import { BackIcon } from 'images/icons';
import { UiConfigContext } from 'context/CustomUIContext';

const Footer = ({handleShowConfirmation, handleBack, currentStep, handleNext, setCurrentStep}:any) => {
    const {editData} = useContext(UiConfigContext);
    return (
        <div>
            {(currentStep === 2) && 
                <FlexBox style={{gap: 20, justifyContent: 'space-between'}}>
                    <FlexBox style={{alignItems:'center', gap: 8}} onClick={() => setCurrentStep(1)}>
                        <BackIcon />
                        <Button type = "secondary" title = "Back"/>
                    </FlexBox>
                    <Button title = {'Next'} onClick = {handleNext} />
                </FlexBox>
            } 

            {(currentStep === 3) &&
                <SpaceBetweenContainer>
                    <FlexBox style={styleButtonWithIconStyle} 
                        // onClick = {() => handleShowConfirmation(true)}
                        onClick={handleBack}
                    >
                        <BackIcon />
                        {/* <Button type = "secondary" title = "Back | Reset to default" /> */}
                        <Button type = "secondary" title = "Back" />
                    </FlexBox>
                    <FlexBox style={{gap: 20}}>
                        {!editData?.active && <Button title = "Save" type = "ghost" onClick = {() => handleShowConfirmation(true)} />}                     
                        <Button title = "Publish" onClick = {() => handleShowConfirmation(false)} />
                    </FlexBox>

                </SpaceBetweenContainer>
            }
        </div>
    )
}

export default Footer;

const styleButtonWithIconStyle = {
    alignItems:'center',
    gap: 8
}
