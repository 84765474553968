const ICONS:any = {
    "CARDS": 'https://fastrr-boost-ui.pickrr.com/assets/images/payment/CardcardIcon.svg',
    "NETBANKING": 'https://fastrr-boost-ui.pickrr.com/assets/images/payment/CardnetbankingIcon.svg', 
    "WALLETS": 'https://fastrr-boost-ui.pickrr.com/assets/images/payment/CardwalletIcon.svg',
    "BNPL": 'https://fastrr-boost-ui.pickrr.com/assets/images/payment/CardbnplIcon.svg', 
    "CREDPAY": 'https://fastrr-boost-ui.pickrr.com/assets/images/payment/CardbnplIcon.svg',
    "SIMPL":'https://fastrr-boost-ui.pickrr.com/assets/images/payment/simplIcon.svg', 
    "SIMPLPI3": 'https://fastrr-boost-ui.pickrr.com/assets/images/payment/simplIcon.svg', 
    "SIMPLPOD":'https://fastrr-boost-ui.pickrr.com/assets/images/payment/simplIcon.svg', 
    "EMI":'https://fastrr-boost-ui-dev.pickrr.com/assets/images/payment/CardbnplIcon.svg',
    'COD': 'https://fastrr-boost-ui-dev.pickrr.com/assets/images/payment/codIconCard.svg'
}


// const METHOD_NAMES:any = {
//     "UPI": "UPI",
//     "CARDS":"Credit/Debit Card",
//     "NETBANKING": "Net Banking",
//     "WALLETS" : "Wallets",
//     "BNPL": "Pay Later",
//     "CREDPAY": "Credpay",
//     "REWARDS": "Rewards/Loyalty programs",
//     "EMI": "Pay with EMI",
//     "BHARATX": "BHARATX", 
//     "POTLEE":"POTLEE",
//     "OTHERS": "Pay via Giftcard"
//   }

// METHOD_NAMES?.[method] ?? 'Others'

const isKeyExist = (arr:any, target:string | number) => {
    return arr?.find((element:any) => element === target);
}


export const gettingPaymentMethods = (paymentMethods:string[]) => {
    const isUPIIncude = isKeyExist(paymentMethods, 'UPI');
    const isCODInclude = isKeyExist(paymentMethods, 'COD');

    let finalPaymentList = paymentMethods;

    if(!(isUPIIncude?.length > 0) && finalPaymentList)  finalPaymentList = [...finalPaymentList, "UPI"];
    if(!(isCODInclude?.length > 0) && finalPaymentList) finalPaymentList = [...finalPaymentList, "COD"];
    

    const paymentMethodsList = finalPaymentList?.map((method:string, index:number) => {
        return {
            title: method,  
            saveAmount:'₹50',
            totalAmount:null, 
            finalAmount:'₹8868.90', 
            paymentMethodIcon:ICONS?.[method] ?? 'https://fastrr-boost-ui.pickrr.com/assets/images/payment/codIconCard.svg', 
            discountIcon:'https://fastrr-boost-ui.pickrr.com/assets/images/svg/discount_icon.svg', 
            showDiscount: false, 
            id: index + 1, 
        }
    })

    return paymentMethodsList;
}


export const createOtherPaymentMethods = (methods:any) => {
   return  methods?.map((method:string) => ({
        title: method?.trim(),
        finalAmount: "₹8845.00",
        paymentMethodIcon:
            "https://fastrr-boost-ui.pickrr.com/assets/images/payment/other.svg",
   }))
}
