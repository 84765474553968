import styled from "styled-components";

interface SliderProps {
  checked: boolean;
}

export const ToggleWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  margin-bottom: 24px;
`;

export const CustomSwitch = styled.div`
  position: relative;
  width: 60px;
  height: 28px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

export const Slider = styled.span<SliderProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ checked }) => (checked ? "#238017" : "#ccc")};
  border-radius: 24px;
  transition: background-color 0.3s ease;

  &::before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    left: ${({ checked }) => (checked ? "32px" : "4px")};
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: left 0.3s ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
