import styled from "styled-components";
import { Modal } from 'antd'

export const CustomModal = styled(Modal)`
    .ant-modal-content{
        border-radius: 10px;
    }

    .ant-steps-item-icon {
        display: none;
    }
`

export const FlexRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const CancelButton = styled.button`
    background: none;
    border: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.07em;
    color: #0C3784;
    cursor: pointer;
`
export const BackButton = styled(CancelButton)`
    margin-right: 20px;
    cursor: pointer;
`

export const NextButton = styled.button`
    background: #0C3784;
    border-radius: 7px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #FFFFFF;
    height: 30px;
    width:110px;
    border: none;
    cursor: pointer;
`

export const Heading = styled.h3`
    margin-top: 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #0C3784;
    margin-bottom: 0;

`

export const SubHeading = styled.h6`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #0C3784;
    margin-top:5px;
    width:698px;
`
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

// Checkbox
export const Checkbox = styled.input`
  width: 16px;
  height: 16px;
`;
export const Divider = styled.hr`
  margin: 20px 0;
  border: 0.5px solid #ddd;
`;


export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const HowItworkText = styled.span`
position: absolute;
  right: 20px; /* Adjust as needed */
  /* top: 10%; */
  margin-top:25px
font-weight: 500;
font-size: 10px;
line-height: 12.47px;
letter-spacing: 0%;
text-decoration: underline;
text-decoration-style: solid;
text-decoration-offset: Auto;
text-decoration-thickness: Auto;
text-decoration-skip-ink: auto;
cursor:pointer;

`;
// Toggle Container
export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  margin-top: 18px;
  border-radius: 6px;
  background: rgba(235, 241, 251, 1)
`;

// Toggle Label (Custom Rule Text)
export const ToggleLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  color:rgba(116, 91, 231, 1)
  transition: color 0.3s ease-in-out;
`;

// A/B Testing Text
export const ABTestingText = styled.span`
  font-size: 12px;
  color: ${(props) => (props.active ? "rgba(116, 91, 231, 1)" : "#ccc")}; /* Blue when ON, Gray when OFF */ 
  font-weight: 500;
`;

// Toggle Switch (Styled)
export const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & span {
    position: absolute;
    width: 34px;
    height: 18px;
    background: ${(props) => (props.disabled ? "#aaa" : props.active ? "rgba(23, 219, 148, 1)" : "#ccc")}; 
    border-radius: 15px;
    transition: background 0.3s ease-in-out;
  }

  & span::after {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    width: 14px;
    height: 14px;
    background: white;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    transform: ${(props) => (props.active ? "translateX(16px)" : "translateX(0)")};
  }
`;


export const TextInput = styled.input`
width: 400px;
height: 38px;
top: 30px;
border-radius: 8px;
border-width: 1px;
gap: 390px;
padding-top: 11px;
padding-right: 14px;
padding-bottom: 11px;
padding-left: 14px;
margin-top:20px;

`;

// Button container to align buttons side by side
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

// General button styling
export const FormButton = styled.button`
width: 165px;
height: 41px;
border-radius: 10px;
  /* Primary button (e.g., Rename) */
  &:first-child {
    background-color: #1677ff;
    color: #fff;
  }
  
  /* Secondary button (e.g., Close) */
  &:last-child {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    margin-left:5px
  }
`;