import styled from "styled-components";

export const FooterContainer = styled.div`
    display: flex;
    justify-content:center;
    gap: 16px;
`

export const FooterIconContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
`

export const FooterIconText = styled.h5`
    font-family: Metropolis;
    font-size: 8px;
    font-weight: 500;
    line-height: 8px;
    text-align: center;
    color: #4E596F;
    margin-top: 4px;
`

export const FooterContainerBottom = styled.div`
    display:flex;
    justify-content:space-between;
    padding: 16px 22px 10px;
`

export const FooterBottomText = styled.span`
    font-family: Metropolis;
    color: #9e9e9e;
    font-size:10px;
`