import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import Stepper from 'components/CheckoutBuilder/Stepper';
import Button from 'components/CheckoutBuilder/UIComponents/Button';
import TemplateSelection from 'components/CheckoutBuilder/TemplateSelection';
import ColorScheme from 'components/CheckoutBuilder/ColorScheme';
import Element from 'components/CheckoutBuilder/Elements';
import { UiConfigContext } from 'context/CustomUIContext';
import Home from 'components/CheckoutBuilder/Home';
import { updateUiConfig } from './script';
import { BackIcon, ToastErrorCross, ToastSuccessTick } from 'images/icons';
import { Container } from '../styled';
import { useMutation } from 'react-query';
import { preparePayload } from './helper';
import ConfirmModal from '../UIComponents/Modals/Confirm';
import SuccessModal from '../UIComponents/Modals/Success';
import SaveSuccessModal from '../UIComponents/Modals/SaveSuccess'
import Footer from '../Footer';
import { FlexBox } from 'components/UIElements';

const Page = () => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showTempleteSettings, setShowTempleteSettings] = useState(false);
    const [isSaveTemplate, setIsSaveTemplate] = useState(false);
    const [errors, setErrors] = useState({templateName:""});
    const [showPreview, setShowPreview] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState();
    
    const editProps = {
        isEdit, 
        setIsEdit,
        editId, 
        setEditId
    }

    const {
        methods, 
        selectedPaymentMethodColor, 
        sellerLogo, 
        preLoginBanner, 
        postLoginBanner, 
        exitReasons, 
        otherPaymentMethods, 
        themeColor, 
        fomoExit, 
        editData, 
        setEditData, 
        headerConfig, 
        templateConfig, 
        isPostLoginBannerSame
    } = useContext(UiConfigContext)


    const init = () => {
        // setTemplateName("")   
        // setEditData("")
        // setCurrentStep(1)
    }


    const mutation = useMutation(updateUiConfig, {
        onMutate: (variables:any) => {
          return { id: 1 };
        },
        onError: (error, variables, context) => {
          // An error happened!
          toast.error('Something went wrong', {
            icon: ToastSuccessTick
        });
          return 
          console.log(`rolling back optimistic update with id ${context.id}`);
        },

        onSuccess: (data, message, variables) => {
            if(data?.data){
            
            setEditData(data?.data)
            // init();
            if(isSaveTemplate && !showConfirmModal){
                setIsSaveTemplate(false)
            }
            setShowConfirmModal(false)
            setShowSuccessModal(true)
            setShowPreview(false)


            // setShowSuccessModal(false) 
            // setIsSaveTemplate(false)

            toast.success('Seller config updated successfully', {
                icon: ToastSuccessTick
            });
        }

        },
      });
    
    const stepHandle = () => {
        if(currentStep === 1 && !templateConfig?.selectedTemplate){
            toast.error('Please select Any Layout', {icon: ToastErrorCross});
            return;
        }else {
            setCurrentStep((prev:number) => prev + 1)
        }
    };

    const formSubmit = async (isActiveTemplate:boolean) => {
         
        try{
        if(!templateConfig?.templateName?.length) {
            setErrors((prev) => (({...prev, templateName: "Enter Template Name"})))
            return
        }else  setErrors((prev) => (({...prev, templateName: ""})))

        if(exitReasons?.selectedReasons?.length < 3 && exitReasons?.enableExitForm){
            toast.error('Select at least 3 exit reasons', {icon: ToastErrorCross});
            return
        }

          const payload = preparePayload(
            methods, 
            selectedPaymentMethodColor, 
            sellerLogo, 
            preLoginBanner, 
            postLoginBanner, 
            exitReasons, 
            otherPaymentMethods, 
            themeColor, 
            fomoExit, 
            editData, 
            isActiveTemplate, 
            headerConfig, 
            templateConfig, 
            isPostLoginBannerSame
        )
         mutation.mutate(payload)

    }catch(error){console.log(error)}

        
        
    }


    const handleNext = () => {
        if(currentStep === 1 && !templateConfig?.selectedTemplate){
            toast.error('Please select Any Layout', {icon: ToastErrorCross});
            return;
        }else {
            window.scrollTo(0, 0);
            setCurrentStep((prev:number) => prev + 1)
        }
    }

    const handleShowTempleteSetting = () => {
        setShowTempleteSettings(true)
    }


    const handleShowConfirmation = (isActive:boolean) => {
        console.log(isActive, 'checking value')
        setIsSaveTemplate(isActive);
        setShowConfirmModal(true);
    }

    const handleBack = () => {
        setCurrentStep(2)
    }


    const currentInit = () => {
        setCurrentStep(1)
    }

    return (
        <>
          {!showTempleteSettings && 
            <Home 
                {...editProps}
                callback = {handleShowTempleteSetting}
                handlePublish = {formSubmit}
                showPreview = {showPreview}
                setShowPreview = {setShowPreview}
            />}

          {showTempleteSettings && 
                <>
                    { currentStep === 1 &&
                        <TemplateSelection 
                            stepHandle = {stepHandle}
                            currentStep = {currentStep} 
                            setCurrentStep = {setCurrentStep}
                            setShowTempleteSettings  = {setShowTempleteSettings}
                        />
                    }
                    {currentStep > 1 && 
                        <Container>
                            <Stepper currentStep={currentStep} setCurrentStep={setCurrentStep} />
                            {currentStep === 2 && <ColorScheme />}
                            {currentStep === 3 && <Element />} 
                            {/* {(currentStep > 1 && currentStep < 3) && 
                                <FlexBox style={{gap: 20}}>
                                    <FlexBox style={{alignItems:'center', gap: 8}} onClick={() => setCurrentStep(1)}>
                                        <BackIcon />
                                        <Button type = "secondary" title = "Back"/>
                                    </FlexBox>
                                    <Button title = {'Next'} onClick = {handleNext} />
                                </FlexBox>
                            } */}
                        </Container>
                    }
                    {currentStep > 1 && 
                    <Footer 
                        handleShowConfirmation = {handleShowConfirmation} 
                        handleBack = {handleBack}
                        currentStep = {currentStep}
                        handleNext = {handleNext}
                        setCurrentStep = {setCurrentStep}
                    />}
                </>
            }

            {(showSuccessModal && isSaveTemplate) && 
                <SaveSuccessModal 
                    setShowSuccessModal = {setShowSuccessModal} 
                    setShowTempleteSettings = {setShowTempleteSettings} 
                    callback = {formSubmit} 
                    loading = {mutation.isLoading}
                    isSaveTemplate = {isSaveTemplate}
                    currentInit = {currentInit}
            />}
            {showConfirmModal && 
                <ConfirmModal 
                    setShowConfirmModal = {setShowConfirmModal} 
                    callback = {formSubmit} 
                    errors = {errors} 
                    loading = {mutation.isLoading} 
                    isSaveTemplate = {isSaveTemplate}
             />}
            {(showSuccessModal && !isSaveTemplate) && 
                <SuccessModal 
                    setShowSuccessModal = {setShowSuccessModal}  
                    setShowTempleteSettings = {setShowTempleteSettings}
                    currentInit = {currentInit}
                />}
            
        </>
      
    )
}
export default Page;