import React from 'react';
import {FooterContainer, FooterIconContainer, FooterIconText, FooterContainerBottom, FooterBottomText} from './styled'
import { FlexBox } from 'components/UIElements';

const Footer = () => {
    const shiprocketLogo = 'https://fastrr-boost-ui.pickrr.com/assets/images/SRLogoDark.png';
    const badges = [
        {
            title: "Secure payments",
            icon: "https://fastrr-boost-ui.pickrr.com/assets/images/securePay.svg",
        },
        {
            title: "Assured delivery",
            icon: "https://fastrr-boost-ui.pickrr.com/assets/images/truckDelivery.svg",
        },
        {
            title: "Verified seller",
            icon: "https://fastrr-boost-ui.pickrr.com/assets/images/verifiedSell.svg",
        },
    ];

    const logoStyle = {
        height: '13px',
        width: '60px',
        marginLeft: '8px'
    }

    return (
        <>
            <FooterContainer>
                {badges?.map((items: any) => {
                    return (
                        <FooterIconContainer>
                            <img src ={items?.icon} />
                            <FooterIconText>{items.title}</FooterIconText>
                        </FooterIconContainer>
                    )
                })}
            </FooterContainer>
            <FooterContainerBottom>
                <FooterBottomText>T&C | Privacy Policy 
                    {/* | fd55f6e2 */}
                    </FooterBottomText>
                <FooterBottomText>
                    <FlexBox>
                        Powered By <img src ={shiprocketLogo} style={logoStyle}/>
                    </FlexBox>
                </FooterBottomText>
            </FooterContainerBottom>
        </>
    )
}

export default Footer;



  