import styled from "styled-components";

export const ColorPickerContainer = styled.div`
    display:flex;
    align-items: flex-start;
    justify-content:space-between;
    gap: 20px;
    width: 460px;
    margin-top: 20px;
`


