import React, {useContext} from 'react';
import { UiConfigContext } from 'context/CustomUIContext';
import { HeaderContainer, LogoContainer } from './styled';
import { MdArrowBackIos } from "react-icons/md";
import { BackMd } from 'images/icons';

const Header = ({isCardDesign, setIntractiveCheckout, isPreview}:{isCardDesign:boolean, isPreview:boolean, setIntractiveCheckout:any}) => {
    const {sellerLogo} = useContext(UiConfigContext);
    
    const openExitForm = () => {
        if(isPreview){
            setIntractiveCheckout((prev:any) => ({...prev, previewShowExitForm:true}))
        }
    }

    return (
        <HeaderContainer>
            <div className='back_icon cursor-pointer' onClick={openExitForm}>
                <MdArrowBackIos />
            </div>
            <LogoContainer>
                <img src={sellerLogo} alt = "brand-logo"  />
            </LogoContainer>
        </HeaderContainer>
    )
}

export default Header;