import { UiConfigContext } from 'context/CustomUIContext';
import React, { useContext, useEffect, useMemo } from 'react';

const usePreview = (isPreview: boolean) => {
  const { themeColor, headerConfig, selectedPaymentMethodColor, postLoginBanner } = useContext(UiConfigContext);

  const configValues = useMemo(() => ({
    logoAlignment: headerConfig?.logoAlignment,
    headerBgColor: headerConfig?.headerBgColor,
    isHeaderGradient: headerConfig?.isHeaderGradient,
    drawerHeaderBgColor: headerConfig?.drawerHeaderBgColor,
    drawerHeaderColor: headerConfig?.drawerHeaderColor,
    headerIconColor: headerConfig?.headerIconColor,
  }), [headerConfig]);

  const themeStyles = useMemo(() => ({
    primaryColor: themeColor.primaryColor,
    inactiveColor: addOpacityToHex(themeColor.primaryColor, 20),
    bgColor: themeColor.bgColor,
  }), [themeColor]);

  const paymentButtonStyles = useMemo(() => ({
    bgColor: selectedPaymentMethodColor.bgColor,
    textColor: selectedPaymentMethodColor.textColor,
    isApplyGradient: selectedPaymentMethodColor.isApplyGradient,
  }), [selectedPaymentMethodColor]);

  const bannerStyles = useMemo(() => ({
    bannerBgColor: postLoginBanner.bannerBgColor,
    bannerTextColor: postLoginBanner.bannerTextColor,
    fontSize: postLoginBanner.fontSizeSelect,
    fontWeight: postLoginBanner.fontStyleSelect,
  }), [postLoginBanner]);

  useEffect(() => {
    if (!isPreview || !headerConfig) return;
    const rootStyle = document.documentElement.style;

    rootStyle.setProperty('--logo-alignment', configValues.logoAlignment);
    rootStyle.setProperty(
      '--header-bg-color',
      configValues.isHeaderGradient
        ? `linear-gradient(to right, ${configValues.headerBgColor}, white)`
        : configValues.headerBgColor
    );
    rootStyle.setProperty('--drawer-bg-color', configValues.drawerHeaderBgColor);
    rootStyle.setProperty('--drawer-text-color', configValues.drawerHeaderColor);
    rootStyle.setProperty('--header-back-icon-color', configValues.headerIconColor);
  }, [isPreview, configValues]);

  useEffect(() => {
    const rootStyle = document.documentElement.style;
    rootStyle.setProperty('--checkout-primary-color', themeStyles.primaryColor);
    rootStyle.setProperty('--checkout-inactive-color', themeStyles.inactiveColor);
    rootStyle.setProperty('--checkout-bg-color', themeStyles.bgColor);
    
  }, [themeStyles]);

  useEffect(() => {
    const rootStyle = document.documentElement.style;
    rootStyle.setProperty(
      '--payment-button-hover-bg-color',
      paymentButtonStyles.isApplyGradient
        ? `linear-gradient(to right, ${paymentButtonStyles.bgColor}, white)`
        : paymentButtonStyles.bgColor
    );
    rootStyle.setProperty('--payment-button-hover-text-color', paymentButtonStyles.textColor);
  }, [paymentButtonStyles]);

  useEffect(() => {
    const rootStyle = document.documentElement.style;
    rootStyle.setProperty('--banner-bg-color', bannerStyles.bannerBgColor);
    rootStyle.setProperty('--banner-text-color', bannerStyles.bannerTextColor);
    rootStyle.setProperty('--banner-font-size', bannerStyles.fontSize);
    rootStyle.setProperty('--banner-font-weight', bannerStyles.fontWeight);
  }, [bannerStyles]);
};

export default usePreview;

function addOpacityToHex(hex: string, opacity: number): string {
  const alpha = Math.round((opacity / 100) * 255).toString(16).padStart(2, '0');
  return `${hex}${alpha}`;
}