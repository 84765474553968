import { SuccessImage } from "images/icons";
import { useQueryClient } from "react-query";
import React, { useContext } from "react";
import {Heading, SubHeading, TextHeadingWithLine, DefaultText } from '../../styled';
import Button from "../Button";
import { DataContext } from "context/DataProvider";
import { UiConfigContext } from "context/CustomUIContext";
import { gettingPaymentMethods } from "components/Checkout/Payment/helper";



const SuccessModal = ({setShowSuccessModal, setShowTempleteSettings, currentInit }: any) => {
    const queryClient = useQueryClient();
    const { sellerConfig} = useContext(DataContext);
    const {
        resetConfig, 
        setThemeColor, 
        setEditData, 
        setTemplateConfig, 
        setSellerLogo, 
        setHeaderConfig, 
        setPreLoginBanner, 
        setPostLoginBanner, 
        setExitResons, 
        setOtherPaymentMethods, 
        setMethods, 
        setSelectedPaymentMethodColor, 
        paymentConfig
    } = useContext(UiConfigContext);
    
    const handleComplete = () => {
        document.querySelector('body').style.overflow = "scroll";
        queryClient.invalidateQueries('getAllTemlates');
        setShowTempleteSettings(false);
        setShowSuccessModal(false)
        currentInit()
        // setEditData({})
        resetConfig()
        
    /* Rest Config */
    //   


    // /* set edit theme color */
    //   setThemeColor((prev:any) => ({
    //     ...prev,
    //     primaryColor: "#3173E3",
    //     inactiveColor: "", 
    //     lightColor: "",
    //     bgColor: "#F9FAFD", 
    //   }))


    //   /* set template name */
    //   setTemplateConfig({
    //     templateName:"",
    //     selectedTemplate:""
    //   })
      
    //   /* set seller logo */
    //   setSellerLogo(sellerConfig?.logo_url)
      
      

    //   /* set header values */
    //   setHeaderConfig({
    //     logoAlignment:"center", 
    //     headerBgColor: "#ffffff", 
    //     headerIconColor: "#333333", 
    //     isHeaderGradient: false, 
    //   })
      
    //   /*set banner details */
    //   setPreLoginBanner({...bannerConfig})
    //   setPostLoginBanner({...bannerConfig})
    

    //   setExitResons({
    //     addCustomReason: false, 
    //     selectType:'single', 
    //     selectedReasons: [], 
    //     enableExitForm: false
    //   })

    //   /* other payment details set */
    //   setOtherPaymentMethods({
    //     isActive: false, 
    //     methods: []
    //   });
      
    //   /* set payment list */
    // //   const paymentMethodsThroughApi = gettingPaymentMethods(editData?.paymentConfig?.otherPaymentMethodsDisplayOrder);
    //   setMethods(paymentConfig)
    
    //   /* set payment color */
    //   setSelectedPaymentMethodColor({
    //     isApplyGradient: false, 
    //     bgColor: "#ffffff", 
    //     textColor:"#101828"
    //   })
    }

    const sellerDomain = sellerConfig?.domain_name?.includes("https") ? sellerConfig?.domain_name : `http://${sellerConfig?.domain_name}`

    return <>
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
            <div className="fixed inset-0 bg-black opacity-50" 
                // onClick={() => { setShowSuccessModal(false) }} 
            />
            <div className="flex flex-col justify-center items-center relative z-50 bg-refundWhiteBg rounded-lg p-10 w-2/5">
               <div className="flex items-center justify-center -mt-5"> <SuccessImage /></div>
               <Heading className="mt-4"> Congratulations </Heading>
               <SubHeading className="mt-2">Your template is published and live on your store</SubHeading>
               <Button type="ghost" title = "Visit Store" onClick={() => window.open(sellerDomain, "_blank")} />
               <TextHeadingWithLine>Keep improving your store</TextHeadingWithLine>
               <div className="flex gap-4 items-center justify-center mb-6">
                    <img src="https://pickrr.s3.amazonaws.com:443/2025-02-28T17:06:54.436463_Instant-information-pana.svg" alt = "info"/>
                    <DefaultText style={{color:"#929292"}}>Save your ideal checkout template and keep enhancing your store. Experiment with different styles, save each version, and return anytime to refine your checkout experience</DefaultText>
               </div>
               <Button title = "Done" onClick = {handleComplete} />
            </div>
            
        </div>
    </>
}
export default SuccessModal;

const bannerConfig:any = {
    bannerBgColor:'#3173E3', 
    bannerTextColor: '#ffffff', 
    bannerText: 'Checkout Banner', 
    bannerTypeSelect: 'singleBanner', 
    textTypeSelect: 'static', 
    fontStyleSelect: '400', 
    fontSizeSelect: '12px'
  }