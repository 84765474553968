import React, { useState, useContext, useEffect } from "react";
import Info from "components/CheckoutBuilder/UIComponents/Info";
import Radio from "components/CheckoutBuilder/UIComponents/Radio";
import {
  bannerTypeOptions,
  textBehaviorOptions,
  fontStyleOptions,
  fontSizeOptions,
} from "./data";
import ColorPicker from "components/CheckoutBuilder/UIComponents/ColorPicker";
import Input from "components/CheckoutBuilder/UIComponents/Input";
import { Section } from "../styled";
import Checkbox from "components/CheckoutBuilder/UIComponents/Checkbox";

const BannerConfig = ({
  bannerConfigData, 
  setBannerConfigData,
  key, 
  isPostLogin, 
  callback, 
  isPostLoginBannerSame
}: any) => {
  
  const [disabledTextBehavior, setDisabledTextBehavior] = useState(false);

  

    useEffect(() => {
      const { bannerBgColor, bannerTextColor, fontSizeSelect, fontStyleSelect, bannerTypeSelect } = bannerConfigData;
      document.documentElement.style.setProperty("--banner-bg-color", bannerBgColor);
      document.documentElement.style.setProperty("--banner-text-color", bannerTextColor);
      document.documentElement.style.setProperty("--banner-font-size", fontSizeSelect);
      document.documentElement.style.setProperty("--banner-font-weight", fontStyleSelect);

      if(bannerTypeSelect === "carouselBanner"){
        setDisabledTextBehavior(true)
        setBannerConfigData((prev:any) => ({...prev, textTypeSelect: "static"}))
      }else {
        setDisabledTextBehavior(false)
      }

    }, [
      bannerConfigData.bannerBgColor, 
      bannerConfigData.bannerTextColor, 
      bannerConfigData.fontSizeSelect, 
      bannerConfigData.fontStyleSelect, 
      bannerConfigData.bannerTypeSelect
    ]);

  const bannerText = (text:string | null | undefined) => {
    const textArray = text?.trim()?.split(",");
    setBannerConfigData((prev:any) => ({...prev, bannerText: textArray}))
  }

  const disabledInputs:any  = (isPostLoginBannerSame && !!isPostLogin)
  
  const preLoginInfoText = "Banner text will be reflected on phone number verification screen";

  const postLoginInfoText = "Banner text will be reflected payment screen";


  return (
    <div key = {key}>
      <Info text={isPostLogin ? postLoginInfoText : preLoginInfoText} />
      {
        isPostLogin && 
          <Section>
            <Checkbox 
                label="Keep post-login banner same as pre-login banner"
                checked={isPostLoginBannerSame}
                onChange={(checked:boolean) => callback(checked)}
              />
          </Section>
       }

      <Radio
        options={bannerTypeOptions}
        selected={bannerConfigData.bannerTypeSelect}
        title="Type"
        onChange={(value:any) => setBannerConfigData((prev:any) => ({...prev, bannerTypeSelect: value}))}
        disabled = {disabledInputs}
      />
      <ColorPicker
        label="Background colour"
        color={bannerConfigData.bannerBgColor}
        onChange={(value:any) => setBannerConfigData((prev:any) => ({...prev, bannerBgColor: value}))}
        disabled = {disabledInputs}
        isPostLoginBannerSame = {isPostLoginBannerSame}
      />
      <ColorPicker
        label="Text colour"
        color={bannerConfigData.bannerTextColor}
        onChange={(value:any) => setBannerConfigData((prev:any) => ({...prev, bannerTextColor: value}))}
        disabled = {disabledInputs}
        isPostLoginBannerSame = {isPostLoginBannerSame} 
      />
      <Section>
        <Input
          title="Text on banner"
          infoText = "Enter comma seprated value for carousel"
          placeholder={bannerConfigData.bannerTypeSelect === "carouselBanner" ? "Enter comma seprated value for carousel" : "Enter Text"}
          value={bannerConfigData.bannerText}
          // onChange={(value:any) => bannerText(value)}
          onChange={(value:any) => setBannerConfigData((prev:any) => ({...prev, bannerText: value}))}
          maxLength = {bannerConfigData.bannerTypeSelect === "carouselBanner" ? 240 : 80}
          disabled = {disabledInputs}
          // setBannerConfigData((prev:any) => ({...prev, bannerText: textArray}))
          // onChange={(value:any) => bannerText(value)}
        />
      </Section>
      <Radio
        options={textBehaviorOptions}
        selected={bannerConfigData.textTypeSelect}
        title="Text behavior"
        onChange={(value:any) => setBannerConfigData((prev:any) => ({...prev, textTypeSelect: value}))}
        disabled = {disabledTextBehavior || disabledInputs}
      />
      <Radio
        options={fontStyleOptions}
        selected={bannerConfigData.fontStyleSelect}
        title="Font-style"
        onChange={(value:any) => setBannerConfigData((prev:any) => ({...prev, fontStyleSelect: value}))}
        disabled = {disabledInputs}
      />
      <Radio
        options={fontSizeOptions}
        selected={bannerConfigData.fontSizeSelect}
        title="Font-size"
        onChange={(value:any) => setBannerConfigData((prev:any) => ({...prev, fontSizeSelect: value}))}
        disabled = {disabledInputs}
      />
    </div>
  );
};

export default BannerConfig;
