import styled from "styled-components";

export const FieldContainer = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap: 20px;

    div {
        margin-top: 0 !important;
        width: 100%;
    }
`

export const AddMoreButton = styled.button`
    display:flex;
    justify-content:center;
    align-items:center;
    border:none;
    outline:none;
    color: var(--primary-color);
    font-weight: 600;
    line-heght: 16px;
    font-size: 14px;
    cursor: pointer;
    gap: 5px;
`