import React from 'react';
import { InfoContainer } from './styled';

const Info = ({text}:any) => {
    const infoIcon = 
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
        <path d="M4.5 9.38867C6.98528 9.38867 9 7.37395 9 4.88867C9 2.40339 6.98528 0.388672 4.5 0.388672C2.01472 0.388672 0 2.40339 0 4.88867C0 7.37395 2.01472 9.38867 4.5 9.38867Z" fill="#305EFF"/>
        <path d="M4.98746 2.42633C4.87506 2.32375 4.72787 2.26772 4.57571 2.26958C4.42261 2.26774 4.27428 2.32279 4.15946 2.42408C4.09855 2.47871 4.05059 2.54625 4.01906 2.62175C3.98754 2.69726 3.97324 2.77885 3.97721 2.86058C3.97885 2.97732 4.01473 3.09102 4.0804 3.18756C4.14608 3.28409 4.23864 3.35923 4.34663 3.40363C4.45461 3.44804 4.57325 3.45977 4.68784 3.43736C4.80243 3.41495 4.90791 3.35939 4.99121 3.27758C5.04767 3.22395 5.0924 3.15921 5.12261 3.08744C5.15281 3.01568 5.16783 2.93843 5.16671 2.86058C5.17037 2.77931 5.15615 2.69824 5.12505 2.62307C5.09395 2.54791 5.04672 2.48049 4.98671 2.42558L4.98746 2.42633ZM5.14196 6.37883L4.99196 4.64033C4.98555 4.46888 4.94767 4.30007 4.88021 4.14233C4.85236 4.08058 4.80673 4.02855 4.74914 3.99287C4.69156 3.9572 4.62465 3.93952 4.55696 3.94208C4.48972 3.93827 4.42293 3.95513 4.36555 3.99039C4.30817 4.02565 4.26295 4.07762 4.23596 4.13933C4.17208 4.30182 4.13461 4.47349 4.12496 4.64783L4.01171 6.33908C3.99071 6.67358 3.97946 6.90683 3.97946 7.05233C3.96952 7.23434 4.03055 7.41312 4.14971 7.55107C4.20737 7.6119 4.27733 7.65974 4.35492 7.69142C4.43251 7.72311 4.51596 7.7379 4.59971 7.73483C4.69222 7.74481 4.7856 7.72685 4.86781 7.68327C4.95002 7.6397 5.0173 7.57249 5.06096 7.49033C5.14318 7.29013 5.18006 7.07421 5.16896 6.85808C5.16938 6.69777 5.16137 6.53754 5.14496 6.37808L5.14196 6.37883Z" fill="white"/>
    </svg>


    return (
        <InfoContainer><span>{infoIcon}</span> {text}</InfoContainer>
    )
}

export default Info;