import React, { useState, useRef, useEffect } from "react";
import {
  DropdownWrapper,
  DropdownHeader,
  DropdownList,
  DropdownItem,
} from "./styled";
import Checkbox from "../Checkbox";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

interface DropdownProps {
  label: string;
  options: Array<{ label: string; value: string, id?:any  }>;
  multiSelect?: boolean;
  maxSelect?: number; // New prop for the maximum limit
  selectedValues: string[];
  onChange: (values: string[]) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  multiSelect = false,
  maxSelect,
  selectedValues,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

    // Close dropdown when clicking outside
  useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);



  const toggleOption = (value: string) => {
    if (multiSelect) {
      const isSelected = selectedValues.includes(value);
      let updatedValues;
  
      if (isSelected) {
        updatedValues = selectedValues.filter((item) => item !== value);
      } else {
        if (maxSelect && selectedValues.length >= maxSelect) {
          return; 
        }
        updatedValues = [...selectedValues, value];
      }
  
      onChange(updatedValues);
    } else {
      onChange(selectedValues.includes(value) ? [] : [value]);
      setIsOpen(false); 
    }
  };

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownHeader onClick={() => setIsOpen(!isOpen)}>
        {label}
        <span>{isOpen ? <MdExpandLess /> : <MdExpandMore />}</span>
      </DropdownHeader>
      {isOpen && (
        <DropdownList>
          {options.map((option) => (
            <DropdownItem key={option.value}>
              <Checkbox
                label={option.label}
                checked={selectedValues.includes(option.value)}
                onChange={() => toggleOption(option.value)}
              />
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
