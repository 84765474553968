import React from 'react';
import { PaymentIcon } from '../styled';

const PaymentMethodIcon = ({paymentMethodDetails}:any) => {
    const {paymentMethodIcon = null, title} = paymentMethodDetails;
    return (
        <PaymentIcon>
            <img src={paymentMethodIcon} alt={title} />
        </PaymentIcon>
    )
}

export default PaymentMethodIcon;