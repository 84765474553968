import React, {useContext, useEffect, useState} from 'react';
import Info from 'components/CheckoutBuilder/UIComponents/Info';
import { DefaultText } from 'components/CheckoutBuilder/styled';
import FileUploader from 'components/CheckoutBuilder/UIComponents/FileUploader';
import Radio from 'components/CheckoutBuilder/UIComponents/Radio';
import ColorPicker from 'components/CheckoutBuilder/UIComponents/ColorPicker';
import Device from 'components/CheckoutBuilder/UIComponents/Device';
import { AccordianLeftSide, Container } from '../../styled';
import Checkout from 'components/Checkout';
import { UiConfigContext } from 'context/CustomUIContext';
import Checkbox from 'components/CheckoutBuilder/UIComponents/Checkbox';
import Tooltip from 'components/CheckoutBuilder/UIComponents/Tooltip';
import { DataContext } from 'context/DataProvider';
// import {headerIconOptions} from '../data';


const MainHeader = () => {
    const {
        // logoAlignment, 
        // setLogoAlignment, 
        // headerBgColor,
        // setHeaderBgColor, 
        // headerIconColor, 
        // setHeaderIconColor, 
        sellerLogo, 
        setSellerLogo,
        // isHeaderGradient, 
        // setIsHeaderGradient, 
        headerConfig, 
        setHeaderConfig, 
        uploadedFile, 
        setUploadedFile, 
        editData
    } = useContext(UiConfigContext)
    const { sellerConfig } = useContext(DataContext);
    const headerIconOptions  = [
        {label: 'Black', value:"#222222"}, 
        {label: 'White', value: "#f1f1f1"}
    ]


    const infoText = 'Your logo will be adjusted to fit within a container with a height of 40 pixels and a width of 150 pixels';
    const radioOptions = [
        {value: 'flex-start', label: 'Left aligned'}, 
        {value: 'center', label: 'Center aligned'}
    ]

    useEffect(() => {
         document.documentElement.style.setProperty('--logo-alignment', headerConfig?.logoAlignment);
    }, [headerConfig?.logoAlignment])

    useEffect(() => {
        if(headerConfig?.isHeaderGradient){
            document.documentElement.style.setProperty('--header-bg-color', `linear-gradient(to right, ${headerConfig?.headerBgColor}, white)`); 
        }else {
            document.documentElement.style.setProperty('--header-bg-color', headerConfig?.headerBgColor);
        }

    }, [headerConfig?.headerBgColor, headerConfig?.isHeaderGradient])

    useEffect(() => {
        if(headerConfig?.headerIconColor){
            document.documentElement.style.setProperty('--header-back-icon-color', headerConfig?.headerIconColor);
            // setIconColor(headerConfig?.headerIconColor)
        }
    }, [headerConfig?.headerIconColor])

    


    return (
        <Container>
        <AccordianLeftSide>
            <Info text = {infoText} />
            <DefaultText>Logo</DefaultText>
            <FileUploader 
                file = {sellerLogo}
                setFile = {setSellerLogo}
                uploadedFile = {uploadedFile}
                setUploadedFile = {setUploadedFile}
                backupFile = {editData?.sellerUi?.logUrl ?? sellerConfig?.logo_url}
            />
            <Radio 
                options = {radioOptions}
                selected={headerConfig?.logoAlignment}
                // onChange={(value) => setLogoAlignment(value)}
                onChange={(value:any) => setHeaderConfig((prev:any) => ({...prev, logoAlignment: value}))}
                title = "Logo placement"
            />        
            <ColorPicker 
                label = {'Header background colour'} 
                infoText = {'The selected color will be applied to the header background'}
                color = {headerConfig?.headerBgColor} 
                onChange={(value:any) => setHeaderConfig((prev:any) => ({...prev, headerBgColor: value}))}
                // onChange={(value:any) => setHeaderBgColor((prev:any) => (value))}
            />
            <Radio 
                options = {headerIconOptions}
                selected={headerConfig?.headerIconColor}
                onChange={(value:any) => setHeaderConfig((prev:any) => ({...prev, headerIconColor: value}))}
                // onChange={(value) => setHeaderIconColor(value)}
                title = "Icon Color"
            />   
            
            <Checkbox 
                label="Apply gradient"
                checked={headerConfig?.isHeaderGradient}
                // onChange={(checked:boolean) => setIsHeaderGradient(checked)}
                onChange={(value:any) => setHeaderConfig((prev:any) => ({...prev, isHeaderGradient: value}))}
                infoText = "Selecting the gradient option will apply a predefined gradient"
            />
            {/* <Tooltip label="" infoText = "Selecting the gradient option will apply a predefined gradient"/> */}
        </AccordianLeftSide>
        <Checkout showLogin = {true}/>
        </Container>
    )
}

export default MainHeader;