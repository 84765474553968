import React, { useEffect, useState, createContext, useContext} from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import './App.css';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import EditOrderNew from "./pages/EditOrderNew"
import ScrollToTop from './ScrollToTop';
import { PrivateRoute } from './routes';
import Settings from 'pages/Settings';
import CustomizeCheckout from 'components/Settings/CustomizeCheckout';
import Abandoned from './pages/Abandoned';
import EddTracker from './pages/EddTracker';
import RtoTable from './pages/Rto';
import { GetAuthToken, getCookieValue, getUserDetails } from 'globalQuries';
import Orders from './pages/Orders/index';
import EditOrder from './pages/EditOrder/index';
import Customers from 'pages/Customers';
import Dashboard from './pages/Home/index';
import FinanceNew from './pages/Finance/FinanceNew';
import Refunds from './pages/Finance/Refunds';
import SettlementHistoryNew from './pages/Finance/SettlementHistory/SettlementHistoryNew';
import Wallet from 'pages/Wallet/index';
import UserData from 'pages/UserData';
import UserMangement from 'pages/UserMangement';
import axios from 'axios';
import { fetchSellerCheckoutConfig } from 'components/Settings/Discount/functions';
import { message, notification } from 'antd';
import { DataProvider, DataContext } from 'context/DataProvider';
import { is_local, local_cookie, setCookie} from 'utils/functions';
import {sessionConfig, defaultRoute, routePermission} from './utils/functions';
import moment from 'moment';
import RefundsNew from 'pages/Finance/Refunds/RefundsTabNew';
import RefundTabs from 'pages/Finance/Refunds/RefundTabs';
import Reports from 'pages/Reports';
import SRCOnBoarding from 'pages/Home/Src_Onboarding';
import ChargeRule from 'pages/ChargeRule/Tabs/index';
import FastrrLoginPage from 'pages/FastrrLogin';
import PendingOrders from 'pages/Orders/PendingOrders';
import CheckoutBuilder from 'pages/CheckoutBuilder';
import CreatePurchaseLinkContainer from 'pages/CheckoutLinks/CreateLink/index';
import CheckoutLinks from 'pages/CheckoutLinks/CreateLink/PurchaseLinks';
import PurchaseLinkOrdersTable from 'pages/CheckoutLinks/CreateLink/purchaseLinkOrders';
import OverViewContainer from 'pages/CheckoutLinks/CreateLink/OverviewContainer';
import LinkSelection from 'pages/CheckoutLinks/CreateLink/DefaultLinkSelectionPage';

   

function App() {
  const urlSearchParams = new URLSearchParams(window.location.search);    
  const uid = urlSearchParams.get('uid');
  const token = urlSearchParams.get('token');
  const userAuthToken = urlSearchParams.get('user-token');

  if(uid){
      const sessionCreate = async() => {
        try{
          const sessionResponse = await axios.get(
            `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/user/create/session`,
              { 
                headers: {
                  "X-AUTH": userAuthToken
                }
              }, 
          )

          sessionConfig(sessionResponse);
          document.cookie = `auth_key=${uid};max-age=604800`;
          localStorage.setItem('auth_token', uid);
          localStorage.setItem("loggedInFromAdmin",'1');
          localStorage.removeItem('userManagementToken');
          localStorage.setItem('userManagementToken', userAuthToken);
          defaultRoute(sessionResponse?.headers?.['permissions'])
          {/*// @ts-ignore */}
          window.location = window.location.href.split("?")[0];
        } catch(error){
          if(error?.response?.data?.message?.includes("User not found")){
            message.error("User not found redirecting to your native place");
            window.location.href = 'https://fastrr-admin.fastrr.com/configuration';
          } else{
            notification.error({message: 'something went wrong'})
          }
        }
      }
      sessionCreate();
  }else {
      localStorage.setItem('auth_token', getCookieValue());
  } 



  const queryCache = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 30000,
      },
    },
  });

  const loggedInFromAdmin = localStorage.getItem("loggedInFromAdmin"); //have to remove this



  return (
    <QueryClientProvider client={queryCache}>
    <Router>
      <DataProvider>
          <PrivateRoute
            path="/checkout-builder"
            element={CheckoutBuilder}
            header={{title: 'Checkout Builder'}}
          />
      
          <PrivateRoute
            path="/charge-rule"
            element={ChargeRule}
            header={{title: 'SmartCOD'}}
          />
        {(routePermission('dashboard') || token) && 
          <PrivateRoute
            path="/"
            element={Dashboard}
            header={{title: ''}}
          />
        } 
        {(routePermission('dashboard') || token) && 
          <PrivateRoute
            path="/fastrrlogin"
            element={FastrrLoginPage}
            header={{title: 'Fastrr login'}}
          />
        } 
      {(true &&  //will check later
        <PrivateRoute
        exact
          path="/home"
          element={SRCOnBoarding} // change to component //check later why route is not exposed
          header={{title: ''}}
        />)}
        {(true &&  //will check later
        <>
        <PrivateRoute
        exact
          path="/purchaseLink/create/:hasItem"
          element={CreatePurchaseLinkContainer} // change to component //check later why route is not exposed
          header={{title: 'Overview'}}
        />
        <PrivateRoute
        exact
          path="/purchaseLink/linkSelection"
          element={LinkSelection} // change to component //check later why route is not exposed
          header={{title: 'Overview'}}
        />
        <PrivateRoute
        exact
          path="/purchaseLink/edit/:id"
          element={CreatePurchaseLinkContainer} // change to component //check later why route is not exposed
          header={{title: 'Overview'}}
        />
        </>)}
         {(true &&  //will check later
        <PrivateRoute
        exact
          path="/purchaseLink/links"
          element={CheckoutLinks} // change to component //check later why route is not exposed
          header={{title: 'Overview'}}
        />)}
        
        {(true &&  //will check later
        <PrivateRoute
        exact
          path={["/purchaseLink/orders","/purchaseLink/abandonedCart"]}
          element={OverViewContainer} // change to component //check later why route is not exposed
          header={{title: 'Overview'}}
        />)}
          <>
          <PrivateRoute
            path="/rto"
            element={RtoTable}
            header={{title: 'RTO Tracker'}}
          />
      {routePermission('finance') && 
        <PrivateRoute
          path="finance"
          element={FinanceNew}
          header={{title: 'Payment Transactions'}}
        />
      }
      {routePermission('dashboard') && 
        <PrivateRoute
          path="reports"
          element={Reports}
          header={{title: 'Reports'}}
        />
      }

      {routePermission('finance') && 
        <PrivateRoute
          path="/refunds"
          element={RefundTabs}
          header={{
            title: 'Refunds',
          }}
        />
      }
      <PrivateRoute
        path="/userdata"
        element={UserData}
        header={{
          title: 'User Data',
        }}
      />

      {routePermission('finance') && 
         <PrivateRoute
          path="/settlementhistory"
          element={SettlementHistoryNew}
          header={{
            title: 'Settlement History',
          }}
        />
      }
      {routePermission('finance') && 
        <PrivateRoute
          path="/wallet"
          element={Wallet}
          header={{title: 'Wallet'}}
        />
      }
        <PrivateRoute
          path="/wallet/callback"
          element={Wallet}
          header={{title: 'Wallet'}}
        />
        <PrivateRoute
          path="/edd-tracker"
          element={EddTracker}
          header={{title: 'EDD Tracker'}}
        />
        </>

        {routePermission('abandoned-cart') && 
          <PrivateRoute
            path="/abandoned-cart"
            element={Abandoned}
            header={{title: 'Abandoned Cart'}}
          />
        }
      {routePermission('settings/general-settings') &&
      <>
        <PrivateRoute
          exact
          path="/settings/discount"
          element={Settings}
          header={{title: 'Settings'}}
        />
        <PrivateRoute
          exact
          path="/settings/shipping"
          element={Settings}
          header={{title: 'Settings'}}
        />
          
        <PrivateRoute
        exact
        path="/settings/communication"
        element={Settings}
        header={{title: 'Settings'}}
      />
      <PrivateRoute
        exact
        path="/settings/customize"
        element={CustomizeCheckout}
        header={{title: 'Settings',}}
      />
        <PrivateRoute
          exact
          path="/settings/payments"
          element={Settings}
          header={{title: 'Settings'}}
        />
        <PrivateRoute
          exact
          path="/settings/inventory"
          element={Settings}
          header={{title: 'Settings'}}
        />
        <PrivateRoute
          exact
          path="/settings/manage-channel/shopify"
          element={Settings}
          header={{title: 'Settings'}}
        />
        <PrivateRoute
          exact
          path="/settings/manage-channel"
          element={Settings}
          header={{title: 'Settings'}}
        />
        <PrivateRoute
          exact
          path="/settings/manage-channel/woocommerce"
          element={Settings}
          header={{title: 'Settings'}}
        />
        <PrivateRoute
          exact
          path="/settings/manage-channel/magento"
          element={Settings}
          header={{title: 'Settings'}}
        />
        <PrivateRoute
          exact
          path="/settings/rto"
          element={Settings}
          header={{
            title: 'Settings',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/settings/edd-tracker"
          element={Settings}
          header={{
            title: 'Settings',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/settings/abandoned-carts"
          element={Settings}
          header={{
            title: 'Settings',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/settings/customers"
          element={Settings}
          header={{
            title: 'Settings',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/settings/general-settings"
          element={Settings}
          header={{
            title: 'Settings',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/settings/webhooks"
          element={Settings}
          header={{
            title: 'Settings',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/settings/customwebhooks"
          element={Settings}
          header={{
            title: 'Settings',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
      </>
      }

        <PrivateRoute
          exact
          path="/customers"
          element={Customers}
          header={{
            title: 'Customers',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/settings/tracking-info"
          element={Settings}
          header={{
            title: 'Settings',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        {routePermission('orders') &&
        <PrivateRoute
          exact
          path="/orders"
          element={Orders}
          header={{
            title: 'Orders',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
      }
      {/* {loggedInFromAdmin && routePermission('orders') &&  */}
      {routePermission('orders') && 
        <PrivateRoute
          exact
          path="/pending-orders"
          element={PendingOrders}
          header={{
            title: 'Actionable Transaction',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
      }
        <PrivateRoute
          exact
          path="/orders/order-details/:id"
          element={EditOrder}
          header={{
            title: 'Orders',
            iconLink:
              'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        {(routePermission('dashboard') || token) && 
          <PrivateRoute
            exact
            path="/dashboard"
            element={Dashboard}
            header={{
              title: 'Dashboard',
              iconLink:
                'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
            }}
        />
      } 
      {routePermission('user-management') && 
        <PrivateRoute
            exact
            path="/user-management"
            element={UserMangement}
            header={{
              title: 'User Management',
              iconLink:
                'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
            }}
        />
       } 
      
    </DataProvider>
    </Router>
    </QueryClientProvider>
  );
}

export default App;