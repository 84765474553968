import styled from "styled-components"

export const ShippingBox = styled.div`
    margin: 12px 4px;
    // padding: 6px 0;
    align-items: center;
    gap: 4px;
    min-width: 135px;
    border-radius: 8px;
    background: #FFF;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border: 0.7px solid var(--checkout-primary-color);
    box-shadow: rgba(159, 159, 159, 0.25) 0px 4px 12px 0px;
`

export const ShippingText = styled.div<{color?:string}>`
    font-weight: 400;
    font-size: 12px;
    width:100%;
    padding: 3px 10px;
    text-align:center;
    color: ${(props:any) => props.color ?? '#38393A'};
    border-bottom: 0.7px solid var(--checkout-primary-color);
    line-height: 12px;
    text-transform: upperCase;
    font-family: Metropolis;
    margin-bottom: 4px;
    display:inline-block;
    margin-right: 4px;

`

