import React from 'react';
import { Card, Heading, Button, Text, Badge } from '../styled';
import { SpaceBetweenContainer, FlexBox } from 'components/UIElements';

const Delivery = ({isCardDesign, setIntractiveCheckout, isPreview}:{isCardDesign:boolean, setIntractiveCheckout:any, isPreview:any}) => {
    const showAddressDrawer = () => {
        if(isPreview){
            setIntractiveCheckout((prev:any) => ({...prev, previewShowAddress:true}))
        }
    }
    
    return (
        <Card>
            <SpaceBetweenContainer>
                <Heading>Delivery</Heading>
                <Button onClick={showAddressDrawer}>Change</Button>
            </SpaceBetweenContainer>
            <FlexBox style={{alignItems:'center'}}>
                <Text style={{marginTop: 10}}>Rizwan</Text>
                <Badge>Home</Badge>
            </FlexBox>
            <Text weight={400}>
                Test 123, south delhi, delhi,
                Test 123, south delhi, delhi, 110095
            </Text>

        </Card>
    )
}

export default Delivery;