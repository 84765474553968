import styled from 'styled-components';
import { Input, DatePicker, TimePicker } from 'antd';

export const Label = styled.h6`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    color: #0C3784;
    margin-right: auto;
`

export const CustomInput = styled(Input)`
    border-radius: 8px;
    border: 2px solid rgba(12, 100, 219, 0.1);
`

export const CustomDatePicker = styled(DatePicker)`
    border-radius: 8px;
    width: 100%;
    border: 2px solid rgba(12, 100, 219, 0.1);
    color: #0C3784;

    .ant-picker-input {
        flex-direction: row-reverse;
        color: #0C3784;
    }

    .ant-picker-suffix {
        margin-right: 10px;
    }
`

export const CustomTimePicker = styled(TimePicker)`
    border-radius: 8px;
    width: 100%;
    border: 2px solid rgba(12, 100, 219, 0.1);

    .ant-picker-input {
        flex-direction: row-reverse;
        color: #0C3784;
    }

    .ant-picker-suffix {
        margin-right: 10px;
    }
`

export const CustomTimeRangePicker = styled(TimePicker.RangePicker)`
    border-radius: 8px;
    width: 100%;
    border: 1px solid #d9d9d9;
`