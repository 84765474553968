import React, { useEffect, useState } from "react";
import {
  FormContainer,
  SubHeading,
  CheckboxText,
  CheckboxDescriptipon,
  FootereBox,
} from "../styled";
import { CustomForm, CustomTable } from "components/UIElements";
import { Row, Col, Form, Input, Checkbox, notification, Select } from "antd";
import { PickrrButton } from "components/UIElements";
import {
  getGeneralDetails,
  updateSellerCheckoutConfig,
  connectedGoogleAccounts,
  connectedGoogleAccountsMeasurementId,
  connectMeasurementIdWithEmail,
  connectedtMeasurementIds,
  pingServerforMids
} from "components/Settings/GeneralSettings/script";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { GA_CONNECT_URL } from "utils/constants";
import SuccessModal from "../../Discount/CreateDiscountModal/successModal";
import { List,Spin } from "antd";

const { Option } = Select;

export const GA4TrackingPage = ({ tabKey }: { tabKey: string }) => {
  const [form] = Form.useForm();
  const queryParameters = new URLSearchParams(window.location.search);

  const _email = queryParameters.get("email");
  const queryClient = useQueryClient();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [googleData, setGoogleData] = useState([]);
  const [googleDataMeasurementid, setGoogleDataMeasurementid] = useState({});
  const [selectedMeasurementid, setSelectedMeasurementid] = useState([]);
  const [connectedMeasurementid, setConnectedMeasurementid] = useState([]);
  const [pollingInterval, setPollingInterval] = useState(null);
  const [isPooling, setIsPooling] = useState(false);
  const { data,refetch:generalSettingsrefetch } = useQuery("generalSettings", getGeneralDetails);

  const mutation = useMutation(updateSellerCheckoutConfig, {
    onError: () => {
      notification.error({ message: "Something went wrong." });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("generalSettings");
      notification.success({ message: "Updated seller details." });
    },
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        measurement_id: data?.marketing_config?.ga4?.measurement_id,
        checkout_initiated:
          data?.marketing_config?.ga4?.events?.checkout_initiated,
        address_selected: data?.marketing_config?.ga4?.events?.address_selected,
        payment_initiated:
          data?.marketing_config?.ga4?.events?.payment_initiated,
        order_placed: data?.marketing_config?.ga4?.events?.order_placed,
      });
    }
    // setLoading(true);
  }, [data, tabKey]);

  const {refetch:connectedGoogleAccountsrefetch}= useQuery(["connectedGoogleAccounts", data?._id], connectedGoogleAccounts, {
    enabled: !!data?._id ,
    onSuccess(data) {
      setGoogleData(data);
    },
  });
    const {refetch:connectedMeasurementidsrefetch}=useQuery(["connectedMeasurementids", data?._id], connectedtMeasurementIds, {
      enabled: !!data?._id && googleData?.length >0,
      onSuccess(data) {
        setConnectedMeasurementid(data);
        // setLoading(false);
      },
    });
  
    useEffect(() => {
      if(tabKey=='2'){
        generalSettingsrefetch();
        connectedGoogleAccountsrefetch();
        connectedMeasurementidsrefetch();
      }
    }, [tabKey]);
  
    function isBlankOrUndefined(obj:any) {
      // Check if the object is undefined or null
      if (obj === undefined || obj === null) {
          return true;
      }
  
      // Check if the object is blank (empty object)
      if (Object.keys(obj).length === 0 && obj.constructor === Object) {
          return true;
      }
  
      // If the object is not blank or undefined, return false
      return false;
  }
      const {refetch:poolingrefetch}=useQuery(
        ["connectedGoogleAccountsMeasurementId", data?._id,_email],
        connectedGoogleAccountsMeasurementId,
        {
          enabled: !!_email && !!data?._id,
          onSuccess(data) {
            // clearInterval(pollingInterval);
            // setIsPooling(false);
            setGoogleDataMeasurementid(data);
          },
          // refetchInterval:query=>isBlankOrUndefined(query) ? 3000 : false
        }
      );

      useEffect(() => {
       if(!!_email && !!data?._id){
        const intervalId = setInterval(() => {
          poolingrefetch();
        }, 5000);
        setIsPooling(true);
        setPollingInterval(intervalId);
       }

       if(!!_email){
        let _is_exist = localStorage.getItem(_email);
        if(!_is_exist){
          setIsSuccessModalOpen(true);
          localStorage.setItem(_email,_email);
        }
       
       }
      }, [_email,data?._id]);

      useEffect(() => {
        if(pollingInterval){
          setTimeout(() => {
            clearInterval(pollingInterval);
            setIsPooling(false);
            setPollingInterval(null);
            notification.error({ message: "Taking time, Please refresh after sometime" });
          }, 300000); 
        }
      }, [pollingInterval]);
    
      useEffect(() => {
       if(!isBlankOrUndefined(googleDataMeasurementid)){
        clearInterval(pollingInterval);
        setIsPooling(false);
        setPollingInterval(null);
        connectedMeasurementidsrefetch();
       }
      }, [googleDataMeasurementid])
      

    useQuery(
      ["pingServerforMids", data?._id,_email],
      pingServerforMids,
      {
        enabled: (!!_email && !!data?._id && isSuccessModalOpen),
        onSuccess(data) {
          // setGoogleDataMeasurementid(data);
          return true
        },
      }
    );

  const submitForm = () => {
    const postData = { ...form.getFieldsValue() };
    mutation.mutate({
      marketing_config: {
        ...data?.marketing_config,
        ga4: {
          measurement_id: postData.measurement_id,
          events: {
            checkout_initiated: postData.checkout_initiated,
            address_selected: postData.address_selected,
            payment_initiated: postData.payment_initiated,
            order_placed: postData.order_placed,
          },
        },
      },
    });
  };

  const handleMesurementidValue = (event: any) => {
    setSelectedMeasurementid(event);
  };

  const gaMeasurement = useMutation(connectMeasurementIdWithEmail, {
    onMutate: (variables) => {
      setLoading(true);
      // A mutation is about to happen!
      // Optionally return a context containing data to use.
      return true;
    },
    onError: (error, variables, context) => {
      // An error happened!
      notification.error({ message: "Failed." });
      setLoading(false);

    },
    // onSuccess: (message, variables, data) => {
    //   notification.success({ message: "Updated Suggested Discounts details." });
    // },
  });
  const connectToMeasurementid = (email: any = _email) => {
    const data_ = { sellerId: data?._id, email: email };
    selectedMeasurementid.map((id) => {
      gaMeasurement.mutate(
        { data: data_, measurement_id: id, url_for: "connect" },
        {
          onSuccess: (data) => {
            {data.msg && notification.success({
              message: data.msg ,
            })}
            {data.error && notification.error({
              message: data.error,
            })}
            setLoading(false);
            connectedMeasurementidsrefetch();
          },
          onError(error, variables, context) {
            setSelectedMeasurementid((prop: any) => {
              let _temp = prop;
              delete _temp[id];
             return _temp;
            });
          },
        }
      );
    });

    setGoogleDataMeasurementid((prop: any) => {
      let _temp = prop;
      selectedMeasurementid.map(_=>{
        delete _temp[_]
      });
      return _temp;
    });
    setSelectedMeasurementid([]);

  };

  const connectToMeasurementIdByTable = (table_data: any) => {
    const data_ = { sellerId: data?._id, email: table_data.gaEmail };
    gaMeasurement.mutate(
      {
        data: data_,
        measurement_id: table_data.mid,
        url_for: table_data.connected ? "disconnect" : "connect",
      },
      {
        onSuccess: (data) => {
          {data.msg && notification.success({
            message: data.msg,
          })}
          {data.error && notification.error({
            message: data.error,
          })}
          setLoading(false);

          setConnectedMeasurementid((_tem) => {
            _tem = _tem.map((_d) =>
              _d.mid === table_data.mid && _d.gaEmail === table_data.gaEmail
                ? { ..._d, connected: !_d.connected }
                : _d
            );
            return _tem;
          });
        },
      }
    );
  };

  const streamMeasurementIdByTable = (table_data: any, e: any) => {
    const data_ = { sellerId: data?._id, email: table_data.gaEmail };
    gaMeasurement.mutate(
      {
        data: data_,
        measurement_id: table_data.mid,
        url_for: e == "primary" ? "primary" : "secondary",
      },
      {
        onSuccess: (data) => {
          {data.msg && notification.success({
            message: data.msg ,
          })}
          {data.error && notification.error({
            message: data.error,
          })}
          setLoading(false);
          // setConnectedMeasurementid((_tem) => {
          //   _tem = _tem.map((_d) =>
          //     _d.mid === table_data.mid && _d.gaEmail === table_data.gaEmail
          //       ? { ..._d, primary: !_d.primary }
          //       : _d
          //   );
          //   return _tem;
          // });
          connectedMeasurementidsrefetch();

        },
        onError(error, variables, context) {
          setLoading(false);
          // notification.error({ message: "Failed." });
          // connectedMeasurementidsrefetch();
          setConnectedMeasurementid((_tem) => {
            _tem = _tem.map((_d) =>
              _d.mid === table_data.mid && _d.gaEmail === table_data.gaEmail
                ? { ..._d, primary: false }
                : {..._d}
            );
            return _tem;
          });
        },
      }
    );
  };

  const disconnectGaMail = (mail:any) => {
    const data_ = { sellerId: data?._id };
    // alert(mail);
    // selectedMeasurementid.map((id) => {
      gaMeasurement.mutate(
        { data: data_, measurement_id: mail, url_for: "disconnect_mail" },
        {
          onSuccess: (data) => {
            setLoading(false);
            connectedMeasurementidsrefetch();
            localStorage.removeItem(mail);
            {data.msg && notification.success({
              message: data.msg ,
            })}
            {data.error && notification.error({
              message: data.error,
            })}
            setGoogleData((prop: any) => {
              let _temp = prop.filter((_a:any)=>_a!=mail)
              return _temp;
            });
          },
        }
      );
    // });
  };

  const column: any = [
    {
      title: "Measurement Id ",
      dataIndex: "mid",
      key: "mid",
      width: 300,
      align: "center",
      render: (data: any) => (
        <span style={{ textTransform: "capitalize" }}>{data}</span>
      ),
    },
    {
      title: "Stream type ",
      //   dataIndex: "primary",
      //   key: "primary",
      width: 300,
      align: "center",
      render: (data: any) => (
        <Select
          style={{ width: "300px" }}
          placeholder="Stream type "
          //   mode="multiple"
          onChange={(e) => streamMeasurementIdByTable(data, e)}
          defaultValue={data.primary ? "primary" : "secondary"}
          value={data.primary ? "primary" : "secondary"}
        >
          <Option value={"primary"}>Primary</Option>
          <Option value={"secondary"}>Secondary</Option>
        </Select>
      ),
    },
    {
      title: "Connected Google Account ",
      dataIndex: "gaEmail",
      key: "gaEmail",
      width: 300,
      align: "center",
      render: (data: any) => (
        <span style={{ textTransform: "capitalize" }}>{data}</span>
      ),
    },
    {
      title: "Status",
      //   dataIndex: "connected",
      //   key: "connected",
      width: 300,
      align: "center",
      render: (data: any) => (
        <PickrrButton onClick={() => connectToMeasurementIdByTable(data)}>
          {data.connected ? "Disconnect" : "Connect"}
        </PickrrButton>
      ),
    },
  ];
  return (
    <Spin tip="Loading Measurement ID's" size="small" spinning={isPooling}>
    <FormContainer>
      {/* start */}
      <Row gutter={[50, 0]}>
        
        {/* <>
          <Col span={24}>
            <SubHeading>
              Google Account Integration<br></br>
              <small>
                For better tracking and accuracy and visibility of advanced
                analytics
              </small>
            </SubHeading>
          </Col>
          <Col span={12} style={{ paddingBottom: "15px" }}>
            <List
             footer={
            <p><b>Disclosure:</b> Shiprocket Checkout use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
}
              header={
                <List.Item
                  actions={[
                    googleData?.length == 0 && (
                      <PickrrButton
                        color="checkout-blue"
                        onClick={() => {
                          window.open(`${GA_CONNECT_URL}${data?._id}`);
                        }}
                      >
                        Connect
                      </PickrrButton>
                    ),
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <img 
                        src="https://banner2.cleanpng.com/20201008/rtv/transparent-google-suite-icon-google-icon-5f7f985ccd60e3.5687494416021975968412.jpg" 
                        height="25px"
                        style={{height: 25}}
                    />}
                    title={
                      <span>
                        {googleData?.length == 0
                          ? "Connect your Google Account"
                          : "Google Account"}
                      </span>
                    }
                  />
                </List.Item>
              }
              // footer={
              //   googleData?.length !== 0 && !isPooling && (
              //     <FootereBox
              //       onClick={() => {
              //         window.open(`${GA_CONNECT_URL}${data?._id}`);
              //       }}
              //     >
              //       + Connect another account
              //     </FootereBox>
              //   )
              // }
              itemLayout="horizontal"
              dataSource={googleData}
              renderItem={(item: string, index) => (
                <List.Item
                  actions={[
                    <PickrrButton color="checkout-blue" onClick={() => {disconnectGaMail(item)}} disabled={loading}>
                      DisConnect
                    </PickrrButton>,
                  ]}
                >
                  <List.Item.Meta style={{ marginLeft: 40 }} title={item} />
                </List.Item>
              )}
            />
          </Col>
        </> */}
        
        {/* end */}
        
        {/* {(!_email  || googleData?.length != 0) && <Col span={10} style={{ alignSelf: "self-end", marginBottom: 20 }}>
          <label>Connect additional Measurement IDs </label>
          <Form.Item name="measurement_id">
            <Input />
          </Form.Item>
        </Col>} */}
        {/* {_email && googleData?.length > 0 && (
          <Col span={24} style={{ paddingBottom: "15px", paddingTop: "15px" }}>
            <Select
              style={{ width: "300px" }}
              placeholder="Measurement Id's"
              mode="multiple"
              value={selectedMeasurementid}
              onChange={handleMesurementidValue}
            >
              {googleDataMeasurementid &&
                Object.keys(googleDataMeasurementid)?.map((keys) => (
                  <Option value={keys}>{keys}</Option>
                ))}
            </Select>
            {selectedMeasurementid?.length !== 0 && (
              <PickrrButton
                color="checkout-blue"
                onClick={() => {
                  connectToMeasurementid();
                }}
              >
                Connect
              </PickrrButton>
            )}
          </Col>
        )} */}
        {(connectedMeasurementid?.length > 0) &&(
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ borderRadius: 12 }}
          >
            <CustomTable
              columns={column}
              dataSource={connectedMeasurementid}
              loading={loading}
              pagination={{
                pageSize: 20,
                showSizeChanger: false,
                // total: tableData?.total,
                // current: pageNumber,
                // onChange: (page) => {
                //   setPageNumber(page);
                // },
                position: ["bottomRight"],
              }}
            />
          </Col>
        )}

        <CustomForm layout="vertical" onFinish={submitForm} form={form}>
          <>
            <Col span={10}>
                    <Form.Item
                        label="Measurement ID"
                        name="measurement_id"
                        rules={[
                            {
                              required: true,
                              message: 'This field is required.',
                            },
                          ]}
                        >
                        <Input />
                    </Form.Item>
                    </Col>
            <Col span={24}>
              <SubHeading>
                Please select the Events you want to trigger :
              </SubHeading>
            </Col>
            <Col span={24}>
              <Form.Item name="checkout_initiated" valuePropName="checked">
                <Checkbox>
                  <CheckboxText>Checkout Initiated</CheckboxText>
                </Checkbox>
              </Form.Item>
              <CheckboxDescriptipon>
                This event is triggered when the checkout pop-up opens. It is
                the standard GA4 event for tracking ecommerce - begin_checkout.
              </CheckboxDescriptipon>
            </Col>
            <Col span={24}>
              <Form.Item name="address_selected" valuePropName="checked">
                <Checkbox>
                  <CheckboxText>Add Shipping Info</CheckboxText>
                </Checkbox>
              </Form.Item>
              <CheckboxDescriptipon>
                This event is triggered when the user completes the shipping
                stage. It is the standard GA4 event for tracking ecommerce -
                add_shipping_info.
              </CheckboxDescriptipon>
            </Col>
            <Col span={24}>
              <Form.Item name="payment_initiated" valuePropName="checked">
                <Checkbox>
                  <CheckboxText>Add Payment Info</CheckboxText>
                </Checkbox>
              </Form.Item>
              <CheckboxDescriptipon>
                This event is triggered when the user reaches payment stage. It
                is the standard GA4 event for tracking ecommerce -
                add_payment_info.
              </CheckboxDescriptipon>
            </Col>
            <Col span={24}>
              <Form.Item name="order_placed" valuePropName="checked">
                <Checkbox>
                  <CheckboxText>Purchase</CheckboxText>
                </Checkbox>
              </Form.Item>
              <CheckboxDescriptipon>
                This event is triggered when the order is placed. It is the
                standard GA4 event for tracking ecommerce - purchase.
              </CheckboxDescriptipon>
            </Col>
            <Col
              span={24}
              style={{ paddingBottom: "15px", paddingTop: "15px" }}
            >
              <PickrrButton htmlType="submit" color="checkout-blue">
                Save
              </PickrrButton>
            </Col>
          </>
        </CustomForm>
      </Row>
      <SuccessModal
        isSuccessModalOpen={isSuccessModalOpen}
        setIsSuccessModalOpen={setIsSuccessModalOpen}
        main_heading={"Google Account connected successfully."}
        sub_heading={"You can disconnect it anytime"}
      />
    </FormContainer>
    </Spin>
  );
};

export default GA4TrackingPage;
