import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
`

export const ContentContainer = styled.div`
  background: #fff;
  border-radius: 0px 0px 12px 12px;
  padding: 24px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #ebedf7 !important;
    color: #263f97;
  }
  .ant-select-selector {
    background: #ebedf7 !important;
    color: #263f97;
    border-color: #ebedf7 !important;
    font-size: 12px;
  }
  .ant-select-arrow {
    color: #263f97;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector::after,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    color: #263f97;
  }

  .ant-divider-horizontal.ant-divider-with-text {
    margin: 8px 0 !important;
    border-top-color: #7e8fcc;
  }
  .ant-divider-inner-text {
    color: #263f97;
  }

  textarea.ant-input {
    background: #edf0f9;
    border-radius: 10px;
    border-color: #edf0f9;
  }
  .ant-input-placeholder {
    color: red;
  }
`;

export const ModalHeader = styled.div`
  background: #f9fafe;
  border-radius: 12px 12px 0px 0px;
  height: 76px;
  width: 100%;
  padding: 24px;
`;

export const ModalFooter = styled.div`
  background: #f9fafe;
  border-radius: 0px 0px 12px 12px;
  height: 76px;
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: flex-end;
`;

export const SubText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #263f97;
  opacity: 0.6;
`;

export const MenuLinks = styled.span`
  font-style: normal;
  font-size: 16px;
  line-height: 140%;
  color: #263f97;
  margin-bottom: 24px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.checked === 'true' ? '3px solid #0C64DB' : 'none'};
  font-weight: ${(props) => (props.checked === 'true' ? 'bold' : 'normal')};
`;

export const ServiceTypeContainer = styled.div`
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #263f97;
    border-color: #263f97;
  }
  .ant-radio-button-wrapper {
    color: #263f97;
    font-size: 14px;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: #263f97;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 10px 0 0 10px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 10px 10px 0;
  }
`;

export const CheckBox = styled.div`
  width: 92px;
  height: 50px;
  background: ${(props) => (props.checked === 'true' ? '#263f97' : '#EDF0F9')};
  color: ${(props) => (props.checked === 'true' ? '#fff' : '#263f97')};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #263f97;
  cursor: pointer;
`;

export const WeightRangeContainer = styled.div`
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #edf0f9;
    border-color: #edf0f9;
    font-size: 14px;
    line-height: 140%;
    color: #263f97;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #c6c8e3;
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: #ff4d4f;
`;

export const RiskProfileHeading = styled.h6`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #0C3784;
  margin-bottom: 20px;
`

export const RiskParameterSubheading = styled.div`
  font-weight: 600;
  font-size:12px;
  color: #0C3784;
  line-height: 16px; 
  margin: 10px 0;
`

export const RadioButtonGroup = styled.div`
  margin-top: 12px;
  .ant-checkbox-group{
    .ant-checkbox-wrapper.ant-checkbox-group-item{
      padding-bottom: 8px;
    }
    display:flex;
    flex-direction: column;
  }
`
export const InfoContainer = styled.div`
background: #FFFCEB; 
padding: 4px;
margin-bottom: 16px;
color: black;
display: flex;
align-items: center;
border-radius: 4px;
border: 1px solid #F4CF00;
`