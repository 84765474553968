import styled from "styled-components";

export const PhoneContainer = styled.div`
    text-align:center;
    display:flex;
    flex-direction:column;
    align-items:center;
    padding: 16px;
    margin: 8px 0px;
    height: 310px
`
export const PhoneInputContainer = styled.div`
    padding: 20px;
    width: 100%;
    position:relative;
`

export const IconContainer = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
    display:flex;
    justify-content:center;
    align-items:center;
    gap: 6px;
    border-right: 1px solid var(--checkout-inactive-color);
    padding-right: 2px;
`

