import React, { useState, useContext, useEffect } from "react";
import Fomo from "./Fomo";
import Dropdown from "../../../UIComponents/Dropdown";
import Radio from "../../../UIComponents/Radio";
import { AccordianLeftSide, Container } from "../../styled";
import Checkout from "components/Checkout";
import { exitReasonsOptions, exitFormSelectionType } from "./data";
import { UiConfigContext } from "context/CustomUIContext";
import Button from "components/CheckoutBuilder/UIComponents/Button";
import DynamicFormFields from "components/CheckoutBuilder/UIComponents/DynamicFormFields/DynamicFormFields";
import Info from "components/CheckoutBuilder/UIComponents/Info";
import { Text } from "components/CheckoutBuilder/styled";
import Switch from '../../../UIComponents/Switch';
import { exit } from "process";

const ExitForm = () => {
  const {
    exitReasons,
    setExitResons,
    exitFormCustomReasons,
    setExitFormCustomReasons,
    fomoExit
  } = useContext(UiConfigContext);

  const [allReasons, setAllReasons] = useState<any>([]);

  // useEffect(() => {
  //   let customReasons: any = [];
  //   if (exitFormCustomReasons?.length > 0) {
  //     customReasons = exitFormCustomReasons
  //       ?.filter((reason: any) => reason.value)
  //       .map((reason: any) => ({
  //         ...reason,
  //         label: reason.value,
  //         value: reason.value,
  //       }));
  //   } else {
  //     customReasons = [];
  //   }

  //   setAllReasons([...customReasons, ...exitReasonsOptions]);
  // }, [exitFormCustomReasons]);


  // useEffect(() => {
  //   if(exitReasons.selectType === "single"){
  //     const reasonsArrays = exitReasons.selectedReasons?.map((reason: string) => ({
  //         label: reason,
  //         value: reason,
  //       }));

  //     setReasonsArray(reasonsArrays)
  //     console.log(reasonsArrays, 'checking values')
      

  //   }
  // }, [exitReasons.selectedReasons])





  // exitReasons?.selectedReasons
  // exitReasons

  /* edit case */


const removeDuplicates = (options:any) => {
  const unique:any = [];
  const seen = new Set();

  options.forEach((option:any) => {
      if (!seen.has(option.value)) {
          seen.add(option.value);
          unique.push(option);
      }
  });

  return unique.sort((a:any, b:any) => 
      a.value === "Others (please specify)" ? 1 : b.value === "Others (please specify)" ? -1 : 0
  );
};

  useEffect(() => {
    let customReasons: any = [];
    if (exitFormCustomReasons?.length > 0) {
      customReasons = exitFormCustomReasons
        ?.filter((reason: any) => reason.value)
        .map((reason: any) => ({
          ...reason,
          label: reason.value,
          value: reason.value,
        }));
    } else {
      customReasons = [];
    }


    if(exitReasons?.selectedReasons?.length){
      const newReasons = exitReasons?.selectedReasons?.map((reason:any) => ({
        label: reason, 
        value: reason
      }));

      const allReasons:any = [...newReasons, ...exitReasonsOptions, ...customReasons];
      const result = removeDuplicates(allReasons);
      setAllReasons([...result])
    }
  }, [exitFormCustomReasons])



  return (
    <Container>
      <AccordianLeftSide>
        <Fomo />
        <Switch 
          label="Enable Exit Form"
          checked={exitReasons.enableExitForm}
          onChange={(toggled) => {setExitResons((prev:any) => ({...prev, enableExitForm: toggled}))}}
        />
        {exitReasons.enableExitForm ?
        <>
          <Text>Exit form reasons ({exitReasons.selectedReasons.length}/5)</Text>
          <Info text="You can add minimum of 3 and maximum of 5 Exit form reasons" />
          <Dropdown
            label="Select exit form reasons"
            options={allReasons}
            multiSelect
            selectedValues={exitReasons.selectedReasons}
            maxSelect={5}
            onChange={(reason) =>
              setExitResons((prev: any) => ({ ...prev, selectedReasons: reason }))
            }
          />
          <Radio
            title="Selection type"
            options={exitFormSelectionType}
            selected={exitReasons.selectType}
            onChange={(value) => {
              setExitResons((prev: any) => ({ ...prev, selectType: value }));
            }}
          />
          <Button
            type="link"
            title="Add custom reason"
            onClick={() =>
              setExitResons((prev: any) => ({
                ...prev,
                addCustomReason: !prev.addCustomReason,
              }))
            }
          />
          {exitReasons.addCustomReason ? (
            <DynamicFormFields
              fields={exitFormCustomReasons}
              setFields={setExitFormCustomReasons}
            />
          ) : (
            ""
          )}
        </>
        : "" }
      </AccordianLeftSide>
      <Checkout 
        showExistDrawer = {exitReasons.enableExitForm || fomoExit.isActive} 
        showShipping ={true} 
        showDelivery = {true}
        showPayment = {true}
      />
    </Container>
  );
};

export default ExitForm;
