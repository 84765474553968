import styled from "styled-components";

export const ColorBoxContainer = styled.div`
    display:flex;
    align-items:center;
    flex-direction: column;
`

export const ColorBox = styled.div<{bgColor?:string}>`
    width: 60px;
    height: 30px;
    border-radius: 2px;
    border: .3px solid var(--grey-color);
    background: ${(props:any) => props.bgColor ?? 'transparent'}
`

export const ColorBoxHeading = styled.h5`
    color:var(--grey-color);
    font-size:12px;
    font-weight:500;
    line-height:14px;
    margin-bottom: 6px;
`