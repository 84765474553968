import styled from "styled-components";

export const DropdownWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  font-family: Arial, sans-serif;
  margin: 16px 0;
  box-shadow: 0px 4px 16px 0px #0000000A;
`;

export const DropdownHeader = styled.div`
  padding: 12px 16px;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #333;
`;

export const DropdownList = styled.ul`
  margin: 0;
  padding: 8px 0;
  list-style: none;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 8px 8px;
  background: white;
  max-height: 200px;
  overflow-y: auto;
`;

export const DropdownItem = styled.label`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  color: #333;
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }
`;

export const Checkbox = styled.input`
  margin-right: 8px;
  accent-color: #4caf50;
  cursor: pointer;
`;