import Header from "components/CommonComponents/Header";
import React, { useState, useContext, useEffect } from "react";
import { AddButton, BackButton, Container, CustomForm, RadioHeading, TableDataContainer, TableHeading } from "./style";
import { useQuery , useQueryClient} from 'react-query';
import { Table, Col, Row, Form, Input, Space, Select, notification,Switch, Radio, InputNumber } from "antd";
import { PickrrButton } from "components/UIElements";
import { LeftOutlined } from '@ant-design/icons';
import { fetchWebhookList } from "./script";
import { DataContext } from 'context/DataProvider';
import axios from "axios";

const { Option } = Select;
const WebHooks = () =>{

    const {sellerId} = useContext(DataContext) 

    const queryClient = useQueryClient();
    
    const {data: webhookListData, isLoading } = useQuery(
        ['WebhookList', {sellerId}],
        fetchWebhookList,
        {
            enabled: !!sellerId
        }
    );

    const [addWebHook, setAddWebHook] = useState(false)
    const [webhookType, setWebhookType] = useState("RealTime")
    const [editWebhookData,setEditWebhookData] = useState({})
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const [switchLoading, setSwitchLoading]= useState(null)
    const [abandonedServiceProvider,setAbandonedServiceProvider]=useState('Custom')
    const [form] = Form.useForm();

    const handleActiveChange = async(value, record,index) => {
        setSwitchLoading(index)
        const postData = {
            "id": record?.id,
            "apiEndPoint": record?.apiEndPoint,
            "sellerId": sellerId,
            "active": value,
            "type": record?.type
        }
        try{
            const response = await axios.put(
                `${process.env.REACT_APP_AGGREGATOR}/api/ve1/aggregator-service/seller/webhook`,
                postData
            )
            if(response.status !== 200){
                notification.error({
                    message: "something went wrong",
                    });
            }
            await queryClient.invalidateQueries('WebhookList');
            setSwitchLoading(null)
        } catch (error) {
        notification.error({
        message: error?.response?.data?.message,
        });
        await queryClient.invalidateQueries('WebhookList');
        setSwitchLoading(null)
        }   
    }

    useEffect(() => {
        if(Object.keys(editWebhookData).length === 0) {
          form.setFieldsValue({
            type : "RealTime",
          });
          setWebhookType("RealTime");
        } else {
   
          let adjustedHeaders = editWebhookData?.header || [];

          if (
            editWebhookData?.abandonServiceProvider?.toLowerCase() === "interakt" ||
            editWebhookData?.serviceProvider?.toLowerCase() === "interakt" 
          ) {
            adjustedHeaders = adjustedHeaders.map((adjheader) => ({
              ...adjheader,
              value: adjheader.value?.replace(/^Basic\s+/i, ""), 
            }));
          }
          if (
            editWebhookData?.abandonServiceProvider?.toLowerCase() === "webengage" ||
            editWebhookData?.serviceProvider?.toLowerCase() === "webengage" || editWebhookData?.abandonServiceProvider?.toLowerCase() === "spurnow" ||
            editWebhookData?.serviceProvider?.toLowerCase() === "spurnow" 
          ) {
            adjustedHeaders = adjustedHeaders.map((adjheader) => ({
              ...adjheader,
              value: adjheader.value?.replace(/^Bearer\s+/i, ""), 
            }));
          }
          form.setFieldsValue({
            apiEndPoint: editWebhookData?.apiEndPoint,
            header: adjustedHeaders,
            stage: editWebhookData?.stage,
            abandonServiceProvider: editWebhookData?.abandonServiceProvider || "Custom",
            serviceProvider: editWebhookData?.serviceProvider,
            type : editWebhookData?.type,
            extra : editWebhookData?.extra?.length ? editWebhookData.extra : [{}],
            time : editWebhookData?.time,
          });
          setWebhookType(editWebhookData.type);
          setAbandonedServiceProvider(editWebhookData.abandonServiceProvider || "Custom")
        }
      }, [editWebhookData, webhookListData?.length]);
      
      const submitForm = async (values) => {
        setIsSubmitLoading(true);
      
        let adjustedHeaders = values?.header || [];
      
        if (values?.abandonServiceProvider === "Interakt" || values?.abandonServiceProvider === "Bikayi" ) {
          adjustedHeaders = adjustedHeaders.map((item) => {
            if (item.value && !/^Basic\s+/i.test(item.value)) {
              return { ...item, value: `Basic ${item.value}` };
            }
            return item;
          });
        }

        if (values?.abandonServiceProvider === "Webengage" || values?.abandonServiceProvider === "Spurnow" ) {
          adjustedHeaders = adjustedHeaders.map((item) => {
            if (item.value && !/^Bearer\s+/i.test(item.value)) {
              return { ...item, value: `Bearer ${item.value}` };
            }
            return item;
          });
        }
      
        let postData =
          webhookType === "RealTime"
            ? {
                apiEndPoint: values?.apiEndPoint,
                header: adjustedHeaders,
                stage: values?.stage,
                extra: values?.extra?.[0]?.key ? values?.extra : [],
                serviceProvider: values?.serviceProvider,
                sellerId: sellerId,
                type: values?.type,
              }
            : {
                apiEndPoint: values?.apiEndPoint,
                time: values?.time,
                header: adjustedHeaders,
                serviceProvider: values?.serviceProvider,
                extra: values?.extra?.[0]?.key ? values?.extra : [],
                sellerId: sellerId,
                type: values?.type,
                abandonServiceProvider: values?.abandonServiceProvider,
              };
      
        if (Object.keys(editWebhookData).length !== 0) {
          postData = {
            ...postData,
            id: editWebhookData?.id,
          };
        }
      
        try {
          const response =
            Object.keys(editWebhookData).length === 0
              ? await axios.post(
                  `${process.env.REACT_APP_AGGREGATOR}/api/ve1/aggregator-service/seller/webhook`,
                  postData
                )
              : await axios.put(
                  `${process.env.REACT_APP_AGGREGATOR}/api/ve1/aggregator-service/seller/webhook`,
                  postData
                );
      
          if (response?.data?.errors) {
            notification.error({
              message: response?.data?.errors?.message,
            });
            setIsSubmitLoading(false);
          } else {
            await queryClient.invalidateQueries("WebhookList");
            notification.success({
              message: "Webhook saved successfully",
            });
            setIsSubmitLoading(false);
            form.resetFields();
            setEditWebhookData({});
            setAbandonedServiceProvider('Custom')
            setAddWebHook(false);
          }
        } catch (error) {
          notification.error({
            message: error?.response?.data?.error?.message,
          });
          setIsSubmitLoading(false);
        }
      };

    const columns = [
        {
            title: <TableHeading> Service Provider </TableHeading>,
            width: "50%",
            // dataIndex: 'serviceProvider',
            align : "left",
            render: (data) => {
                return <TableDataContainer>{`${data.serviceProvider?.slice(0,56)||( data?.abandonServiceProvider==='Elevenza'?'11za':data?.abandonServiceProvider==='Bikayi'?'Bik.ai': data?.abandonServiceProvider==='Spurnow' ? 'Spur' : data?.abandonServiceProvider)} ${data.serviceProvider?.length > 56 ? "..." : ""}`}</TableDataContainer>;
              },
            },
        {
          title: <TableHeading> Webhook Type </TableHeading>,
          width: "20%",
          dataIndex: 'type',
          align : "left",
          render: (data) => {
            return (
                <TableDataContainer>
                    {data === "AbandonWebhook" ? "Abandon Cart" : data === "RealTime" ? "Real Time" : "-"}
                </TableDataContainer>
            );
          },
        },
        {
        title: <TableHeading> Time </TableHeading>,
        width: "15%",
        dataIndex: 'time',
        align : "left",
        render: (data) => {
            return (
                <TableDataContainer>
                    { data ? `${data} minutes` : "-"}
                </TableDataContainer>
            )},
        },
        {
        title: <TableHeading> Actions </TableHeading>,
        dataIndex: 'active',
        align : "left",
        render: (data,record,index) => {
            return(
                <TableDataContainer style={{flexDirection : 'row', display:'flex'}}>
                    <div>
                        <Switch 
                        style={{marginRight : '10px'}} 
                        checked = {data}
                        onChange={(value)=>{
                            handleActiveChange( value,record,index)
                        }} 
                        loading = {index === switchLoading ? true : false}
                        />
                    </div>
                    <div onClick={(e) => {
                            setAddWebHook(true)
                            setEditWebhookData(record)
                            // handleSingleDownload(rowData.orderDetails.orderId, fromDate, toDate)
                        }} style= {{cursor: 'pointer'}}
                    >
                        <img
                            src="https://d10srchmli830n.cloudfront.net/1678863692643_00b3a84d-70a1-4b35-88db-4e7ebc7dfc05_Group-1116602126.svg"
                            alt="download"
                        />
                    </div>
                </TableDataContainer>
            )
          },
        },
    ]

    return (
        <Container>
            <Header
            title="Webhooks"
            addWebHook = {addWebHook}
            setAddWebHook = {setAddWebHook}
            />
            {!addWebHook ?
            <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <Table
                    bordered
                    dataSource={webhookListData}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{
                        pageSize: 20,
                        total: webhookListData?.length
                    }}
                    loading = {isLoading}
                />
            </Col>
            :
            <CustomForm layout="vertical" form={form} onFinish={submitForm}>
                <Row gutter={[20,20]} style ={{marginLeft: '10px'}}>
                    <Col span={24}>
                    <>
                        <LeftOutlined
                        style={{
                            color: '#000B3499 !important',
                            cursor: 'pointer',
                        }}
                        />
                        <BackButton 
                        onClick={()=>{
                            setAddWebHook(false)
                            setAbandonedServiceProvider('Custom')
                            setEditWebhookData({})
                            form.resetFields()
                        }}
                        >BACK</BackButton>
                    </>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                        label= {<RadioHeading>Select Webhook Type</RadioHeading>}
                        name = "type"
                        rules={[
                            { 
                            required: true, 
                            message: "Webhook Type is required",
                            }
                        ]}
                        //value={webhookType}
                        >
                            <Radio.Group 
                            onChange={(e)=>{
                                setWebhookType(e.target.value)
                            }}
                            value={webhookType} 
                            disabled = {Object.keys(editWebhookData).length !== 0 ? true : false}
                            >
                                <Radio value={"RealTime"}>Real Time</Radio>
                                <Radio value={"AbandonWebhook"}>Abandon Cart</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {webhookType === "AbandonWebhook" && (
                    <Col span={24}>
                        <Form.Item
                        label={<RadioHeading>Select Abandoned Service Provider Type</RadioHeading>}
                        name="abandonServiceProvider"
                        initialValue="Custom"
                        rules={[
                            {
                            required: true,
                            message: "Service Provider Type is required",
                            },
                        ]}
                        >
                        <Select
                            placeholder="Select a provider type"
                            onChange={(value) => {
                            setAbandonedServiceProvider(value);
                            }}
                        >
                            <Select.Option value="Custom">Custom</Select.Option>
                            <Select.Option value="Elevenza">11za</Select.Option>
                            <Select.Option value="Shopagain">Shopagain</Select.Option>
                            <Select.Option value="Limechat">Limechat</Select.Option>
                            <Select.Option value="Techmonk">TechMonk</Select.Option>
                            <Select.Option value="Interakt">Interakt</Select.Option>
                            <Select.Option value="Bikayi">Bik.ai</Select.Option>
                            <Select.Option value="Webengage">Webengage</Select.Option>
                            <Select.Option value="Quickreply">QuickReply</Select.Option>
                            <Select.Option value="Spurnow">Spur</Select.Option>
                            <Select.Option value="Netcore">Netcore</Select.Option>
                            <Select.Option value="Engati">Engati</Select.Option>
                            <Select.Option value="Convertway">Convertway</Select.Option>
                            <Select.Option value="Aisensy">Aisensy</Select.Option>
                            <Select.Option value="Easyrewardz">Easyrewardz</Select.Option>
                            <Select.Option value="Bob">Bob</Select.Option>
                            




                        </Select>
                        </Form.Item>
                    </Col>
                    )}

                { (webhookType!=="AbandonWebhook" || abandonedServiceProvider == 'Custom' || abandonedServiceProvider === 'Quickreply') && <Col xl={12} lg={12} md={24} sm={24} x={24}>
                        <Form.Item
                        style={{width : '90%'}}
                        label="API Endpoint"
                        name="apiEndPoint"
                        rules={[
                            { 
                            required: true, 
                            message: "Please enter a valid API endpoint.",
                            validator(rule, value) {
                                if (!value?.trim().length) {
                                  return Promise.reject(new Error("Please enter a valid API endpoint."));
                                }
                                return Promise.resolve();
                              },
                            },
                        ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>}
                    {webhookType==="AbandonWebhook" &&
                        <Col xl={12} lg={12} md={24} sm={24} x={24}>
                            <Form.Item
                            style={{width : '90%'}}
                            label="Time"
                            name="time"
                            rules={[
                                {
                                    required : true,
                                    message : 'Time is required'
                                }
                            ]}
                            >
                                <InputNumber addonAfter="minutes" />
                            </Form.Item>
                        </Col>
                    }
                    {webhookType==="RealTime" &&
                        <Col xl={12} lg={12} md={24} sm={24} x={24}>
                            <Form.Item
                            style={{width : '90%'}}
                            label="Stage"
                            name="stage"
                            rules={[
                                { 
                                required: true, 
                                message: "Stage is required",
                                }
                            ]}
                            >
                            <Select 
                            mode="multiple"
                            allowClear = {true}
                            maxTagCount="responsive"
                            showArrow={true}
                            >
                                    <Option value={0}>Cart Initiated</Option>
                                    <Option value={10}>Phone Received </Option>
                                    <Option value={50}>Payment Initiated</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                   {(webhookType!=="AbandonWebhook" || (abandonedServiceProvider !== 'Quickreply' && abandonedServiceProvider !== 'Bikayi')) && <Col xl={12} lg={12} md={24} sm={24} x={24}>
                        <Form.List
                        name="header"
                        initialValue={[{}]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                {fields.map(( field , index ) => (
                                    <div style={{display : 'flex', width : '100%', alignItems : 'end' }}>
                                    <Form.Item
                                    style={{ marginRight: '10px',width : '45%'}}
                                    label = {index===0 && "Headers"}
                                    name={[field.name, `key`]}
                                    rules={[
                                        (formInstance) => ({
                                            message: 'Enter Valid Header Key',
                                            validator(rule, value) {
                                                const key = formInstance.getFieldValue(['header', field.name, 'value']);
                                                if (key && !value?.trim().length && index === 0) {
                                                    return Promise.reject(new Error());
                                                }
                                                if (!value?.trim().length && fields.length > 1) {
                                                    return Promise.reject(new Error());
                                                }
                                                if(value && !value?.trim().length){
                                                    return Promise.reject(new Error());
                                                }
                                                return Promise.resolve();
                                            },
                                          }),
                                    ]}
                                  >
                                    <Input placeholder="Enter key" />
                                  </Form.Item>
                                  <Form.Item
                                    style={{ marginRight: '10px',width : '45%'}}
                                    label = {index===0 && ""}
                                    name={[field.name, `value`]}
                                    rules={[
                                        (formInstance) => ({
                                            message: 'Enter valid Header value',
                                            validator(rule, value) {
                                                const key = formInstance.getFieldValue(['header', field.name, 'key']);
                                                if (key && !value?.trim().length && index === 0) {
                                                    return Promise.reject(new Error());
                                                }
                                                if (!value?.trim().length && fields.length > 1) {
                                                    return Promise.reject(new Error());
                                                }
                                                if(value && !value?.trim().length){
                                                    return Promise.reject(new Error());
                                                }
                                                return Promise.resolve();
                                            },
                                          }),
                                    ]}
                                  >
                                    <Input placeholder="Enter value" />
                                  </Form.Item>
                                    {fields.length>1 &&
                                    <img 
                                    onClick={() => remove(field.name)}
                                    style={{marginBottom : '30px'}}
                                    src="https://d10srchmli830n.cloudfront.net/1678874340329_65c480e9-a900-4dbd-b9e6-a94b7fd405a7_Group-1116599519.svg"
                                    />}
                                  </div>
                                ))}
                                <Form.Item>
                                    <AddButton onClick={() => add()}>
                                    Add More
                                    </AddButton>
                                </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
}
                 {(webhookType!=="AbandonWebhook" || abandonedServiceProvider === 'Custom') && <Col xl={12} lg={12} md={24} sm={24} x={24}>
                        <Form.Item
                        style={{width : '90%'}}
                        label="Service Provider"
                        name="serviceProvider"
                        rules={[
                            { 
                            required: true, 
                            message: "Please enter a valid Service Provider.",
                            validator(rule, value) {
                                if (!value?.trim().length) {
                                  return Promise.reject(new Error("Please enter a valid Service Provider."));
                                }
                                return Promise.resolve();
                              },
                        }
                        ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>}
                    <Col xl={12} lg={12} md={24} sm={24} x={24}>
                        <Form.List
                        name="extra"
                        initialValue={[{}]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                {fields.map(( field , index ) => (
                                    <div style={{display : 'flex', width : '100%', alignItems : 'end' }}>
                                    <Form.Item
                                    style={{ marginRight: '10px',width : '45%'}}
                                    label = {index===0 && "Additional Info"}
                                    name={[field.name, `key`]}
                                    rules={[
                                        (formInstance) => ({
                                            message: 'Enter valid Additional key',
                                            validator(rule, value) {
                                              const key = formInstance.getFieldValue(['extra', field.name, 'value']);
                                              if (key && !value?.trim().length && index === 0 ) {
                                                    return Promise.reject(new Error());
                                              }
                                              if (!value?.trim().length && fields.length>1) {
                                                return Promise.reject(new Error());
                                               }
                                                if(value && !value?.trim().length){
                                                    return Promise.reject(new Error());
                                                }
                                                return Promise.resolve();
                                            },
                                          }),
                                    ]}
                                  >
                                    <Input placeholder="Enter key" />
                                  </Form.Item>
                                  <Form.Item
                                    style={{ marginRight: '10px',width : '45%'}}
                                    name={[field.name, `value`]}
                                    rules={[
                                        (formInstance) => ({
                                            message: 'Enter valid Additional value',
                                            validator(rule, value) {
                                                const key = formInstance.getFieldValue(['extra', field.name, 'key']);
                                                if (key && !value?.trim().length && index === 0) {
                                                    return Promise.reject(new Error());
                                                }
                                               if (!value?.trim().length && fields.length>1) {
                                                    return Promise.reject(new Error());
                                                }
                                                if(value && !value?.trim().length){
                                                    return Promise.reject(new Error());
                                                }
                                                return Promise.resolve();
                                            },
                                          }),
                                    ]}
                                  >
                                    <Input placeholder="Enter value" />
                                  </Form.Item>
                                    {fields.length>1 &&
                                    <img 
                                    onClick={() => remove(field.name)}
                                    style={{marginBottom :'30px'}}
                                    src="https://d10srchmli830n.cloudfront.net/1678874340329_65c480e9-a900-4dbd-b9e6-a94b7fd405a7_Group-1116599519.svg"
                                    />}
                                  </div>
                                ))}
                                <Form.Item>
                                    <AddButton onClick={() => add()}>
                                    Add More
                                    </AddButton>
                                </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                    <Col span={24}>
                    <Space>
                    <PickrrButton 
                    style={{marginLeft:'10px'}} 
                    htmlType="submit" 
                    color="checkout-blue"
                    loading = {isSubmitLoading}
                    >
                        Save & Proceed
                    </PickrrButton>
                </Space>
                </Col>
                </Row>
            </CustomForm>
            }
        </Container>
    )
}

export default WebHooks