import React, { useContext, useEffect, useState } from 'react';
import Header from '../../CommonComponents/Header';
import RulesTable from './RulesTable';
import CreateRuleModal from '../CreateRuleModal';
import {
  Col,
  Row,
  Switch,
  Form,
  Divider,
  Input,
  InputNumber,
  Space,
  Typography,
  Popconfirm,
  Radio,
  notification,
  TimePicker,
  Table,
  Button,
  Upload,
  Alert
} from 'antd';
import { Container, CustomForm, SubHeader } from '../GeneralSettings/style';
import { FlexColContainer,
  PickrrHeading,
  FlexBox,
  PickrrButton,
  SpaceBetweenContainer,
} from 'components/UIElements';
import { CustomTabs, Text, UploadContainer } from './style';
import { CustomButton } from '../Discount/styled';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getGeneralDetails,
  updateSellerCheckoutConfig,
} from '../GeneralSettings/script';
import { fetchPickrrSelectRuleList } from './script';
import moment from 'moment';
import { DataContext } from 'context/DataProvider';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import ABTesting from './AbTesting'
import axios from 'axios';

const { TabPane } = CustomTabs;
const { TextArea } = Input;
const ManageShipping = () => {
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const { data, isLoading, isSuccess } = useQuery(
    'generalSettings',
    getGeneralDetails
  );

  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (message, variables, data) => {
      notification.success({ message: 'Updated seller details.' });
    },
  });
  const [cutOffTime, setCutOffTime] = useState(null);
  const [isCreateRuleModalVisible, setIsCreateRuleModalVisible] =
    useState(false);
  const [activeKey, setActiveKey] = useState(1);

  const [editingKey, setEditingKey] = useState('');
  const [editData, setEditdata] = useState(undefined);
  const [key, setKey] = useState(0);
  const [tableData, setTableData] = useState([])
  const [updated, setUpdated] = useState(false);
  const [pincodeList , setPincodeList] = useState([])
  const [uploadFile, setUploadFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorPincodesList, setErrorPincodesList] = useState([]);
  const queryClient = useQueryClient();

  const Regex = new RegExp(/^(([0-9]+[.]?[0-9]*))$/);
  const percentRegex = new RegExp(/(^100(\.0{1,2})?$)|(^([0-9]([0-9])?)(\.[0-9]{1,2})?$)/);
  const timeRegex = new RegExp(/^(((?!0)[0-9]*))$/);

  const uploadProps = {
    onRemove: (file) => {
      setUploadFile([]);
    },
    beforeUpload: (file) => {
      if(file.type !== 'text/csv'){
        notification.error({
          message: 'You can only upload csv file!',
        });
      }
      else{
        setUploadFile([file]);
      }
      return false;
    },
  };

  useEffect(() => {
    const uploadCSV = async () => {
      try {
      setLoading(true);
      const formData = new FormData();
      formData.append('pincodes', uploadFile[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/upload-csv/`,
        formData
      );
      setLoading(false);
      if (response.data.success) {
        if (response.data.data.err_list.length > 0) {
          notification.error({
            message: 'Invalid pincodes found.',
          });
        } else {
          notification.success({
            message: 'File uploaded successfully.',
          });
          setPincodeList(response.data.data.list);
        }
        setErrorPincodesList(response.data.data.err_list);
        setUploadFile([]);
      } else {
        notification.error({
          message: response.data.message,
        });
        setUploadFile([]);
      } 
    } catch (e) {
      notification.error({
        message: 'Something went wrong.',
      });
      setLoading(false)
    }
    };
    if (uploadFile.length === 1) {
      uploadCSV();
    }
  }, [setPincodeList, uploadFile]);

  const { isLoading: isRuleListLoading, data: ruleListData } = useQuery(
    'courierRuleList',
    fetchPickrrSelectRuleList
  );

  const handleQueryReFetch = () => {
    queryClient.invalidateQueries('courierRuleList');
  };

  const handleQueryUpdate = (data) => {
    queryClient.setQueryData('courierRuleList', { data: data });
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const {onboardVersion} = useContext(DataContext);
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        block_pincodes : data?.block_pincodes !== undefined ? data?.block_pincodes : true,
        ...(!!data?.pickup_config && {
          pickup_config: {
            ...data.pickup_config,
            cut_off_time: moment(
              data.pickup_config.cut_off_time,
              'HH:mm'
            ),
          },
        }),
      });

      setPincodeList(data?.blocking_pincode_list ? data?.blocking_pincode_list : [])
      let tableData = [];
      for (let item in data.pickup_ofd_config) {
        tableData.push({
          zone: item,
          ...data.pickup_ofd_config[item],
          key: item,
        });
      }
      setTableData(tableData);
    }
  }, [data]);

  const submitForm = (values) => {
    let pickUpString = pincodeList.length
      ? pincodeList.join()
      : null;
    let pickupError = false
    if (pickUpString) {
      if (
        pickUpString[pickUpString.length - 1] === ',' ||
        pickUpString[0] === ',' ||
        !pickUpString.match(/^([1-9][0-9]{5}\,?)+$/g)
      ) {
        pickupError = true;
        notification.error({
          message: 'Please enter pickup pincodes in correct format.',
        });
      } else {
        pickupError = false;
      }
    } else {
      pickupError = false;
    }
    if(pickupError === false){
      mutation.mutate(
        {...values,
          blocking_pincode_list : pincodeList,
          ...((!data?.sr_promise_user && !data?.edd_via_sr_omuni_api) && {pickup_config:{...values.pickup_config,cut_off_time:moment(values.pickup_config.cut_off_time).format('HH:mm')}})
        }
        );
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    if (record) {
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            initialValue={record[dataIndex]}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    // try {
    //   const row = await tableForm.validateFields();
    //   const newData = [...data];
    //   const index = newData.findIndex((item) => key === item.key);
    //   if (index > -1) {
    //     const item = newData[index];
    //     newData.splice(index, 1, { ...item, ...row });
    //     setData(newData);
    //     setEditingKey('');
    //   } else {
    //     newData.push(row);
    //     setData(newData);
    //     setEditingKey('');
    //   }
    //   tableForm.resetFields();
    // } catch (errInfo) {
    //   console.log('Validate Failed:', errInfo);
    // }
  };

  const handleOpenModal = () => {
    setKey(`${Math.random()}`);
    setIsCreateRuleModalVisible(true);
  };

  const handleCloseModal = () => {
    setEditdata(null);
    setKey(`${Math.random()}`);
    setIsCreateRuleModalVisible(false);
  };

  const handleTabChange = (activeKey) => {
    setActiveKey(activeKey);
    console.log(activeKey);
  };

  const columns = [
    {
      title: 'Zone',
      dataIndex: 'zone',
      width: '25%',
      editable: false,
    },
    {
      title: 'Zone FAST TAT',
      dataIndex: 'fast',
      width: '40%',
      editable: true,
    },
    {
      title: 'Zone No Rush TAT',
      dataIndex: 'no_rush',
      width: '40%',
      editable: true,
    },
/*     {
      title: '',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ''}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    }, */
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Container>
      <Header
        title="Shipping"
        submitForm={submitForm}
        id={form}
        shippingTab={activeKey === '2' ? true : false}
      />
      <CustomTabs
        defaultActiveKey="1"
        tabBarExtraContent={
          activeKey === '2' && (
            <CustomButton
              style={{
                marginRight: '10px',
                width: '100px',
              }}
              onClick={handleOpenModal}
            >
              Create Rule
            </CustomButton>
          )
        }
        onChange={handleTabChange}
      >
        <TabPane tab="Default Value" key="1">
          <CustomForm layout="vertical" form={form} onFinish={submitForm}>
            <Row gutter={[16, 16]}>
              <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <SubHeader>Prepaid Discount</SubHeader>
              </Col>
              <Col xl={10} lg={10} md={24} sm={24} x={24}>
                <Form.Item 
                  name="prepaid_discount" 
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>₹</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Charges"
                  />
                </Form.Item>
              </Col>
              <Col xl={4} lg={10} md={24} sm={24} x={24}>
                <p style={{ textAlign: 'center', color: '#0C3784' }}>or</p>
              </Col>
              <Col xl={10} lg={10} md={24} sm={24} x={24}>
                <Form.Item 
                  name="prepaid_discount_percent" 
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>%</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Percentage"
                  />
                </Form.Item>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <Form.Item 
                  name="apply_max_prepaid_discount" 
                  label="Apply maximum value" 
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
                </Form.Item>
              </Col>
              <Divider />

              <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <SubHeader>Cod to Prepaid Discount</SubHeader>
              </Col>
              <Col xl={10} lg={10} md={24} sm={24} x={24}>
                <Form.Item 
                  name={['cod_to_prepaid_discount', 'value']}
                  // rules={[{ attern:Regex,  message: 'This field is required.' }]}
                >
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>₹</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Value"
                  />
                </Form.Item>
              </Col>
              <Col xl={4} lg={10} md={24} sm={24} x={24}>
                <p style={{ textAlign: 'center', color: '#0C3784' }}>or</p>
              </Col>
              <Col xl={10} lg={10} md={24} sm={24} x={24}>
                <Form.Item 
                  name={['cod_to_prepaid_discount', 'percent']}
                  // rules={[{pattern:percentRegex, message: 'This field is required.' }]}
                >
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>%</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Percentage"
                  />
                </Form.Item>
              </Col>
              <Col xl={10} lg={10} md={24} sm={24} x={24}>
                <Form.Item 
                  name={['cod_to_prepaid_discount', 'time']}
                  // rules={[{ pattern:timeRegex, message: 'This field is required.' }]}
                >
                  <InputNumber
                    min={1}
                    style={{ width: '100%' }}
                    placeholder="Enter time in minutes"
                  />
                </Form.Item>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <Row>
                <Col xl={12} lg={12} md={24} sm={24} x={24}>
                  <Form.Item 
                    name={['cod_to_prepaid_discount', 'apply_max']}
                    label="Apply maximum value" 
                    // rules={[{ message: 'This field is required.' }]}
                  >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} x={24}>
                  <Form.Item 
                    name={['cod_to_prepaid_discount', 'is_enabled']}
                    label="Enable Cod to Prepaid" 
                    initialValue={false}
                    // rules={[{message: 'This field is required.' }]}
                  >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  </Form.Item>
                </Col>
                </Row>
              </Col>
              <Divider />




              <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <SubHeader>COD Charges</SubHeader>
              </Col>
              <Col xl={10} lg={10} md={24} sm={24} x={24}>
                <Form.Item 
                  name="cod_charges" 
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>₹</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Charges"
                  />
                </Form.Item>
              </Col>
              <Col xl={4} lg={10} md={24} sm={24} x={24}>
                <p style={{ textAlign: 'center', color: '#0C3784' }}>or</p>
              </Col>
              <Col xl={10} lg={10} md={24} sm={24} x={24}>
                <Form.Item 
                  name="cod_charge_percent" 
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>%</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Percentage"
                  />
                </Form.Item>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} x={24}>
              <Form.Item 
                name="apply_max_cod_charge" 
                label="Apply maximum value" 
                rules={[{ required: true, message: 'This field is required.' }]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
                </Form.Item>
              </Col>
              <Divider />
              <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <SubHeader>Shipping Charges</SubHeader>
              </Col>
              <Col xl={10} lg={10} md={24} sm={24} x={24}>
                <Form.Item 
                  name="fast_shipment_charges" 
                  label={onboardVersion && onboardVersion !== 'v3'? "Fastrr" : 'Rush'} 
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>₹</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Charges"
                  />
                </Form.Item>
              </Col>
              {/* <Col
                xl={4}
                lg={10}
                md={24}
                sm={24}
                x={24}
                style={{ margin: 'auto' }}
              >
                <p style={{ textAlign: 'center', color: '#0C3784' }}>or</p>
              </Col>
              <Col
                xl={10}
                lg={10}
                md={24}
                sm={24}
                x={24}
                style={{ margin: 'auto' }}
              >
                <Form.Item name="percentage" style={{ marginBottom: 0 }}>
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>%</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Percentage"
                  />
                </Form.Item>
              </Col> */}
              <Col xl={10} lg={10} md={24} sm={24} x={24}>
                <Form.Item 
                  name="no_rush_shipment_charges" 
                  label={onboardVersion && onboardVersion !== 'v3'? "No-rush" : 'Express' } 
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>₹</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Charges"
                  />
                </Form.Item>
              </Col>
              {/* <Col
                xl={4}
                lg={10}
                md={24}
                sm={24}
                x={24}
                style={{ margin: 'auto' }}
              >
                <p style={{ textAlign: 'center', color: '#0C3784' }}>or</p>
              </Col>
              <Col
                xl={10}
                lg={10}
                md={24}
                sm={24}
                x={24}
                style={{ margin: 'auto' }}
              >
                <Form.Item name="percentage" style={{ marginBottom: 0 }}>
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>%</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Percentage"
                  />
                </Form.Item>
              </Col> */}
              <Col xl={10} lg={10} md={24} sm={24} x={24}>
                <Form.Item 
                  name="standard_shipment_charges" 
                  label="Standard" 
                  rules={[{ required: true, message: 'This field is required.'}]}
                >
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>₹</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Charges"
                  />
                </Form.Item>
              </Col>
              {/* <Col
                xl={4}
                lg={10}
                md={24}
                sm={24}
                x={24}
                style={{ margin: 'auto' }}
              >
                <p style={{ textAlign: 'center', color: '#0C3784' }}>or</p>
              </Col>
              <Col
                xl={10}
                lg={10}
                md={24}
                sm={24}
                x={24}
                style={{ margin: 'auto' }}
              >
                <Form.Item name="percentage" style={{ marginBottom: 0 }}>
                  <InputNumber
                    prefix={<p style={{ color: '#0C3784', margin: 0 }}>%</p>}
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter Percentage"
                  />
                </Form.Item>
              </Col> */}
              {/* <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <Form.Item label="Set Value as">
                  <Radio.Group>
                    <CustomRadio value={1}>Minimum Value</CustomRadio>
                    <CustomRadio value={2}>Maximum Value</CustomRadio>
                  </Radio.Group>
                </Form.Item>
              </Col> */}

              {/* <Divider />
              <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <Form.Item label="Prepaid Discount" name="prepaid_discount">
                  <Input style={{ width: '50%' }} />
                </Form.Item>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <Form.Item label="COD charges" name="cod_charges">
                  <Input style={{ width: '50%' }} />
                </Form.Item>
              </Col> */}
              {/* <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <Form.Item
                  label={
                    <p>
                      Disable COD:{' '}
                      <span style={{ fontWeight: '400' }}>
                        {' '}
                        in case of high risk order
                      </span>
                    </p>
                  }
                  name="disable_cod_for_high_risk_order"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                  }}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    value={data?.enable_cod ? data.enable_cod : false}
                    onChange={onChange}
                    style={{ marginLeft: '50px', width: '78px' }}
                  />
                </Form.Item>
              </Col> */}
            </Row>
            <Divider />
          {(!data?.sr_promise_user && !data?.edd_via_sr_omuni_api)  && 
            <Row gutter={[16,16]}>
            <Col xl={24} lg={24} md={24} sm={24} x={24}>
              <SubHeader>Pickup Configuration </SubHeader>
            </Col>
            <Col xl={4} lg={4} md={24} sm={24} x={24}>
              <Form.Item
                label="Cut-off time"
                name={['pickup_config', 'cut_off_time']}
                rules={[{ required: true, message: 'Cutoff time is required' }]}
              >
              <TimePicker
                  onChange={(time, timeString) => setCutOffTime(timeString)}
                  style={{height: '35px', width:'100%'}}
                  format="HH:mm"
                  clearIcon={false}
                  defaultOpenValue={moment()} 
                  />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} x={24}>
              <Form.Item
                label="TAT after cut-off time"
                name={['pickup_config', 'tat_after_cut_off']}
                rules={[
                  {
                    required: true,
                    message: 'TAT after cut-off time is required',
                  },
                ]}
              >
                <InputNumber
                  placeholder="No of days"
                  min={-3}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} x={24}>
              <Form.Item
                label="TAT before cut-off time"
                name={['pickup_config', 'tat_before_cut_off']}
                rules={[
                  {
                    required: true,
                    message: 'TAT before cut-off time is required',
                  },
                ]}
              >
                <InputNumber
                  placeholder="No of days"
                  min={-3}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Divider />
            </Row>
          }
          <Row gutter={[16,16]}>
            <Col xl={24} lg={24} md={24} sm={24} x={24}>
              <SubHeader>Non Serviceable Pincodes</SubHeader>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <Form.Item
            style={{marginBottom : '0px'}}
            name='block_pincodes'
            rules={[
              {
                required: true,
                message: 'Pincode is required',
              },
            ]}
            >
              <Radio.Group>
                <Radio value={true}>Include</Radio>
                <Radio value={false}>Exclude</Radio>
              </Radio.Group>
            </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <FlexColContainer>
              <SpaceBetweenContainer>
                <PickrrHeading size={5}>Select Pincodes</PickrrHeading>
                <Button type="link" onClick={() => setPincodeList([])}>
                  Reset
                </Button>
              </SpaceBetweenContainer>
              <TextArea
                placeholder="Add comma separated pincode list"
                rows={4}
                className="mb-16"
                value={pincodeList.join(',')}
                onChange={(e) =>{
                  setErrorPincodesList([])
                  e.target.value
                    ? setPincodeList(e.target.value.split(','))
                    : setPincodeList([])
                }
                }
              />
              {errorPincodesList.length > 0 && (
                <Alert
                  message={`${
                    errorPincodesList.length
                  } pincode(s) found invalid. Please check the following pincode(s): ${errorPincodesList.join(
                    ','
                  )}`}
                  type="error"
                />
              )}
              <PickrrHeading size={5} style={{marginBottom : '10px'}}>or upload pincode list</PickrrHeading>
              <Row>
                <Col  xl={4} lg={4} md={4} sm={24} x={24}>
                  <UploadContainer>
                <Upload 
                style={{width : '200px'}} 
                {...uploadProps} 
                showUploadList = {false}
                >
                  <PickrrButton
                    color="primary"
                    style={{ textTransform: 'none' }}
                    icon={<UploadOutlined />}
                    className="mr-12"
                    loading={loading}
                  >
                    Upload CSV
                  </PickrrButton>
                </Upload>
                </UploadContainer>
                </Col>
                <Col  xl={8} lg={8} md={8} sm={24} x={24}>
                <a
                  href="https://d10srchmli830n.cloudfront.net/1632747241375_Sample PinCode File.csv"
                  download
                >
                  <Text>
                    <DownloadOutlined className="mr-8" /> Download Sample CSV File
                  </Text>
                </a>
                </Col>
                </Row>
             </FlexColContainer>
             </Col>

          </Row>
          <Divider />
          <Row gutter={[16,16]}>
          <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <SubHeader>Zone TAT Table</SubHeader>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <Form form={tableForm}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={tableData}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
              />
            </Form>
          </Col>
          <Divider />
          </Row>
            <Space>
              <PickrrButton htmlType="submit" color="checkout-blue">
                Save & Proceed
              </PickrrButton>
            </Space>
          </CustomForm>
        </TabPane>
        <TabPane tab="Custom Rules" key="2">
          <RulesTable
            setEditdata={setEditdata}
            setIsCreateRuleModalVisible={setIsCreateRuleModalVisible}
            setKey={setKey}
            updated={updated}
            setUpdated={setUpdated}
            ruleList={ruleListData?.data.length ? ruleListData?.data : []}
            isRuleListLoading={isRuleListLoading}
            handleQueryUpdate={handleQueryUpdate}
            handleQueryReFetch={handleQueryReFetch}
          />
        </TabPane>
        {ruleListData?.data.filter((data) => data.rule_type === "ab_testing").length >0 && 
        <TabPane tab="A/B TESTING RESULTS" key="3">
        <ABTesting
          ruleList={ruleListData?.data.length ? ruleListData?.data : []}
          isRuleListLoading={isRuleListLoading}
          handleQueryUpdate={handleQueryUpdate}
          handleQueryReFetch={handleQueryReFetch}
        />
      </TabPane>
        }
        
      </CustomTabs>
      <CreateRuleModal
        visible={isCreateRuleModalVisible}
        handleCloseModal={handleCloseModal}
        editData={editData}
        key={key}
        setKey={setKey}
        updated={updated}
        setUpdated={setUpdated}
        ruleList={ruleListData?.data}
        handleQueryReFetch={handleQueryReFetch}
      />
    </Container>
  );
};

export default ManageShipping;
