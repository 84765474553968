import { FlexBox } from 'components/UIElements';
import React from 'react';
import { Text } from '../../styled';

const Amount = ({paymentMethodDetails, isCardDesign}:any) => {
    const {totalAmount, finalAmount} = paymentMethodDetails;
    const rightArrow = 'https://fastrr-boost-ui.pickrr.com/assets/images/arrowIconCard.svg';

    return (
    <FlexBox style={{gap: 6, alignItems:'center'}}>
        {isCardDesign &&
            <>
                <Text weight = {400} strike = {true} className='label'>{totalAmount}</Text>
                <Text className='label'>{finalAmount}</Text>
            </>
        }   
        <img src = {rightArrow} alt ={'right-arrow'} />
    </FlexBox>
    )
}

export default Amount;