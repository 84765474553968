import styled from "styled-components";

export const PrimaryButton = styled.button<{size?:string}>`
    background: var(--primary-color);
    color: var(--white-color);
    font-size: ${(props:any) => props.size == "sm" ? "12px" : '16px'}; 
    padding: ${(props:any) => props.size == "sm" ? "10px 24px" : '12px 40px'};
    display:flex;
    justify-content:center;
    align-items:center;
    line-height:20px;
    border-radius: 8px;
     &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`

export const LinkButton = styled.button`
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 600;
    border:none;
    outline:none;
    line-height: 16px;
    cursor:pointer;
     &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`

export const GhostButton = styled.button<{size?:string}>`
    
 
    background: #ffffff;
    color: var(--primary-color);
    font-size: ${(props:any) => props.size == "xs" ? "10px" : '14px'};
    padding: ${(props:any) => props.size == "xs" ? "3px 12px" : '12px 40px'};
    display:flex;
    justify-content:center;
    align-items:center;
    line-height:20px;
    border-radius: 8px;
    border: 1px solid #C1C1C1;
     &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`


export const SecondaryButton = styled.button`
    color: #4C535F;
    font-size: 14px;
    font-weight: 500;
    border:none;
    outline:none;
    line-height: 16px;
    cursor:pointer;
     &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`