import React from 'react';
import { couponData } from './data';
import { CouponContainer, SaveAmountBadge } from './styled';
import { Heading, Button, TextSecondarySmall } from '../styled';
import { FlexBox, SpaceBetweenContainer } from 'components/UIElements';

const CouponList = () => {
    const discountIcon = 'https://fastrr-boost-ui.pickrr.com/assets/images/svg/discount_icon.svg';
    return (
        <div>
            <Heading style={{margin: '12px 0'}}>Applicable coupons</Heading>

            {couponData.map((coupon:any) => {
                return (
                    <CouponContainer>
                        <SpaceBetweenContainer>
                            <FlexBox style={{gap: 4}}>
                                <img src = {discountIcon} />
                                <Heading>{coupon.code}</Heading>
                            </FlexBox>
                            <Button>Apply</Button>
                        </SpaceBetweenContainer>
                        <div>
                            <SaveAmountBadge>Apply coupon and save {coupon.amount}</SaveAmountBadge>
                            <TextSecondarySmall>{coupon.description}</TextSecondarySmall>
                        </div>
                    </CouponContainer>
                )
            })}
        </div>
    )
}

export default CouponList;