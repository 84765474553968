import React, { useState } from "react";
import { Col, InputNumber, Row, Checkbox } from "antd";

import { FlexColContainer, PickrrHeading } from "../../../UIElements";
import { ContainerDiscount } from "./styled";
import { InfoCircleOutlined } from "@ant-design/icons";

const payment_methods = {
  Default: {
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,
  },
  UPI: {
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,
  },
  CARDS: {
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,
  },
  NETBANKING: {
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,
  },
  WALLETS: {
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,
  },
  EMIS: {
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,
  },
  BNPL: {
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,
  },
  CREDPAY: {
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,
  },
  REWARDS: {
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,
  },
  UPI_OTHERS: {
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,
  },
  SIMPL:{
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,
  },
  POTLEE:{
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,

  },
  SIMPLPI3:{
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,

  },
  SIMPLPOD:{
    rule_value_absolute: 0,
    rule_value_percent: 0,
    apply_maximum_value: false,

  }
  // BHARATX:{
  //   rule_value_absolute: 0,
  //   rule_value_percent: 0,
  //   apply_maximum_value: false,
  // }
};
const MultipalDiscounts = ({
  payment_modes_discount,
  setPaymentModesDiscount,
  applyMaxValue,
  setApplyMaxValue,
  ruleValuePercent,
  ruleValueAbsolute,
  setRuleValuePercent,
  setRuleValueAbsolute,
  isABTesting
}) => {
  const _default_payment_modes = JSON.parse(JSON.stringify(payment_methods));
  const [payment_modes, setPaymentDiscounts] = useState({
    ..._default_payment_modes,
    ...payment_modes_discount,
  });

  const handleChange = (key, value, key_to_edit) => {
    setPaymentDiscounts((prev) => {
      let _temp = prev;
      _temp[key][key_to_edit] = value;
      setPaymentModesDiscount(_temp);
      return { ..._temp };
    });
  };

  return (
    <>
      <span style={{ marginTop: 10 }}>
        <InfoCircleOutlined />
        &nbsp;&nbsp;
        <b>
          When the "Apply max value" box is checked, the system applies the
          maximum discount when both flat and percentage discounts are provided;
          otherwise, it applies the minimum discount
        </b>
      </span>
      <ContainerDiscount>
        <FlexColContainer>
          {Object.keys(payment_modes)?.map((s, index) => {
            return (
              <Row gutter={isABTesting ? 4 : 16} className="mb-16" key={index}>
                <Col span={4} style={{ marginTop: s == "Default" ? 0 : 10 }}>
                  <PickrrHeading size={6}>
                    {s == "Default" ? "" : (s?.toUpperCase()=='POTLEE'?'SETTLE':s)}
                  </PickrrHeading>
                </Col>
                <Col span={6}>
                  {s == "Default" ? (
                    <PickrrHeading size={6}>Flat discount</PickrrHeading>
                  ) : (
                    <InputNumber
                      className="mr-12"
                      size="large"
                      placeholder="Charges ₹"
                      min={0}
                      defaultValue={0}
                      value={
                        s == "Default"
                          ? ruleValueAbsolute
                          : payment_modes[s].rule_value_absolute
                      }
                      onChange={(value) =>
                        s == "Default"
                          ? setRuleValueAbsolute(value > 0 ? value : 0)
                          : handleChange(
                              s,
                              value > 0 ? value : 0,
                              "rule_value_absolute"
                            )
                      }
                      style={{ width: "100%", borderRadius: "10px" }}
                    />
                  )}
                </Col>
                <Col span={2} style={{ marginTop: s == "Default" ? 0 : 10 }}>
                  {" "}
                  {s !== "Default" && (
                    <PickrrHeading size={6}>OR</PickrrHeading>
                  )}
                </Col>
                <Col span={6}>
                  {s == "Default" ? (
                    <PickrrHeading size={6}>Percentage discount </PickrrHeading>
                  ) : (
                    <InputNumber
                      className="mr-12"
                      size="large"
                      placeholder="Percentage %"
                      value={
                        s == "Default"
                          ? ruleValuePercent
                          : payment_modes[s].rule_value_percent
                      }
                      min={0}
                      onChange={(value) =>
                        s == "Default"
                          ? setRuleValuePercent(value > 0 ? value : 0)
                          : handleChange(
                              s,
                              value > 0 ? value : 0,
                              "rule_value_percent"
                            )
                      }
                      style={{ width: "100%", borderRadius: "10px" }}
                    />
                  )}
                </Col>
                {s !== "Default" && (
                  <Col span={6}>
                    <Checkbox
                      checked={
                        s == "Default"
                          ? applyMaxValue
                          : payment_modes[s].apply_maximum_value
                      }
                      onChange={() =>
                        s == "Default"
                          ? setApplyMaxValue(!applyMaxValue)
                          : handleChange(
                              s,
                              !payment_modes[s].apply_maximum_value,
                              "apply_maximum_value"
                            )
                      }
                      style={{ marginTop: "10px", color: "#0C3784" }}
                    >
                      Apply max value?
                    </Checkbox>
                  </Col>
                )}
              </Row>
            );
          })}
        </FlexColContainer>
      </ContainerDiscount>
    </>
  );
};

export default MultipalDiscounts;
