import React from "react";
import styled from "styled-components";

// Styled Components
const DeviceFrame = styled.div<{isPreview: boolean}>`
  width: 320px; 
  height: 620px; 
  background-color: black; /* Device color */
  border-radius: 40px; /* Rounded corners for the frame */
  border: 6px solid #000000; /* Outline for the device frame */
  border-top: 10px solid #000000;
  border-bottom: 12px solid #000000;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props:any) => props.isPreview ? 'translateY(-24px)  scale(.86)' : 'translateY(-60px)  scale(.8)'};

  ::before {
    content: '';
    position: absolute;
    top: 20px; 
    width: 60px; 
    height: 5px; 
    background-color: #1c1c1c;
    border-radius: 8px; 
    left: 50%;
    transform: translateX(-50%);
  }
`;

// Screen Area
// const Screen = styled.div`
//   width: 90%; /* Inner screen width */
//   height: 90%; /* Inner screen height */
//   background-color: #000; /* Screen content color */
// `;

const Device = ({children, isPreview}:any) => {
  return (
    <DeviceFrame isPreview = {isPreview}>
      {/* <Screen /> */}
      {children}
    </DeviceFrame>
  );
};

export default Device;
