import Checkout from 'components/Checkout';
import React from 'react';
import usePreview from 'components/CheckoutBuilder/Hooks/usePreview';
import { MdOutlineCancel } from "react-icons/md";
import Button from '../Button';



const PreviewDevice = ({handleClosePreview, handlePublish}:any) => {
    document.querySelector('body').style.overflow = "hidden";
    usePreview(true);

    
    const pubLishTemplate = async () => {
        await handlePublish(true);
    }

    return (
        <div 
            className="
            fixed 
            inset-0 
            z-50 flex 
            items-center 
            justify-center 
            overflow-x-hidden 
            overflow-y-auto 
            outline-none
            flex-col
            gap-4
        ">
            <div className="fixed inset-0 bg-black opacity-60" 
            onClick={handleClosePreview} 
            />
            <div className='
                bg-[#ffffff]
                z-10 
                text-[#5e5e5e] 
                text-[20px]  
                rounded-full 
                p-2 
            '
                onClick={handleClosePreview}
            >
                <MdOutlineCancel />
            </div>
            <div 
                 className="
                 flex 
                 flex-col 
                 justify-center 
                 items-center 
                 relative 
                 z-50 
                 bg-[#e9e9e9] 
                 rounded-xl 
                 w-4/2
                 h-3/4 
                 px-4
                 sm:h-[90vh]   /* If width >= 1280px, set height to 90vh */
                 2xl:h-[80vh]   /* If width >= 1536px, reset to 80vh */
               "
            >

            <Checkout 
                showDelivery = {true}
                showShipping = {true} 
                showPayment = {true}
                showBanner = {true}
                // showCoupon =  {true}
                isPreview = {true}
            />
            <div className='absolute bottom-[14px]'>
                <Button title = "Publish" size = "sm" onClick = {pubLishTemplate}/>
            </div>

               
               
            </div>
            
        </div>
    )
}

export default PreviewDevice;