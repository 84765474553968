import React, { useState } from 'react'; 
import Tabs from "components/CheckoutBuilder/UIComponents/Tabs";
import { Container, AccordianLeftSide } from "../../styled";
import MainHeader from './MainHeader';
import DrawerHeader from './DrawerHeader';

const Header = () => {
    const [activeTab, setActiveTab] = useState(1);

    const tabs = [
        { label: "Header", value: 1 },
        { label: "Drawer", value: 2 },
      ];

    return (
        <>
        <Tabs
          tabOptions={tabs}
          activeTab={activeTab}
          onChange={(value: number) => setActiveTab(value)}
        />

        {activeTab === 1 ? (
          <MainHeader />
        ) : (
           <DrawerHeader />
        )}
        </>
    )
}

export default Header;