import React, { useState, useContext } from 'react';
import { UiConfigContext } from 'context/CustomUIContext';
import Radio from '../UIComponents/Radio';
import { Text } from '../styled';
import { HeadingContainer, TemplateWrapper, TemplateContainer, LayoutInfo } from './styled';


const oldLayout = 'https://pickrr.s3.amazonaws.com:443/2024-12-02T12:03:45.003356_oldDesign.png';
const newLayout = 'https://pickrr.s3.amazonaws.com:443/2024-12-02T12:03:28.204530_newDesign.png';
const options = [
    {label:'Overall layout', value: 'overall_layout'}, 
    {label: 'Order summary', value: 'order_summary'},
    {label: 'Payment', value: 'payment'} 
]

const Template = () => {
    const [selected, setSelected] = useState('');
    const {templateConfig, setTemplateConfig, setThemeColor} = useContext(UiConfigContext);

    // 6f57e9

    return (
        <>
            <HeadingContainer>
            <Text>Select Template</Text>
            {/* <Radio 
                selected = {selected}
                options = {options}
                onChange = {(value) => {setSelected(value)}}
            /> */}
            </HeadingContainer>
            <TemplateContainer>
                <TemplateWrapper
                    selected = {templateConfig?.selectedTemplate === "oldLayout"}
                    onClick={() => {
                        setTemplateConfig((prev: any) => ({ ...prev, selectedTemplate: 'oldLayout' }));
                        setThemeColor((prev: any) => ({ ...prev, primaryColor: "#6f57e9" }));
                    }}
                >
                    <LayoutInfo>Discounted payment amount is shown in a clear cut-out</LayoutInfo>
                    <img src ={oldLayout} alt ="old layout" />
                </TemplateWrapper>
                <TemplateWrapper
                    selected = {templateConfig?.selectedTemplate === "newLayout"}
                    onClick={() => {
                        setTemplateConfig((prev:any) => ({...prev, selectedTemplate:'newLayout'}))
                        setThemeColor((prev: any) => ({ ...prev, primaryColor: "#3173E3" }));
                    }}
                >
                    <LayoutInfo>Final payment amount is displayed at the top for easy visibility</LayoutInfo>
                    <img src ={newLayout} alt ="old layout" />
                </TemplateWrapper>
            </TemplateContainer>
        </>
    )
}

export default Template;