import React, { useContext, useEffect } from "react";
import { Form, Radio, Space } from "antd";
import { DataContext } from "context/DataProvider";
import {
  DiscountTypeContainer,
  SubHeading,
  Text,
  CustomInput,
  DiscountTypeFlex,
  NumberContainer,
} from "./styled";

import { CustomSelect } from './CriteriaTypes/styled';

import { IDiscountTypeProps } from "../data";

const DiscountType = ({
  discountType,
  setDiscountType,
  discountMethod,
  setDiscountMethod,
  reset,
  form,
  setThirdPartyDiscountProvider,
  thirdPartyDiscountProvider
}: IDiscountTypeProps) => {
  
  const { Option } = CustomSelect;

  const toolTip = (
    <img
      src="https://pickrr.s3.amazonaws.com:443/2023-02-10T16:37:29.397675_info.svg"
      alt="info_img"
      height="15px"
      style={{ marginLeft: 6 }}
    />
  );

  const onChangeDiscountMethod = (e: any) => {
    setDiscountMethod(e.target.value);
  };

  const onChangeDiscountType = (e: any) => {
    setDiscountType(e.target.value);
    reset();
    form.setFieldsValue({ type: e.target.value });
    if (e.target.value == "aftersell") {
      setDiscountMethod("automaticDiscount");
    }
    if(e.target.value === "bankOffer"){
      // setDiscountMethod("couponCode");
      form.setFieldsValue({discountMethod: "couponCode"})
    }
  };

  const {
    sellerConfig
  } = useContext(DataContext);

  return (
    <DiscountTypeContainer>
      <DiscountTypeFlex>
        <NumberContainer>1</NumberContainer>
        <div>
          <SubHeading>Select Discount Type</SubHeading>
          <Text style={{ marginBottom: "18px" }}>
            We can have sub body text for “select discount type”
          </Text>
          <Form.Item name="type" initialValue={"prdAmt"}>
            <Radio.Group onChange={onChangeDiscountType}>
              <Space direction="vertical">
                <Radio value="prdAmt">
                  Amount Off Products
                  {/* {toolTip} */}
                </Radio>
                <Radio value="amtCart">
                  Amount Off Cart
                  {/* {toolTip} */}
                </Radio>
                <Radio value="tiered">
                  Tiered Discount
                  {/* {toolTip} */}
                </Radio>
                <Radio value="bxgy">Buy X get Y</Radio>
                {/* <Radio value="shipping">Shipping</Radio> */}
                <Radio value="bundle">
                  Bundle
                  {/* {toolTip} */}
                </Radio>
                <Radio value="aftersell">
                  Upselling
                  {/* {toolTip} */}
                </Radio>
                <Radio value="bankOffer">
                  Bank offer
                  {/* {toolTip} */}
                </Radio>
                <Radio value="freebie">
                  Freebie
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
      </DiscountTypeFlex>

      <DiscountTypeFlex style={{ marginTop: 10 }}>
        <NumberContainer>2</NumberContainer>
        <div>
          <SubHeading>Select Discount Method</SubHeading>
          <Form.Item name="discountMethod" initialValue={"automaticDiscount"}>
            <Radio.Group onChange={onChangeDiscountMethod}>
              <Space direction="vertical">
                {discountType != "bankOffer" && 
                 <Radio value="automaticDiscount">Automatic Discount</Radio>
                }
                {discountType != "aftersell" && (
                  <Radio value="couponCode">Coupon Code</Radio>
                )}
                { (discountType === "prdAmt" || discountType === "amtCart") &&
                  <Radio value="fomo">FOMO</Radio>
                }
                <Form.Item
                  name="couponCode"
                  rules={[
                    {
                      required: true,
                      // pattern: new RegExp(/^\S*$/),
                      message: "Please enter the coupon code",
                    },
                  ]}
                >
                  <CustomInput
                    style={{ width: 243, marginTop: 10 }}
                    placeholder="Enter Discount Code"
                  />
                </Form.Item>
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
      </DiscountTypeFlex>
      {
        sellerConfig?.is_casa_enable &&
        <DiscountTypeFlex style={{ marginBottom: 5, marginTop: -8 }}>
          <NumberContainer>3</NumberContainer>
          <div>
            <SubHeading>Select Discount Validation</SubHeading>
              <CustomSelect
                height="big"
                onChange={(e) => setThirdPartyDiscountProvider(e)}
                value={thirdPartyDiscountProvider}
                placeholder="---Select Provider---"
              >
                <Option value="CASA">CASA</Option>
              </CustomSelect>
          </div>
        </DiscountTypeFlex>
      }
    </DiscountTypeContainer>
  );
};

export default DiscountType;
