export const couponData:any = [
    {
        "code": "123TEST",
        "description": "Apply this code and get 10% off",
        "applicable": true,
        "amount": 869.5,
        "tnc": "Minimum cart value ₹10,\n",
        "validationErrorMsgs": null,
        "bankOfferImageUrl": null,
        "bankOffer": false
    },
    {
        "code": "TEST_12345",
        "description": "Apply this code and get 10% off",
        "applicable": true,
        "amount": 869.5,
        "tnc": "",
        "validationErrorMsgs": null,
        "bankOfferImageUrl": null,
        "bankOffer": false
    }
]