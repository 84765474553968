import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Switch, TimePicker } from 'antd'
import { SwitchText } from 'components/Settings/CustomizeCheckout/style'
import { FlexColContainer, FlexContainer, PickrrButton, PickrrHeading } from 'components/UIElements'
import { CustomTimeRangePicker } from 'components/UIElements/CustomInputBox/styled'
import dayjs from 'dayjs'
import customParseFormat from "dayjs/plugin/customParseFormat";
import { InfoIconYellow } from 'images/icons'
import React, { useState } from 'react'
import { InfoContainer } from './style'
dayjs.extend(customParseFormat);

const DeliverySlots = ({timingsList, setTimingsList}) => {
    const [showToday, setShowToday] = useState(timingsList?.TODAY?.length ? true : false);
    const [showTomorrow, setShowTomorrow] = useState(timingsList?.TOMORROW?.length ? true : false);

    const updateTimingsList = (type, callback) => {
        setTimingsList((prev) => ({
            ...prev,
            [type]: callback(prev[type]),
        }));
    };
    
    const handleAddTiming = (type) => {
        updateTimingsList(type, (prev) => [
            ...prev,
            { start_time: null, end_time: null, order_count: null },
        ]);
    };
    
    const handleChangeCapacity = (index, event, type) => {
        const value = event.target.value;
        updateTimingsList(type, (prev) =>
            prev.map((slot, idx) => (idx === index ? { ...slot, order_count: Number(value) } : slot))
        );
    };

     const handleSlotTextChange = (index, event, type) => {
        const value = event.target.value;
        if(type === 'TODAY') {
            updateTimingsList(type, (prev) =>
                prev.map((slot, idx) => (idx === index ? { ...slot, hyperlocal_text: value } : slot))
            );
        }
    };
    
    const handleTimeChange = (index, event, type) => {
        if (!event) return;
        const formattedRange = [
            event[0] ? event[0].format("HH:mm") : "",
            event[1] ? event[1].format("HH:mm") : "",
        ];
        updateTimingsList(type, (prev) =>
            prev.map((slot, idx) =>
                idx === index ? { ...slot, start_time: formattedRange[0], end_time: formattedRange[1] } : slot
            )
        );
    };
    
    const handleDelete = (index, type) => {
        updateTimingsList(type, (prev) => prev.filter((_, idx) => idx !== index));
    };

    const handleToggleChange = (type, value) => {
            if(type === 'TODAY'){
                setShowToday(value)
            }
            if(type === 'TOMORROW') {
                setShowTomorrow(value)
            }

         if(!value) {
            setTimingsList((prev) => ({
                ...prev,
                [type]: [],
            }));
        }
    }
    return (
        <FlexColContainer>
            <PickrrHeading size={5}>Select Delivery Slots</PickrrHeading>
            <InfoContainer>
                <span style={{marginRight: '10px'}}><InfoIconYellow /></span>
                
                Please do not overlap the delivery slots
            </InfoContainer>
            <div className='mb-8'>
                <Switch checked={showToday} onChange={(value) => { handleToggleChange('TODAY',value) }} />
                <SwitchText style={{ marginLeft: "10px" }}>
                    Show Today
                </SwitchText>
            </div>
            {showToday &&
                <>
                    <FlexColContainer>
                        {timingsList?.TODAY?.map((s, index) => {
                            return (
                                <Row gutter={16} className="mb-16" key={index} style={{alignItems: "center"}}>
                                    <Col span={7}>
                                        <CustomTimeRangePicker
                                            className="mr-12 w-full rounded-lg"
                                            value= {s.start_time && s.end_time
                                                ? [dayjs(s.start_time, "HH:mm"), dayjs(s.end_time, "HH:mm")]
                                                : null}
                                            needConfirm={false}
                                            format={'HH:mm'}
                                            onChange={(value) => { handleTimeChange(index, value, "TODAY") }}
                                        />
                                    </Col>

                                    <Col span={5}>
                                        <Input
                                            style={{ borderRadius: "8px", height: "32px" }}
                                            size="large"
                                            placeholder="Capacity"
                                            value={s.order_count}
                                            onChange={(value) => {
                                                handleChangeCapacity(index, value, "TODAY");
                                            }}
                                        />
                                    </Col>
                                    <Col span={7}>
                                        <Input
                                            style={{ borderRadius: "8px", height: "32px" }}
                                            size="large"
                                            placeholder="Delivery slot text"
                                            value={s.hyperlocal_text}
                                            onChange={(value) => {
                                                handleSlotTextChange(index, value, "TODAY");
                                            }}
                                        />
                                    </Col>
                                    <Col span={3}>
                                        <Button
                                            size="large"
                                            type="primary"
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            danger
                                            onClick={() => handleDelete(index, "TODAY")}
                                        />
                                    </Col>
                                </Row>
                            )

                        })}
                        <PickrrButton color="outline" onClick={()=> {handleAddTiming("TODAY")}}>
                            {timingsList.TODAY.length ? 'Add More' : 'Add'}
                        </PickrrButton>
                    </FlexColContainer>
                </>
            }
            <div className='mb-8 mt-14'>
                <Switch checked={showTomorrow} onChange={(value) => { handleToggleChange('TOMORROW',value) }} />
                <SwitchText style={{ marginLeft: "10px" }}>
                    Show Tomorrow
                </SwitchText>
            </div>
            {showTomorrow &&
                <>
                    <FlexColContainer>
                        {timingsList?.TOMORROW?.map((s, index) => {
                            return (
                                <Row gutter={16} className="mb-16" key={index}>
                                    <Col span={7}>
                                        <CustomTimeRangePicker
                                            className="mr-12 w-full rounded-lg"
                                            value= {s.start_time && s.end_time
                                                ? [dayjs(s.start_time, "HH:mm"), dayjs(s.end_time, "HH:mm")]
                                                : null}
                                            needConfirm={false}
                                            format={'HH:mm'}
                                            onChange={(value) => { handleTimeChange(index, value, "TOMORROW") }}
                                        />
                                    </Col>

                                    <Col span={5}>
                                        <Input
                                            style={{ borderRadius: "8px", height: "32px" }}
                                            size="large"
                                            placeholder="Capacity"
                                            value={s.order_count}
                                            onChange={(value) => {
                                                handleChangeCapacity(index, value, 'TOMORROW');
                                            }}
                                        />
                                    </Col>
                                    <Col span={3}>
                                        <Button
                                            size="large"
                                            type="primary"
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            danger
                                            onClick={() => handleDelete(index, "TOMORROW")}
                                        />
                                    </Col>
                                </Row>
                            )

                        })}
                        <PickrrButton color="outline" onClick={()=> {handleAddTiming("TOMORROW")}}>
                            {timingsList.TOMORROW.length ? 'Add More' : 'Add'}
                        </PickrrButton>
                    </FlexColContainer>
                </>
            }


        </FlexColContainer>
    )
}

export default DeliverySlots