import React from "react";
import { PhoneContainer, PhoneInputContainer, IconContainer } from "./styled";
import { Heading, InputBox, TextSecondarySmall } from "../styled";


const Phone = ({isCardDesign}:{isCardDesign:boolean}) => {
    const indianFlagIcon =  'https://fastrr-boost-ui.pickrr.com/assets/images/indian_flag.svg';

    return (
        <PhoneContainer>
            <Heading style={{marginBottom: 4}}>Enter phone number</Heading>
            <TextSecondarySmall>Provide your phone number to continue</TextSecondarySmall>
            <PhoneInputContainer>
                <div style={{width: '100%'}}>
                    <IconContainer><img src = {indianFlagIcon} alt = "discount-icon" />+91</IconContainer>
                    <InputBox type = "text" placeholder='Enter coupon code' paddingLeft = {'60px'} focus={true}/>
                </div>
            </PhoneInputContainer>
        </PhoneContainer>
    )
}

export default Phone;