import React from "react";
import { CheckboxWrapper, CustomCheckbox } from "./styled";
import Tooltip from "../Tooltip";

interface CheckboxProps {
  label: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  infoText?:string
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange, infoText }) => {
  return (
    <CheckboxWrapper>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <CustomCheckbox checked={checked}>
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.00041 11.2002L3.20041 8.4002L2.26675 9.33354L6.00041 13.0672L14.0004 5.0672L13.0671 4.13354L6.00041 11.2002Z" />
        </svg>
      </CustomCheckbox>
        {infoText ? <Tooltip label = {label} infoText = {infoText} style={{marginBottom: 0}} /> : label}
    </CheckboxWrapper>
  );
};

export default Checkbox;
